import React, { useState } from "react";
import { Box, Button, Container, Card, Typography, List, ListItem, ListItemText, Divider, Grid, ListItemIcon, IconButton } from "@mui/material";
import { Dialog, DialogActions, DialogContent, DialogTitle, RadioGroup,
    FormControlLabel,
    Radio,FormLabel, FormControl, TextField } from "@mui/material";

import { useNavigate } from "react-router-dom";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { AddSharp, CancelOutlined, MoreVert } from "@mui/icons-material";


const Transfer: React.FC = () => {
    const navigation = useNavigate()
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [amount, setAmount] = useState("");

    const handleOpen = () => {
        setOpen(true);
    };
    const handlesecond = () => {
        setOpen2(true);
        setOpen(false);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleClose2 = () => {
        setOpen2(false);
    };

    const handleDeposit = () => {
        console.log("Deposit Amount:", amount);
        handleClose();
    };
  
        const [value, setValue] = React.useState("Citibank Online");
      
        const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
          setValue((event.target as HTMLInputElement).value);
        };
    return (
        <Container
            maxWidth={false}
            className="bg-dark"

            style={{
                minHeight: "calc(100vh -  70px)",
                width: "100%",
                padding: '30px !important'
            }}
        >
            <Typography
                className="text-secondary"
                sx={{ marginBottom: "20px", paddingTop: '30px' }}
            >
                {" "}
                <span
                    onClick={() => navigation(-1)}
                    style={{ color: "#4A7AF2", cursor: "pointer" }}
                >
                    More Options{" "}
                </span>{" "}
                / Transfer
            </Typography>
            <Box sx={{ display: "flex", gap: '20px', minHeight: 'calc(100vh - 180px)', color: "#fff" }}>
                {/* Sidebar */}
                <Box
                    sx={{
                        width: "400px",
                        backgroundColor: "#FFFFFF1A",
                        borderRadius: '20px', border: "1px solid #FFFFFF2A",
                        padding: '20px',
                        display: "flex",
                        flexDirection: "column",
                        gap: 2,
                    }}
                >
                    {/* Transfer Options */}
                    <Card
                        sx={{
                            backgroundColor: "#FFFFFF2A",
                            padding: '10px',
                            marginBottom: 2,
                            border: '1px solid #FFFFFF2A',
                            borderRadius: '20px'
                        }}
                    >
                        <Box display='flex' justifyContent='space-between' gap='10px'>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 5.55859H22" stroke="#F2674A" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M14.22 2H19.78C21.56 2 22 2.44 22 4.2V8.31C22 10.07 21.56 10.51 19.78 10.51H14.22C12.44 10.51 12 10.07 12 8.31V4.2C12 2.44 12.44 2 14.22 2Z" stroke="#F2674A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M2 17.0586H12" stroke="#F2674A" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M4.22 13.5H9.78C11.56 13.5 12 13.94 12 15.7V19.81C12 21.57 11.56 22.01 9.78 22.01H4.22C2.44 22.01 2 21.57 2 19.81V15.7C2 13.94 2.44 13.5 4.22 13.5Z" stroke="#F2674A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M22 15C22 18.87 18.87 22 15 22L16.05 20.25" stroke="#F2674A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M2 9C2 5.13 5.13 2 9 2L7.95001 3.75" stroke="#F2674A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            <Box>
                                <Typography variant="subtitle1" className="text-white" lineHeight='22px' gutterBottom>
                                    Transfer to Trading Block Brokerage Account
                                </Typography>
                                <Typography variant="caption" className="text-secondary">
                                    Transfer money from your bank account to your TradingBlock Account
                                </Typography>
                            </Box>
                        </Box>
                    </Card>
                    <Card
                        sx={{
                            backgroundColor: "#FFFFFF2A",
                            padding: '10px',
                            marginBottom: 2,
                            border: '1px solid #FFFFFF2A',
                            borderRadius: '20px'
                        }}
                    >
                        <Box display='flex' justifyContent='space-between' gap='10px'>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.37 2.14984L21.37 5.74982C21.72 5.88982 22 6.30981 22 6.67981V9.99982C22 10.5498 21.55 10.9998 21 10.9998H3C2.45 10.9998 2 10.5498 2 9.99982V6.67981C2 6.30981 2.28 5.88982 2.63 5.74982L11.63 2.14984C11.83 2.06984 12.17 2.06984 12.37 2.14984Z" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M22 22H2V19C2 18.45 2.45 18 3 18H21C21.55 18 22 18.45 22 19V22Z" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M4 18V11" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M8 18V11" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M12 18V11" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M16 18V11" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M20 18V11" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M1 22H23" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M12 8.5C12.8284 8.5 13.5 7.82843 13.5 7C13.5 6.17157 12.8284 5.5 12 5.5C11.1716 5.5 10.5 6.17157 10.5 7C10.5 7.82843 11.1716 8.5 12 8.5Z" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>

                            <Box>
                                <Typography variant="subtitle1" lineHeight='22px' className="text-white" gutterBottom>
                                    Transfer to your Bank
                                </Typography>
                                <Typography variant="caption" className="text-secondary" >
                                    Transfer money from your TradingBlock account to your bank
                                </Typography>
                            </Box>
                        </Box>
                    </Card>

                    {/* Funds Available Section */}
                    <Card
                        sx={{
                            padding: '28px 20px',
                            background: "linear-gradient(#141414, #F2674A)",
                            height: '276px',
                            width: '360px',
                            marginTop: "auto",
                            borderRadius: '10px'
                        }}
                    >
                        <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_1886_8016)">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.0785 22.0715C10.0785 29.2701 15.5192 33.9091 21.916 33.9091C21.916 26.6739 18.1361 20.3475 10.0785 22.0715Z" fill="#BAED2C" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.0785 22.0742C10.0785 29.2728 15.5192 33.9117 21.916 33.9117C18.3921 28.8623 14.4461 24.9165 10.0785 22.0742Z" fill="#A0D904" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M33.7535 22.0715C33.7535 29.2701 28.3127 33.9091 21.916 33.9091C21.916 26.6739 25.6958 20.3475 33.7535 22.0715Z" fill="#BAED2C" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M33.7535 22.0742C33.7535 29.2728 28.3127 33.9117 21.916 33.9117C25.4399 28.8623 29.3858 24.9165 33.7535 22.0742Z" fill="#A0D904" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M22.9004 37.7863C22.9004 38.3299 22.4596 38.7707 21.916 38.7707C21.3723 38.7707 20.9316 38.3299 20.9316 37.7863V18.3594C20.9316 17.8157 21.3723 17.375 21.916 17.375C22.4596 17.375 22.9004 17.8157 22.9004 18.3594V37.7863Z" fill="#8BBD00" />
                                <path d="M24.6865 19.8519C29.624 18.3284 32.3915 13.0906 30.8679 8.15314C29.3443 3.21566 24.1066 0.448164 19.1691 1.97176C14.2316 3.49535 11.4641 8.73308 12.9877 13.6706C14.5113 18.608 19.749 21.3755 24.6865 19.8519Z" fill="#F9AC00" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M21.6239 20.2596C16.5943 20.1051 12.5644 15.9799 12.5644 10.913C12.5644 5.84619 16.5943 1.72081 21.6239 1.56641C26.6534 1.72081 30.6834 5.84605 30.6834 10.9129C30.6834 15.9798 26.6535 20.1051 21.6239 20.2594V20.2596Z" fill="#FCDB35" />
                                <path d="M21.916 18.3715C26.0367 18.3715 29.3771 15.031 29.3771 10.9104C29.3771 6.78969 26.0367 3.44922 21.916 3.44922C17.7953 3.44922 14.4548 6.78969 14.4548 10.9104C14.4548 15.031 17.7953 18.3715 21.916 18.3715Z" fill="#F19A00" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M22.3959 3.46484C26.2929 3.71234 29.3771 6.9508 29.3771 10.9102C29.3771 14.8697 26.2928 18.1081 22.3959 18.3556C18.4989 18.1081 15.4146 14.8697 15.4146 10.9102C15.4146 6.9508 18.4989 3.71234 22.3959 3.46484Z" fill="#F9AC00" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M48.9624 22.543C54.8762 24.8748 59.9127 33.1898 61.7378 40.2692C64.7994 52.1445 58.0044 54.6079 46.8638 54.8022C35.7233 54.6079 28.9281 52.1445 31.9898 40.2692C33.815 33.1898 38.8515 24.8748 44.7653 22.543H48.9624Z" fill="#DEDEDE" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M45.0518 54.7472C35.0006 54.3051 29.098 51.4857 31.9898 40.2692C33.815 33.1898 38.8515 24.8748 44.7653 22.543H45.3382C51.252 24.8748 56.2885 33.1898 58.1137 40.2692C61.0055 51.4857 55.1031 54.3051 45.0518 54.7472Z" fill="white" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M45.1856 23.9755L42.1542 23.9843C35.2963 14.592 36.9125 11.2475 40.0978 12.2385C41.0393 12.5314 41.6267 13.0308 42.1225 13.439C43.0104 14.1703 43.7182 14.511 44.476 13.4352C46.1253 11.0945 47.6026 11.0945 49.2518 13.4352C50.0096 14.5109 50.7174 14.1701 51.6053 13.439C52.1012 13.0308 52.6885 12.5314 53.63 12.2385C56.8153 11.2475 58.4315 14.592 51.5737 23.9843L48.5422 23.9755C47.909 23.9793 47.3864 23.9811 46.864 23.9811C46.3416 23.9811 45.8189 23.9793 45.1856 23.9755Z" fill="#DEDEDE" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M45.1856 23.9753L45.0517 23.9758L44.918 23.9753L44.628 23.977L43.2397 23.9811C42.8877 23.9811 42.5358 23.9803 42.1501 23.9786C35.2972 14.5906 36.9132 11.2478 40.0979 12.2385C41.0396 12.5314 41.6268 13.0308 42.1227 13.439C43.0106 14.1703 43.7183 14.5109 44.4762 13.4352C46.1256 11.0945 47.6025 11.0945 49.2519 13.4352C50.0098 14.5109 50.7177 14.1701 51.6054 13.439C51.8508 13.2369 52.1186 13.0126 52.4406 12.802C53.3087 14.2258 52.4731 17.7873 47.9537 23.9786C47.5679 23.9803 47.216 23.9811 46.864 23.9811L45.4756 23.977L45.1856 23.9753Z" fill="white" />
                                <path d="M40.7652 22.2423H52.9626C53.9232 22.2423 54.703 23.0222 54.703 23.9827C54.703 24.9435 53.9231 25.7232 52.9626 25.7232H40.7652C39.8046 25.7232 39.0247 24.9433 39.0247 23.9827C39.0247 23.0221 39.8046 22.2422 40.7652 22.2422V22.2423Z" fill="#873F30" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M50.0182 22.2422H40.7652C39.808 22.2422 39.0248 23.0253 39.0248 23.9826C39.0248 24.9398 39.808 25.7231 40.7652 25.7231H50.0182C50.9754 25.7231 51.7586 24.9399 51.7586 23.9827C51.7586 23.0255 50.9756 22.2422 50.0182 22.2422Z" fill="#A34F41" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M45.8803 44.8999C44.2582 44.6545 42.7399 43.8986 42.0479 42.635C41.7876 42.1585 41.9618 41.5606 42.4389 41.2997C42.9168 41.038 43.5146 41.2137 43.7763 41.6901C44.5077 43.0275 47.4379 43.3935 48.9495 42.6412C49.4239 42.4046 49.7472 41.9728 49.8177 41.4855C49.8523 41.236 49.8496 40.7609 49.428 40.3725C49.0464 40.0213 48.3971 39.7076 47.5504 39.467C47.2562 39.3829 46.9343 39.3071 46.5926 39.2271C45.5074 38.9696 44.2765 38.6788 43.3892 37.9901C42.5751 37.3577 42.0884 36.5037 42.0213 35.5846C41.9562 34.6878 42.2871 33.8115 42.9546 33.1159C43.6309 32.4121 44.6881 31.9458 45.88 31.7839V30.3754C45.88 29.8311 46.3199 29.3906 46.8636 29.3906C47.4072 29.3906 47.8485 29.8312 47.8485 30.3754V31.7929C49.5214 32.0302 50.8275 32.8143 51.4389 33.9483C51.6973 34.4268 51.5176 35.0239 51.039 35.2815C50.5612 35.5397 49.9632 35.3609 49.7057 34.8816C49.3967 34.3076 48.5881 33.8805 47.5435 33.7375C46.2638 33.5632 44.9618 33.8683 44.3754 34.479C44.0995 34.7664 43.9605 35.108 43.9857 35.4416C44.0107 35.8014 44.2229 36.1444 44.5957 36.4345C45.1454 36.8609 46.1119 37.0893 47.0467 37.3109C47.3944 37.3929 47.7529 37.4777 48.0879 37.5732C49.2462 37.9025 50.1463 38.3574 50.7625 38.9242C51.5529 39.6541 51.9196 40.6879 51.7665 41.7621C51.6045 42.8913 50.8805 43.8796 49.8258 44.4029C49.2503 44.6902 48.5685 44.8715 47.8487 44.9474V46.3421C47.8487 46.8863 47.4074 47.3269 46.8637 47.3269C46.3201 47.3269 45.8802 46.8863 45.8802 46.3421V44.9L45.8803 44.8999Z" fill="#F2674A" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M47.1345 53.8821C48.2338 52.0323 46.8413 49.6892 44.6912 49.7705C27.0218 49.7705 26.9906 35.403 10.4626 56.6589L19.2091 65.8282C23.1048 61.8995 27.7357 61.7181 32.8437 62.0236C35.6299 62.1902 38.9548 62.5925 42.0052 62.5252C46.2676 62.4309 48.8798 61.274 52.6227 59.2914C56.8839 57.034 64.2901 52.5381 65.5822 50.8398C67.4312 48.4089 65.0441 46.1194 62.2236 47.7477C58.9067 49.6628 50.124 53.8514 45.5993 55.1134C46.284 54.8165 46.8188 54.4131 47.1344 53.8819L47.1345 53.8821Z" fill="#FDB0A0" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M47.1345 53.8821C48.2338 52.0323 46.8413 49.6892 44.6912 49.7705C27.0218 49.7705 26.9906 35.403 10.4626 56.6589L17.214 63.7367C21.2958 59.7111 26.1352 59.5259 31.4675 59.8449C34.4046 60.0205 37.9098 60.4446 41.1258 60.3736C45.6192 60.2743 48.373 59.0547 52.3188 56.9644C56.7667 54.6083 64.4638 49.9391 65.9374 48.1093C65.3259 47.1713 63.8467 46.8107 62.2238 47.7477C58.9068 49.6628 50.1241 53.8514 45.5995 55.1134C46.2842 54.8165 46.819 54.4131 47.1345 53.8819V53.8821Z" fill="#FFCEC0" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M47.1346 53.8833C47.9921 52.4404 47.3333 50.6973 45.9814 50.0391C46.9599 51.1108 46.3036 52.6322 44.769 53.1905C43.9196 53.4994 42.775 53.6454 41.5314 53.6758C38.2829 53.7552 34.5454 52.9932 33.0674 52.224C32.5464 51.9531 31.9043 52.1559 31.6335 52.6769C31.3626 53.1979 31.5654 53.84 32.0864 54.1107C33.7942 54.9995 37.9986 55.8829 41.5811 55.7953C43.0608 55.7591 44.4604 55.5537 45.5994 55.1146C46.2841 54.8178 46.8189 54.4143 47.1344 53.8832L47.1346 53.8833Z" fill="#FDB0A0" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.76558 57.9228L9.81488 53.8735C10.6098 53.0786 11.9105 53.0787 12.7054 53.8735L22.3322 63.5001C23.1271 64.2949 23.127 65.5957 22.3322 66.3907L18.2829 70.44L5.76572 57.9227L5.76558 57.9228Z" fill="#2A93FC" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.76562 57.9228L9.81492 53.8735C10.6094 53.0789 11.9094 53.0787 12.7043 53.8725L12.7053 53.8735L20.2866 61.4548C21.0815 62.2497 21.0814 63.5504 20.2866 64.3454L16.2373 68.3947L5.76548 57.9229L5.76562 57.9228Z" fill="#4EB1FC" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M20.932 15.784C19.7982 15.5617 18.7643 14.9915 18.2614 14.0721C17.9997 13.5949 18.1754 12.997 18.6525 12.7361C19.1289 12.4751 19.7269 12.6501 19.9884 13.1265C20.3917 13.8632 22.2564 14.1778 23.2602 13.6782C23.5138 13.5522 23.6791 13.3338 23.7157 13.0797C23.7326 12.9604 23.7313 12.7319 23.532 12.5475C23.366 12.3951 23.023 12.1721 22.3214 11.9727C22.12 11.9157 21.8964 11.8628 21.6585 11.8066C20.8627 11.6182 19.9613 11.4047 19.2847 10.88C18.6435 10.3825 18.2613 9.70538 18.2077 8.97399C18.1541 8.2575 18.4172 7.55944 18.9458 7.00833C19.4186 6.51684 20.1276 6.17541 20.9319 6.02353V5.27316C20.9319 4.7295 21.3718 4.28906 21.916 4.28906C22.4602 4.28906 22.9002 4.72964 22.9002 5.27316V6.02944C24.0546 6.25022 24.9571 6.83522 25.4021 7.6597C25.6597 8.13825 25.4807 8.73534 25.0028 8.99297C24.5229 9.25116 23.9272 9.07228 23.6696 8.59374C23.4994 8.27859 22.985 8.02027 22.3573 7.93491C21.552 7.82508 20.7149 8.00874 20.3666 8.37211C20.2283 8.51513 20.1599 8.67783 20.1707 8.83027C20.1863 9.04514 20.3536 9.21727 20.4913 9.32442C20.8302 9.58739 21.5114 9.74869 22.1113 9.89044C22.3608 9.94936 22.6163 10.0104 22.8596 10.0789C23.7217 10.3243 24.3969 10.6673 24.8652 11.0997C25.4949 11.6799 25.7857 12.5027 25.665 13.3575C25.5369 14.2495 24.9663 15.0276 24.1366 15.4405C23.7674 15.6246 23.3459 15.7521 22.9002 15.8233V16.5514C22.9002 17.0951 22.4589 17.5355 21.916 17.5355C21.373 17.5355 20.9319 17.0949 20.9319 16.5514V15.7839L20.932 15.784Z" fill="#FFEB87" />
                            </g>
                            <defs>
                                <clipPath id="clip0_1886_8016">
                                    <rect width="72" height="72" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>

                        <Typography variant="h6" sx={{ mt: '10px' }} fontWeight={600} className="text-white" gutterBottom>
                            Funds Available for Withdraw
                        </Typography>
                        <Typography variant="h4" fontSize='45px' gutterBottom className="text-white" sx={{ fontWeight: "bold" }}>
                            $5040.00
                        </Typography>
                        <Typography variant="caption" className="text-secondary">
                            Withdrawable amount will be available in 2 business days.
                        </Typography>
                    </Card>
                </Box>

                {/* Main Content */}
                <Box sx={{ flex: 1, backgroundColor: "#FFFFFF1A", borderRadius: '20px', border: "1px solid #FFFFFF2A", padding: '20px' }}>
                    {/* Header */}
                    <Box mb='30px'>
                        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: '20px' }}>
                            <Typography variant="h5">
                                December 2024
                            </Typography>
                            <Button onClick={handleOpen} variant="contained" sx={{ fontSize: '14px' }} className="btn btn-primary" color="primary" startIcon={<AddSharp />}>
                                Deposit
                            </Button>
                        </Box>

                        {/* Transaction List */}
                        <Box sx={{}}>
                            <List>
                                <ListItem sx={{ backgroundColor: "#FFFFFF2A", borderRadius: '20px', padding: '20px 30px', height: '113px', marginBottom: 1 }}>
                                    <ListItemIcon sx={{ marginRight: '20px' }}>
                                        <Box height='73px' width='73px' borderRadius='50px' padding='10px 0px' textAlign='center' bgcolor='#FFFFFF1A'>
                                            <Typography variant="h5" fontWeight='600' color="#F2674A" mb='0px'>11</Typography>
                                            <Typography variant="subtitle2" color="#FFFFFF5A">DEC</Typography>
                                        </Box>
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={<Typography variant="h5">11 Dec, 2024 <span style={{ color: '#4A7AF2' }}>02:07 PM</span></Typography>}
                                        secondary="ID: 5409"
                                        primaryTypographyProps={{ color: "#fff" }}
                                        secondaryTypographyProps={{ color: "#FFFFFF", fontSize: '16px' }}
                                    />
                                    <Button variant="contained" sx={{ fontSize: '14px', marginRight: '50px', padding: '5px 20px', bgcolor: '#4A7AF233', color: '#4A7AF2' }} className="btn"  >
                                        Pending
                                    </Button>
                                    <Typography sx={{ marginLeft: "auto", fontWeight: '500', fontSize: '28px' }}>$100.00</Typography>
                                    <Typography><MoreVert sx={{ fontSize: "32px" }}></MoreVert></Typography>
                                </ListItem>
                            </List>

                        </Box>
                    </Box>
                    <Box mb='30px'>
                        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: '20px' }}>
                            <Typography variant="h5">
                                December 2024
                            </Typography>

                        </Box>

                        {/* Transaction List */}
                        <Box sx={{}}>
                            <List>
                                <ListItem sx={{ backgroundColor: "#FFFFFF2A", borderRadius: '20px', padding: '20px 30px', height: '113px', marginBottom: '20px' }}>
                                    <ListItemIcon sx={{ marginRight: '20px' }}>
                                        <Box height='73px' width='73px' borderRadius='50px' padding='10px 0px' textAlign='center' bgcolor='#FFFFFF1A'>
                                            <Typography variant="h5" fontWeight='600' color="#F2674A" mb='0px'>11</Typography>
                                            <Typography variant="subtitle2" color="#FFFFFF5A">DEC</Typography>
                                        </Box>
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={<Typography variant="h5">11 Dec, 2024 <span style={{ color: '#4A7AF2' }}>02:07 PM</span></Typography>}
                                        secondary="ID: 5409"
                                        primaryTypographyProps={{ color: "#fff" }}
                                        secondaryTypographyProps={{ color: "#FFFFFF", fontSize: '16px' }}
                                    />
                                    <Button variant="contained" sx={{ fontSize: '14px', marginRight: '50px', padding: '5px 20px', bgcolor: '#4A7AF233', color: '#4A7AF2' }} className="btn"  >
                                        Pending
                                    </Button>
                                    <Typography sx={{ marginLeft: "auto", fontWeight: '500', fontSize: '28px' }}>$100.00</Typography>
                                    <Typography><MoreVert sx={{ fontSize: "32px" }}></MoreVert></Typography>
                                </ListItem>
                                <ListItem sx={{ backgroundColor: "#FFFFFF2A", borderRadius: '20px', padding: '20px 30px', height: '113px', marginBottom: '20px' }}>
                                    <ListItemIcon sx={{ marginRight: '20px' }}>
                                        <Box height='73px' width='73px' borderRadius='50px' padding='10px 0px' textAlign='center' bgcolor='#FFFFFF1A'>
                                            <Typography variant="h5" fontWeight='600' color="#F2674A" mb='0px'>11</Typography>
                                            <Typography variant="subtitle2" color="#FFFFFF5A">DEC</Typography>
                                        </Box>
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={<Typography variant="h5">11 Dec, 2024 <span style={{ color: '#4A7AF2' }}>02:07 PM</span></Typography>}
                                        secondary="ID: 5409"
                                        primaryTypographyProps={{ color: "#fff" }}
                                        secondaryTypographyProps={{ color: "#FFFFFF", fontSize: '16px' }}
                                    />
                                    <Button variant="contained" sx={{ fontSize: '14px', marginRight: '50px', padding: '5px 20px', bgcolor: '#4A7AF233', color: '#4A7AF2' }} className="btn"  >
                                        Pending
                                    </Button>
                                    <Typography sx={{ marginLeft: "auto", fontWeight: '500', fontSize: '28px' }}>$100.00</Typography>
                                    <Typography><MoreVert sx={{ fontSize: "32px" }}></MoreVert></Typography>
                                </ListItem>
                            </List>

                        </Box>
                    </Box>
                </Box>
            </Box>
            <Dialog sx={{ '.MuiDialog-paper': { margin: "0px", maxWidth: "620px" } }} open={open} onClose={handleClose}>
                <Box width='620px' sx={{ borderRadius: '20px' }}>
                    <DialogTitle padding='22px 30px'>
                        <Grid container>
                            <Grid item xs={10}><Typography variant="h5" className="text-white">Deposit</Typography></Grid>
                            <Grid item><IconButton onClick={handleClose}><CancelOutlined sx={{ height: '36px', width: '36px' }}></CancelOutlined></IconButton></Grid>
                        </Grid>
                    </DialogTitle>
                    <Divider sx={{ borderColor: 'white' }}></Divider>
                    <DialogContent sx={{ padding: '0px', margin: '20px 30px' }}>
                        <Box sx={{ margin: '20px 0px 0px  0px', }}>
                            <FormControl fullWidth className="mb-30"
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        height: '48px',
                                    }
                                }}
                            >
                                <TextField
                                    // name="EmailId"
                                    label={
                                        <span>
                                          Deposit Amount{" "}
                                            <sup>
                                                <i
                                                    className="fa-solid fa-star-of-life"
                                                    style={{ color: "#fa0000", fontSize: "0.5rem" }}
                                                ></i>
                                            </sup>
                                        </span>
                                    }
                                    variant="outlined"
                                    className="text-secondary "
                                    sx={{

                                    }}
                                    InputLabelProps={{
                                        shrink: false,
                                    }}
                                />
                            </FormControl>
                        </Box>
                        <Box >
                            <FormControl fullWidth>
                                <FormLabel
                                className="text-secondary"
                                    component="legend"
                                    sx={{
                                        color: "text.primary",
                                        marginBottom: "8px",
                                        fontSize: "0.875rem",
                                        
                                    }}
                                >
                                    From
                                    <sup>
                                        <i
                                            className="fa-solid fa-star-of-life"
                                            style={{ color: "#fa0000", fontSize: "0.5rem" }}
                                        ></i>
                                    </sup>
                                </FormLabel>
                                <Box
                                    sx={{
                                        border: "1px solid #424242",
                                        borderRadius: "10px",
                                        padding: "20px",
                                        backgroundColor: "transparent",
                                    }}
                                >
                                    <RadioGroup
                                        value={value}
                                        onChange={handleChange}
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            gap: "8px",
                                        }}
                                    >
                                        <FormControlLabel
                                            value="Citibank Online"
                                            control={<Radio sx={{ color: "#FFFFFF2A" }} />}
                                            label={
                                                <Box display="flex" justifyContent="space-between" gap='5px' width="100%">
                                                    <Typography variant="subtitle1" className="text-white">Citibank Online</Typography>
                                                    <Typography variant="subtitle1" className="text-white">|</Typography>
                                                    <Typography variant="subtitle1" color="#4A7AF2">Savings</Typography>
                                                </Box>
                                            }
                                            sx={{
                                                margin: 0,
                                            }}
                                        />
                                        <FormControlLabel
                                            value="Chase"
                                            control={<Radio sx={{ color: "#FFFFFF" }} />}
                                            label={
                                                <Box display="flex" justifyContent="space-between" gap='5px' width="100%">
                                                    <Typography  variant="subtitle1" className="text-white">Chase</Typography>
                                                    <Typography variant="subtitle1" className="text-white">|</Typography>

                                                    <Typography variant="subtitle1" color='#4A7AF2'>Checking</Typography>
                                                </Box>
                                            }
                                            sx={{
                                                margin: 0,
                                            }}
                                        />
                                    </RadioGroup>
                                </Box>
                            </FormControl>
                        </Box>
                    </DialogContent>
                    <Divider sx={{ borderColor: 'white' }}></Divider>
                    <DialogActions  sx={{ width:'100%', padding: '20px 30px' }}>
                        <Button fullWidth onClick={handlesecond} sx={{ fontSize: '16px', fontWeight: 'bold', padding: "13px 100px" }} className="btn btn-primary">
                          Deposite
                        </Button>

                    </DialogActions>
                </Box>
            </Dialog>
            <Dialog sx={{boxShadow: '0px 8px 100px rgba(255, 255, 255, 0.3)'}}  open={open2} onClose={handleClose2}>
                <Box width='400px' sx={{borderRadius:'20px'}}>
                <DialogTitle padding='22px 30px'>
                    <Grid container>
                    <Grid item xs={10}><Typography  variant="h5" className="text-white">Deposit Amount</Typography></Grid>
                    <Grid item><IconButton onClick={handleClose2}><CancelOutlined sx={{height:'36px', width:'36px'}}></CancelOutlined></IconButton></Grid>
                    </Grid>
                    </DialogTitle>
                    <Divider sx={{borderColor:'white'}}></Divider>
                <DialogContent sx={{padding:'20px 30px'}}>
                    <Box display='flex' justifyContent='space-between' alignItems='center' mb='10px'>
                        <Typography className="text-secondary">Amount</Typography>
                        <Typography className="text-white">$100.00</Typography>
                    </Box>
                    <Box display='flex' justifyContent='space-between' alignItems='center' mb='10px'>
                        <Typography className="text-secondary">Status</Typography>
                        <Typography className="text-white">Pendingfirmcreateapproval</Typography>
                    </Box>
                    <Box display='flex' justifyContent='space-between' alignItems='center' mb='10px'>
                        <Typography className="text-secondary">Transaction ID</Typography>
                        <Typography className="text-white">2817</Typography>
                    </Box>
                    <Box display='flex' justifyContent='space-between' alignItems='center' mb='10px'>
                        <Typography className="text-secondary">Transfer Time</Typography>
                        <Typography className="text-white">11/12/2024 - 02:07:00</Typography>
                    </Box>
                    <Box display='flex' justifyContent='space-between' alignItems='center' mb='10px'>
                        <Typography className="text-secondary">Transfer From</Typography>
                        <Typography className="text-white">Citibank Online</Typography>
                    </Box>
                    <Box display='flex' justifyContent='space-between' alignItems='center' mb='10px'>
                        <Typography className="text-secondary">Account Number</Typography>
                        <Typography className="text-white">**** 1116</Typography>
                    </Box>
                    <Box display='flex' justifyContent='space-between' alignItems='center' mb='10px'>
                        <Typography className="text-secondary">Account Type</Typography>
                        <Typography className="text-white">Savings</Typography>
                    </Box>
                </DialogContent>
                <Divider sx={{borderColor:'white'}}></Divider>
                <DialogActions sx={{padding:'20px 30px'}}>
                    <Button onClick={handleClose2} sx={{fontSize:'16px',fontWeight:'bold', padding:"13px 100px"}} className="btn btn-primary">
                        Cancel Transaction
                    </Button>
                    
                </DialogActions>
                </Box>
            </Dialog>
        </Container>
    );
};

export default Transfer;
