import React, { useEffect, useState } from "react";
import {
  Container,
  Switch,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Box,
  Button,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Header3 from "../../Layout/Header3";
import { useLocation, useNavigate } from "react-router-dom";
import Axios from "../../../../config/axios";
import { UrlConstant } from "../../../../commom/UrlConstant";
import RangeInput from "../../InputFields/RangeInput";

const steps = [1, 2];
const CustomConnector = styled(StepConnector)(({ theme }) => ({
  [`& .MuiStepConnector-line`]: {
    borderColor: "#4CAF50", // Green color for the connector line
    borderTopWidth: 10,
  },
}));

const Stepper3 = () => {
  const location = useLocation();
  const navigation = useNavigate();
  const id = location?.state?.id

  const [activeStep, setActiveStep] = useState(1);
  const [preferenceDetails, setPreferenceDetails] = useState<any>({});
  const [preferenceType, setPreferenceType] = useState<any>("");
  const [preferenceLevel, setPreferenceLevel] = useState<any>("");
  const [fieldsData, setFieldsData] = useState<any>({});
  const [fieldsList, setFieldsList] = useState<any>([]);


  const handleChange = (e, field) => {
    const { name, value, type, checked } = e.target;
    console.log(field, type, value, "fieldfield")

    // For range inputs
    if (type === "range") {
      setPreferenceDetails((prevDetails) => ({
        ...prevDetails,
        [field]: {
          ...prevDetails[field],
          [name]: value,
        },
      }));
    }

    // For switch inputs (e.g., isActive)
    if (type === "checkbox" || type === "switch") {
      setPreferenceDetails((prevDetails) => ({
        ...prevDetails,
        [field]: {
          ...prevDetails[field],
          [name]: checked,
        },
      }));
    } else {
      setPreferenceDetails((prevDetails) => ({
        ...prevDetails,
        [field]: {
          ...prevDetails[field],
          [name]: value,
        },
      }));
    }

    // For other types of inputs
    if (type === "text" || type === "select") {
      setPreferenceDetails((prevDetails) => ({
        ...prevDetails,
        [field]: {
          ...prevDetails[field],
          [name]: value,
        },
      }));
    }

    console.log(preferenceDetails, "fieldsDatafieldsData")

  };


  const getPreferenceDetails = async (id) => {
    try {
      const response = await Axios.get(
        UrlConstant.Discovery.getPreferenceDetails.replace("{0}", id)
      );
      const resData = response.data;
      setPreferenceDetails(resData.data);
      setPreferenceType(resData.data?.type);
      setPreferenceLevel(resData.data?.level);
      console.log(resData, "======================")
    } catch (error: any) {
      console.error("ERRR : ", error);
    } finally {
    }
  };

  const getPreferenceFields = async (type) => {
    try {
      const response = await Axios.get(
        UrlConstant.Discovery.getPreferenceFields.replace("{0}", type)
      );
      const resData = response.data;
      setFieldsData(resData.data);
      console.log(resData, "======================")
    } catch (error: any) {
      console.error("ERRR : ", error);
    } finally {
    }
  };

  function filterDataByPreference(data, preferenceType) {
    if (!Array.isArray(data)) {
      throw new Error("Data must be an array");
    }
    let filteredDta

    if (preferenceLevel == "PRO") {
      filteredDta = data
    } else {
      filteredDta = data.filter(item => !item.isPro)
    }

    switch (preferenceType) {
      case "STOCK":
        return filteredDta.filter(item => item.is_stock === true);
      case "ETF":
        return filteredDta.filter(item => item.is_etf === true);
      default:
        throw new Error("Invalid preference type. Allowed values are 'STOCK' or 'ETF'.");
    }
  }

  useEffect(() => {
    if (id) {
      getPreferenceDetails(id)
    }
  }, [id])

  useEffect(() => {
    if (preferenceType) {
      getPreferenceFields(preferenceType)
    }
  }, [preferenceType])

  useEffect(() => {
    if (fieldsData.length) {
      const fields = filterDataByPreference(fieldsData, preferenceType)
      setFieldsList(fields)

    }
  }, [fieldsData])

  const handleSubmit = async () => {
    // Submit form logic
    const data = { ...preferenceDetails, preferenceId: id }
    try {
      const response = await Axios.post(
        UrlConstant.Discovery.updatePreferenceData, data)
      if (response.data.result) {
        navigation("/maindashboard/discovery")
      }
      console.log(response, "response")
    } catch (error: any) {
      console.error("ERRR : ", error);
    } finally {

    }
  };


  return (
    <Container maxWidth={false} sx={{ padding: "0 10px" }} className="bg-color">
      <Header3 />
      <Divider sx={{ marginTop: 1, borderColor: "black" }} />

      <Box sx={{ display: "flex", flexDirection: "column", gap: 4, marginTop: 3 }}>
        <Stepper
          activeStep={activeStep}
          connector={<CustomConnector />}
          sx={{ width: "12%" }} // Keeping the stepper width as in your original code
        >
          {steps.map((label, index) => (
            <Step key={label} sx={{ paddingRight: "0px", paddingLeft: "0px" }}>
              <StepLabel
                sx={{
                  paddingRight: "0px",
                  paddingLeft: "0px",
                  "& .MuiStepLabel-iconContainer": {
                    paddingRight: "0px",
                    paddingLeft: "0px",
                  },
                  // Step Icon customization
                  "& .MuiStepIcon-root": {
                    backgroundColor: activeStep === index ? "#4CAF50" : "transparent",
                    color: activeStep === index ? "white" : "#BDBDBD",
                    borderRadius: "50%",
                    width: "29px",
                    height: "30px",
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "18px",
                    fontWeight: "bold",
                    border: `2px solid ${activeStep === index ? "#4CAF50" : "#BDBDBD"}`,
                  },
                }}
              >
                {activeStep === index ? (index + 1) : ''}
              </StepLabel>
            </Step>
          ))}
        </Stepper>

        {activeStep === 1 && (
          <Box mt={0} sx={{ width: "100%", boxSizing: "border-box", gap: 2 }}>

            <Box mt={0}>
              {fieldsList?.length && fieldsList.map((item) => {
                console.log(preferenceDetails?.[item.fieldKey].isActive, item.fieldKey, "999999999999999999999999")
                return (
                  <>
                    <Box sx={{ display: "flex", flexDirection: "column", gap: 2, marginBottom: 5, width: "100%" }}>
                      {/* PE Ratio Heading with Circle and Switch */}
                      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "10px" }}>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Typography
                            variant="h6"
                            sx={{
                              fontWeight: "600",
                              color: "white",
                              marginRight: "5px",
                            }}
                          >
                            {item.fieldName}
                          </Typography>
                          {/* Circle with "!" inside */}
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "20px",
                              height: "20px",
                              backgroundColor: "transparent",
                              border: "1px solid #4A7AF2",
                              borderRadius: "50%",
                              color: "#4A7AF2",
                              fontSize: "16px",
                            }}
                          >
                            !
                          </Box>
                        </Box>
                        {/* PE Ratio Switch */}
                        <Switch
                          sx={{
                            "& .MuiSwitch-switchBase.Mui-checked": { color: "#f2674a" },
                            "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                              bgcolor: "#f2674a",
                            },
                            "& .MuiSwitch-track": { bgcolor: "rgba(255,255,255,0.4)" },
                          }}
                          checked={preferenceDetails?.[item.fieldKey]?.isActive}
                          onChange={(e) => handleChange(e, item?.fieldKey)}
                          name="isActive"
                        />
                      </Box>

                      {/* Fields in the same row (Min, Max, Priority) */}
                      <Box sx={{ display: "flex", gap: 3, width: "100%" }}>

                        {item.fieldType === "RANGE" ?
                          <>
                            <RangeInput min={item.min} max={item.max} steps={item.steps} minValue={preferenceDetails?.[item.fieldKey]?.min} maxValue={preferenceDetails?.[item.fieldKey]?.max} onRangeChange={(e) => handleChange(e, item.fieldKey)} />
                            {/* <TextField
                              className="text-secondary"
                              label={<span>Min <sup style={{ color: "red" }}>*</sup></span>}
                              name="min"
                              sx={{
                                backgroundColor: "bg-color",
                                borderRadius: 1,
                                flex: 1,
                                height: "50px",
                                "& label": { color: "#FFFFFF1A" },
                                "& .MuiInputBase-root": { color: "white" },
                              }}
                            />
                            <TextField
                              label={<span>Max <sup style={{ color: "red" }}>*</sup></span>}
                              name="max"
                              className="text-secondary"
                              sx={{
                                backgroundColor: "bg-color",
                                borderRadius: 1,
                                flex: 1,
                                height: "50px",
                                "& label": { color: "white" },
                                "& .MuiInputBase-root": { color: "white" },
                              }}
                              type="number"
                            /> */}
                          </>
                          : item.fieldType === "BOOLEAN" ?
                            <>
                              <FormControl fullWidth sx={{ flex: 1 }}>
                                <InputLabel sx={{ color: "gray !important", backgroundColor: "bg-color" }}>
                                  Value <sup style={{ color: "red" }}>*</sup>
                                </InputLabel>
                                <Select
                                  name="value"
                                  sx={{
                                    backgroundColor: "bg-color",
                                    borderRadius: 1,
                                    height: "52px", // Same height for all fields
                                    "& .MuiSelect-icon": { color: "white" },
                                    "& .MuiInputBase-root": { color: "white" },
                                  }}
                                  value={preferenceDetails?.[item.fieldKey]?.value ? "YES" : "NO"}
                                  onChange={(e) => handleChange(e, item?.fieldKey)}
                                >
                                  <MenuItem value="YES" sx={{ color: "black" }}>Yes</MenuItem>
                                  <MenuItem value="NO" sx={{ color: "black" }}>No</MenuItem>
                                </Select>
                              </FormControl>
                            </>
                            : null}



                        <FormControl fullWidth sx={{ flex: 1 }}>
                          <InputLabel sx={{ color: "gray !important", backgroundColor: "bg-color" }}>
                            Priority <sup style={{ color: "red" }}>*</sup>
                          </InputLabel>
                          <Select
                            name="priority"
                            sx={{
                              backgroundColor: "bg-color",
                              borderRadius: 1,
                              height: "52px",
                              "& .MuiSelect-icon": { color: "white" },
                              "& .MuiInputBase-root": { color: "white" },
                            }}
                            value={preferenceDetails?.[item.fieldKey]?.priority}
                            onChange={(e) => handleChange(e, item?.fieldKey)}
                          >
                            <MenuItem value="LOW" sx={{ color: "black" }}>Low</MenuItem>
                            <MenuItem value="MEDIUM" sx={{ color: "black" }}>Medium</MenuItem>
                            <MenuItem value="HIGH" sx={{ color: "black" }}>High</MenuItem>
                          </Select>
                        </FormControl>
                      </Box>
                    </Box>

                    {/* Dashed Divider */}
                    <Box
                      sx={{
                        height: "2px", // Thickness of the divider
                        background: "repeating-linear-gradient(to right, #FFFFFF1A 0px, #FFFFFF1A 20px, transparent 20px, transparent 40px)", // Added 20px more gap
                        margin: "30px 0", // Spacing above and below the divider
                      }}
                    />
                  </>
                )
              })}

            </Box>
          </Box>
        )}
      </Box>

      <Box
        sx={{
          height: "2px", // Thickness of the divider
          background: "repeating-linear-gradient(to right, #FFFFFF1A 0px, #FFFFFF1A 20px, transparent 20px)",
          margin: "30px 0", // Spacing above and below the divider
        }}
      />

      <Box mt="auto">

        <Box sx={{ display: "flex", justifyContent: "flex-end", paddingTop: "10px", paddingRight: "20px" }}>
          <Button
            className="btn-primary"
            onClick={handleSubmit}
            sx={{
              borderRadius: "50px",
              textTransform: "none",
              fontSize: "16px",
              padding: "13px 50px",
              marginLeft: "auto !important",
              marginBottom: "30px", // Adjust this value to move it up or down
            }}
          >
            Save & Next
          </Button>
        </Box>
      </Box>

    </Container>
  );
};

export default Stepper3;
