import { Box, Typography } from "@mui/material";

function TradingACDisclosure() {
  return (
    <>
      <Box display="flex" flexDirection="column" gap={3}>
        <Typography variant="h5" fontWeight={700} className="text-white">
          TradingBlock Disclouser
        </Typography>
        <Typography variant="body2" className="text-white">
          TradingBlock and Wolfpack are unaffiliated third-parties. TradingBlock
          provides brokerage services to subscribers of the Wolfpack application
          and pursuant to a Brokerage Services Agreement.
          <Typography my={2} variant="body2">
            TradingBlock. Member FINRA, NFA & SIPC. 311 S. Wacker Drive, Suite
            1775, Chicago IL 60606{" "}
          </Typography>
          Investing and trading may result in loss of capital. Market
          volatility, volume and system availability may delay account access
          and trade execution. Day trading is a high risk, speculative trading
          strategy and is not suitable for all investors. For more information
          on risks associated with day trading please read TradingBlock’s Day
          Trading Risk Disclosure. You are fully responsible for any investment
          decision you make. TradingBlock makes no investment recommendations
          and does not provide financial, tax or legal advice. For more
          information, please review TradingBlock’s Online Service Agreement.
          This is not an offer or solicitation in jurisdictions where
          TradingBlock is not authorized to do business or where such offer or
          solicitation would be contrary to the local laws and regulations of
          that jurisdiction. Contact TradingBlock for more information.
        </Typography>
      </Box>
    </>
  );
}

export default TradingACDisclosure;
