import { Avatar, Container, Box, CardContent, Typography, IconButton, Card } from "@mui/material";
import { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { UrlConstant } from "../../../commom/UrlConstant";
import Axios from "../../../config/axios";
import { endLoading, startLoading } from "../../../Redux/loadingSlice";
import { CommonDataTable } from "../SharedComponent/CommonDataTable";
import Yourinvestment from "../Cards/Yourinvestment";
import TotalFunds from "../charts/TotalFund";
import { BookmarkAddOutlined, BookmarkBorderOutlined, BookmarkOutlined, Padding } from "@mui/icons-material";
import { getCurrentUserId } from "../../../Utils/utils";
import Swal from "sweetalert2";
function WatchList() {
  const dispatch = useDispatch();
  const navigation = useNavigate();

  // const [setPage] = useState(1);
  const [page, setPage] = useState(1);
  const [rowperpage, setRowsPerPage] = useState(10);
  const [rows, setRows] = useState([]);
  const [totalRows] = useState(10);
  const [setSearch] = useState("");
  const [added, setadded] = useState(false);
  const listitem = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0, 1, 2, 3, 4, 5, 6]

  console.log(page, rowperpage);
  // ** Table Headers
  const columns = [
    {
      name: "Company",
      selector: (row) => [row.displayName],
      cell: (row) => (
        <div
          onClick={() => {
            navigateToDetail(row);
          }}
          className="stock-label"
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            cursor: "pointer",
          }}
        >
          <Avatar
            className="stock-icon"
            alt={row.symbol}
            src={row.branding?.logo_url ?? row.branding?.icon_url}
            sx={{
              width: 30,
              height: 30,
              backgroundColor: "white",
              objectFit: "fill",
            }}
          />
          {row.displayName}
        </div>
      ),
      sortable: true,
      center: false,
      // width: "700px",
      width: "auto",
    },
    {
      name: "Market Price",
      selector: (row) => [row.stockPrice],
      cell: (row) => (
        <div>
          {row.stockPrice === null ? "-" : `$${row.stockPrice?.toFixed(2)}`}
        </div>
      ),
      width: "150px",
      sortable: true,
      center: true,
    },
    {
      name: "1D Price",
      selector: (row) => [row.email],
      cell: (row) => <div>{row.email}</div>,
      sortable: true,
      width: "100px",
      center: true,
    },

    {
      name: "1D Percentage",
      selector: (row) => [row.phone_number],
      sortable: true,
      cell: (row) => <div>{row.phone_number}</div>,
      width: "150px",
      center: true,
    },
  ];
  const navigateToDetail = async (data) => {
    //stockdetail
    navigation("/maindashboard/stockdetail", {
      state: { symbol: data.symbol, title: "Watchlist" },
    });
  };
  const getWatchList = async () => {
    dispatch(startLoading());

    try {
      const response = await Axios.get(UrlConstant.WachList.GetWatchList);
      const resData = response.data;
      if (resData.result === true) {
        const list = resData.data.map((item) => {
          const v1 = item.regularMarketPrice ?? 0;
          const v2 = item.Last ?? 0;
          const diffrence = v2 - v1;
          const div = diffrence / v1;
          const res = div * 100;

          const lastPrice = parseFloat(item?.Last || "0.00");
          const previousClose = parseFloat(item?.PreviousClose || "0.00");
          const diffAmount = lastPrice - previousClose;
          const percentage = (diffAmount * 100.0) / previousClose;



          const itemDetail = {
            displayName: item.displayName,
            stockPrice: item.Last,
            res: res,
            diffrence: diffrence,
            branding: item.branding,
            symbol: item.symbol,
            lastPrice: lastPrice,
            previousClose: previousClose,
            diffAmount: diffAmount,
            percentage: percentage,
          };

          return itemDetail;
        });
        setRows(list);
      }
    } catch (error: any) {
      console.error("ERRR : ", error);
    } finally {
      dispatch(endLoading());
    }
  };

  const CreateWatchList = async (symbol) => {
    dispatch(startLoading());

    const payLoad = {
      userId: getCurrentUserId(),
      symbol: symbol,
    };
    try {
      const response = await Axios.post(
        UrlConstant.WachList.CreateWatchList,
        payLoad
      );
      const resData = response.data;
      Swal.fire(resData.msg);
    } catch (error: any) {
      console.error("ERRR : ", error);
    } finally {
      dispatch(endLoading());
    }
  };


  useEffect(() => {
    getWatchList();
  }, []);
  //  console.log(rows)
  rows.map(item => {
    console.log(item)
  })
  return (
    <Box
      width='100%'
      className="bg-dark "
      style={{
        display: "flex",
        height: "calc(100vh - 70px)",
        padding:'30px'
        
        // width:'100%'

      }}
    >
      <Container style={{ left: "0", }}>

        <Typography
          className="text-secondary"
          sx={{ marginBottom: "20px" }}
        >
          {" "}
          <span
            onClick={() => navigation(-1)}
            style={{ color: "#4A7AF2", cursor: "pointer" }}
          >
            Dashboard{" "}
          </span>{" "}
          / Watchlist
        </Typography>
        <Box marginBottom='20px'>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box display="flex" gap={2} alignItems="center" mb={1}>
              <Typography
                className="text-white"
                mb={0}
                style={{ fontSize: "1.5rem" }}
              >
                Watchlist
              </Typography>
            </Box>
          </Box>
        </Box>
        <Row className="dark-tbl no-border">
          <Box display="flex" flexWrap="wrap" gap="10px" >
            {rows.map((item: any, index: number) => (
              <Card
                key={index}
                className="card"
                sx={{
                  width: '220px', // Responsive widths
                  // height: '116px', // Ensures consistent height
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  cursor: "pointer",
                  padding:'15px 20px !important'
                }}
              >
                <CardContent className="text-white"sx={{padding:'0px !important'}}>
                  <Box display="flex" alignItems="center" justifyContent="space-between" mb='10px'>
                    <Box display="flex" alignItems="center" gap="10px">
                      <Avatar
                        alt={item?.displayName}
                        src={item?.branding?.icon_url}
                        sx={{ width: 36, height: 36 }}
                      />
                      <Typography variant="body2" sx={{
                          overflow: "hidden",
                          display: "-webkit-box",
                          WebkitLineClamp: 2,
                          WebkitBoxOrient: "vertical",
                          textOverflow: "ellipsis",
                          whiteSpace: "normal",
                          fontWeight: "bold",
                        }} fontWeight={600} onClick={() => { navigateToDetail(item) }}>
                        {item?.displayName}
                      </Typography>
                    </Box>
                    <IconButton
                      onClick={() => setadded(!added)}
                      sx={{
                        bgcolor: added ? '#4A7AF233' : 'transparent',
                        borderRadius: '10px',
                      }}
                    >
                      {added ? (
                        <BookmarkOutlined sx={{ fill: '#4A7AF2' }} />
                      ) : (
                        <BookmarkBorderOutlined onClick={() => { CreateWatchList(item.symbol) }} />
                      )}
                    </IconButton>
                  </Box>
                  <Typography variant="body1" fontWeight={600}>
                    ${item?.lastPrice}
                  </Typography>
                  <Typography variant="caption" sx={{ color: item.diffAmount > 0 ? "#4edf6d" : "#ff2a2a" }}>
                    {item.diffAmount?.toFixed(2)} ({item.percentage?.toFixed(2)}%)
                  </Typography>
                </CardContent>
              </Card>
            ))}
          </Box>
        </Row>
      </Container>

      <Container style={{width:'440px', padding:'0px'}}>
        <Typography
          component="h1"
          fontSize={24}
          className="text-white"
          mb={2}
        >
          Your Investment
        </Typography>
        {/* <TotalFunds></TotalFunds> */}
        <Yourinvestment></Yourinvestment>
      </Container>
    </Box>
  );
}
export default WatchList;
