import { Navigate, Route, Routes } from "react-router-dom";
import AiPicks from "../MainComponents/AiPicks";
import BoosterLoan from "../MainComponents/BoosterLoan";
import Dashboard from "../MainComponents/Dashboard";
import Discovery from "../MainComponents/Discovery";
import DiscussionBoard from "../MainComponents/DiscussionBoard";
import MyAccount from "../MainComponents/MyAccount";
import Portfolio from "../MainComponents/Portfolio";
import Profile from "../MainComponents/Profile";
import StockDetail from "../MainComponents/StockDetail";
import CreateApplication from "../MainComponents/TradingAccount/CreateApplication";
import CreateTradingBlockUser from "../MainComponents/TradingAccount/CreateTradingBlockUser";
import WatchList from "../MainComponents/WachList";
import Steppers from "../MainComponents/stepper/stepper";
import LineChart from "../charts/Linechart";
import Header from "./Header";
import "../../../../src/style/style.css";
import Stepper2 from "../MainComponents/Discovery/stepper2";
import MarketSignals from "../MainComponents/MarketSignals";
import MyPicks from "../MainComponents/MyPicks";
import Stepper3 from "../MainComponents/Discovery/stepper3";
import MoreOptions from "../MainComponents/MoreOptions";
import MarketMovers from "../MainComponents/MarketMovers";
import BoosterLoanDetails from "../MainComponents/BoosterLoanDetails";
import PortfolioStockDetail from "../MainComponents/PortfolioStockDetail";
import Transfer from "../MainComponents/Transfer";

function MainDashboard() {
  return (
    <>
      <Header />

      <Routes>
        <Route path="" element={<Dashboard />} />
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="boosterloan" element={<BoosterLoan />} />
        <Route path="boosterLoanDetails" element={<BoosterLoanDetails />} />
        <Route path="discovery" element={<Discovery />} />
        <Route path="discussionboard" element={<DiscussionBoard />} />
        <Route path="portfolio" element={<Portfolio />} />
        <Route path="portfolioStockInfo" element={<PortfolioStockDetail />} />
        <Route path="profile" element={<Profile />} />
        <Route path="myaccount" element={<MyAccount />} />
        <Route path="wachlist" element={<WatchList />} />
        <Route path="aipicks" element={<AiPicks />} />
        <Route path="mypicks" element={<MyPicks />} />
        <Route path="marketSignals" element={<MarketSignals />} />
        <Route path="marketMovers" element={<MarketMovers />} />
        <Route path="stockdetail" element={<StockDetail />} />
        <Route path="chart" element={<LineChart />} />

        <Route
          path="createTradingBlockUser"
          element={<CreateTradingBlockUser />}
        />
        <Route path="createApplication" element={<CreateApplication />} />
        {/* <Route path="createApplication" element={<Steppers />} /> */}
        <Route path="stepper" element={<Steppers />} />
        <Route path="discoveryform" element={<Stepper2 />} />
        <Route path="discoveryFields" element={<Stepper3 />} />
        <Route path="moreopt" element={<MoreOptions />}/>
        <Route path="transfer" element={<Transfer />} />
        <Route
          path="*"
          element={<Navigate to="/maindashboard/dashboard" replace />}
        />
      </Routes>
    </>
  );
}

export default MainDashboard;
