import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { Provider } from "react-redux";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
// import "./App.css";
import "../src/style/style.css"
import Biometric from "./components/auth/Biometric";
import Createpin from "./components/auth/Createpin";
import ForgotPassword from "./components/auth/ForgotPassword";
import Login from "./components/auth/Login";
import Loginwithpin from "./components/auth/Loginwithpin";
import Otp from "./components/auth/Otp";
import Reset from "./components/auth/Reset";
import Signup from "./components/auth/Signup";
import Terms from "./components/auth/Terms";
import Uploadimage from "./components/auth/Uploadimage";
import MainDashboard from "./components/common/Layout/MainDashboard";
import PrivateRoute from "./config/PrivateRoute";
import PublicRoute from "./config/PublicRoute";
import store from "./Redux/store";

function App() {
  const navigation = useNavigate();

  React.useEffect(() => {
    const pinVerified = sessionStorage.getItem("pinVerified");

    if (pinVerified === undefined || pinVerified === null) {
      navigation("/loginwithpin");
    }
  }, []);

  return (
    <Provider store={store}>
      <Routes>
        {/* Public Routes */}
        <Route path="/" element={<PublicRoute element={Signup} />} />
        <Route path="/login" element={<PublicRoute element={Login} />} />
        <Route
          path="/forgot"
          element={<PublicRoute element={ForgotPassword} />}
        />
        <Route path="/otp" element={<PublicRoute element={Otp} />} />
        <Route path="/reset" element={<PublicRoute element={Reset} />} />

        <Route
          path="/biometric"
          element={<PublicRoute element={Biometric} />}
        />

        {/* Protected MainDashboard Routes with Pin Verification */}
        <Route
          path="/createpin"
          element={<PrivateRoute element={Createpin} />}
        />
        <Route path="/terms" element={<PrivateRoute element={Terms} />} />

        <Route
          path="/uploadimage"
          element={<PrivateRoute element={Uploadimage} />}
        />

        <Route
          path="/loginwithpin"
          element={<PrivateRoute element={Loginwithpin} />}
        />

        <Route
          path="/maindashboard/*"
          element={<PrivateRoute element={MainDashboard} />}
        />

        <Route
          path="*"
          element={<Navigate to="/maindashboard/dashboard" replace />}
        />
      </Routes>
    </Provider>
  );
}

export default App;