import {
  Checkbox,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Step from "@mui/material/Step";
import StepConnector from "@mui/material/StepConnector";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";
import Header2 from "../../Layout/Header2";

const steps = [
  "Select campaign settings",
  "Create an ad group",
  "Create an ad",
];
const CustomConnector = styled(StepConnector)(({ theme }) => ({
  [`& .MuiStepConnector-line`]: {
    borderColor: "#FFFFFF1A",
    borderTopWidth: 8,
  },
}));

function Steppers() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [input, setinput] = React.useState<any>();
  const [skipped, setSkipped] = React.useState(new Set<number>());
  const [selectedDate, setSelectedDate] = React.useState<any>("");
  const [age, setAge] = React.useState<any>(10);
  const [inputValue, setInputValue] = React.useState("");

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleButtonClick = () => {
    alert(`Search for: ${inputValue}`);
  };

  const handleChange = (event) => {
    setAge(event.target.value as string);
  };

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const isStepOptional = (step: number) => {
    return step === 1;
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Container
      maxWidth={false}
      sx={{
        height: "100vh",
        paddingLeft: "0px !important",
        paddingRight: "0px !important",
      }}
      className="bg-color"
    >
      <Header2></Header2>
      <Divider></Divider>
      <Box mt={3} mx={2} sx={{ width: "100%", boxSizing: "border-box" }}>
        <Stepper
          activeStep={activeStep}
          connector={<CustomConnector />}
          sx={{ width: "20%" }}
        >
          {steps.map((label, index) => {
            const stepProps: { completed?: boolean } = {};
            const labelProps: {
              optional?: React.ReactNode;
            } = {};
            if (isStepOptional(index)) {
              // labelProps.optional = (
              //   <Typography variant="caption">Optional</Typography>
              // );
            }
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps} sx={{}}></StepLabel>
              </Step>
            );
          })}
        </Stepper>
        {activeStep === steps.length ? (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>
              All steps completed - you&apos;re finished
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button onClick={handleReset}>Reset</Button>
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
            {activeStep === 0 && (
              <Box mt={3} display="flex" flexDirection="column" gap={5}>
                <Typography variant="h6" className=" text-white">
                  1. Personal Information
                </Typography>
                <FormControl className="mb-20" sx={{ width: "33%" }}>
                  <TextField
                    name="FullName"
                    label={
                      <span>
                        FirstName{" "}
                        <sup>
                          <i
                            className="fa-solid fa-star-of-life"
                            style={{ color: "#fa0000", fontSize: "0.5rem" }}
                          ></i>
                        </sup>
                      </span>
                    }
                    value={input}
                    variant="outlined"
                    className="text-white"
                    onChange={(e) => setinput(e.target.value)}
                  />
                </FormControl>
                <TextField
                  // label="Select Date"
                  type="date"
                  className="text-white"
                  value={selectedDate}
                  onChange={handleDateChange}
                  sx={{
                    width: {
                      xs: "100%", // full width on extra small screens
                      // sm: "75%", // 75% width on small screens
                      // md: "50%", // 50% width on medium screens
                      lg: "20%", // 48% width on large screens and up
                    },
                    "& .MuiInputAdornment-root .MuiSvgIcon-root": {
                      color: "white !important", // Change calendar icon color to white
                    },
                  }}
                />
                <FormControl
                  sx={{
                    width: {
                      xs: "100%", // full width on extra small screens
                      // sm: "75%", // 75% width on small screens
                      // md: "50%", // 50% width on medium screens
                      lg: "20%", // 48% width on large screens and up
                    },
                  }}
                >
                  <Select
                    className="text-white"
                    sx={{
                      padding: "12px",
                      "& .MuiSelect-icon": {
                        color: "white", // Set dropdown icon color to white
                      },
                    }}
                    id="demo-simple-select"
                    value={age}
                    onChange={handleChange}
                  >
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </Select>
                </FormControl>
                <FormControlLabel
                  control={<Checkbox defaultChecked sx={{ color: "white" }} />}
                  label="Check it now "
                  className="text-white"
                />
                <FormControl>
                  <TextField
                    className="text-white"
                    variant="outlined"
                    value={inputValue}
                    onChange={handleInputChange}
                    sx={{ width: "20%" }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleButtonClick}
                            className="text-white"
                            edge="start"
                          >
                            $
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </Box>
            )}
            {activeStep === 1 && (
              <>
                <Typography variant="h3" margin={3} className="text-white">
                  Second Step
                </Typography>
              </>
            )}
            {activeStep === 2 && (
              <>
                <Typography variant="h3" margin={3} className="text-white">
                  Third Step
                </Typography>
              </>
            )}

            <Box
              sx={{
                display: "flex",
                width: "90%",
                alignItems: "center",
                justifyContent: "space-around",
                pt: 2,
              }}
            >
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1, border: "1px solid white" }}
                className="text-white"
              >
                Back
              </Button>

              {isStepOptional(activeStep) && (
                <Button
                  color="inherit"
                  className="text-white"
                  onClick={handleSkip}
                  sx={{ mr: 1, border: "1px solid white" }}
                >
                  Skip
                </Button>
              )}
              <Button onClick={handleNext}>
                {activeStep === steps.length - 1 ? "Finish" : "Next"}
              </Button>
            </Box>
          </React.Fragment>
        )}
      </Box>
    </Container>
  );
}

export default Steppers;
