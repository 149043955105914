import {
  Box,
  Button,
  Container,
  FormControl,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import { useFormik } from "formik";
import { KeyboardEvent, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { login } from "../../Redux/authSlice";
import { startBlocking } from "../../Redux/loadingSlice";
import { UrlConstant } from "../../commom/UrlConstant";
import Axios from "../../config/axios";
import "../../style/style.css";
import LeftSide from "../common/Layout/LeftSide";

interface FormValues {
  email: string;
  password: string;
}

function Login() {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [isLogin, setIsLogin] = useState<boolean>(false);

  const initialValues: FormValues = {
    email: "",
    password: "",
  };
  const dispatch = useDispatch();
  const navigation = useNavigate();

  const keyup = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.code === "Enter") {
      formik.handleSubmit();
    }
  };

  const onSubmit = async (values: FormValues) => {
    dispatch(startBlocking());
    
    try {
      const response = await Axios.post(UrlConstant.Auth.Login, values);
      // debugger;
      const resData = response.data;
      if (resData.result === true) {
        dispatch(login(resData.data));

        setTimeout(() => {
          if (!resData.data?.isVerify) {
            navigation("/otp", { state: { email: resData.data.email } });
          } else if (!resData.data?.acceptDisclosure) {
            navigation("/terms", { state: resData.data });
          } else if (!resData.data?.IsPINCreated) {
            navigation("/createpin", { state: resData.data });
          } else {
            navigation("/maindashboard", { state: resData.data });
          }
        }, 100);
      } else {
        Swal.fire(resData.msg ?? "Authentication Failed");
      }
    } catch (error: any) {
      console.error("ERRR : ", error);
      Swal.fire(error.response.data.msg ?? "Authentication Failed");
    } finally {
    }
  };

  const validationSchema = Yup.object({
    email: Yup.string().email("valid format").required("Email is required"),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters long")
      .required("Password is required"),
  });

  const formik = useFormik<FormValues>({
    initialValues,
    onSubmit,
    validationSchema,
  });

  return (
    <Container
      maxWidth={false}
      className="container"
      sx={{ maxWidth: "100%", margin: "0px" }}
    >
      <LeftSide />

      <RightSection className="RightSection bg-color">
        <Typography width="100%">
          <Typography
            className="Typography fw-700 mb-10 fs-24 heading"
            width="100%"
          >
            Welcome back to WolfPack
          </Typography>
          <Typography
            className="Typography fs-18 mb-50 text-secondary"
            variant="body1"
            sx={{ mb: { xs: 10, sm: 10 } }}
          >
            Your world awaits—let’s pick up where you left off.
          </Typography>
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          <Box width="100%">
            <FormControl fullWidth margin="normal" className="mb-30">
              <TextField
                // name="EmailId"
                label={
                  <span>
                    Email ID{" "}
                    <sup>
                      <i
                        className="fa-solid fa-star-of-life"
                        style={{ color: "#fa0000", fontSize: "0.5rem" }}
                      ></i>
                    </sup>
                  </span>
                }
                onKeyUp={keyup}
                variant="outlined"
                className="text-secondary "
                sx={{
                  " & .MuiOutlinedInput-notchedOutline": {
                    borderColor: `${
                      formik.errors.email && isLogin ? "red !important" : ""
                    } `,
                  },
                }}
                slotProps={{
                  input: {
                    startAdornment: (
                      <IconButton sx={{ background: "transparent" }}>
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7 20.5C4 20.5 2 19 2 15.5V8.5C2 5 4 3.5 7 3.5H17C20 3.5 22 5 22 8.5V15.5C22 19 20 20.5 17 20.5H7Z"
                            stroke="white"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M17 9L13.87 11.5C12.84 12.32 11.15 12.32 10.12 11.5L7 9"
                            stroke="white"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </IconButton>
                    ),
                  },
                }}
                InputLabelProps={{
                  shrink: false,
                }}
                {...formik.getFieldProps("email")}
              />
              {isLogin && formik.errors.email ? (
                <Typography className="error ">
                  {formik.errors.email}
                </Typography>
              ) : null}
            </FormControl>

            <FormControl fullWidth margin="normal" className="mb-20">
              <TextField
                // name="Password"
                label={
                  <span>
                    Password{" "}
                    <sup>
                      <i
                        className="fa-solid fa-star-of-life"
                        style={{ color: "#fa0000", fontSize: "0.5rem" }}
                      ></i>
                    </sup>
                  </span>
                }
                type={showPassword ? "text" : "password"}
                variant="outlined"
                className="text-secondary "
                onKeyUp={keyup}
                sx={{
                  " & .MuiOutlinedInput-notchedOutline": {
                    borderColor: `${
                      formik.errors.password && isLogin ? "red !important" : " "
                    } `,
                  },
                }}
                size="medium"
                InputProps={{
                  startAdornment: (
                    <IconButton>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6 10V8C6 4.69 7 2 12 2C17 2 18 4.69 18 8V10"
                          stroke="white"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M7 22C3 22 2 21 2 17V15C2 11 3 10 7 10H17C21 10 22 11 22 15V17C22 21 21 22 17 22H7Z"
                          stroke="white"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M15.9965 16H16.0054"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M11.9955 16H12.0045"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M7.99451 16H8.00349"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </IconButton>
                  ),
                  endAdornment: (
                    <IconButton onClick={() => setShowPassword(!showPassword)}>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.47004 14.53C8.82004 13.88 8.42004 12.99 8.42004 12C8.42004 10.02 10.02 8.42004 12 8.42004C12.99 8.42004 13.88 8.82004 14.53 9.47004L9.47004 14.53Z"
                          stroke="white"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M17.82 5.76998C16.07 4.44998 14.07 3.72998 12 3.72998C8.46997 3.72998 5.17997 5.80998 2.88997 9.40998C1.98997 10.82 1.98997 13.19 2.88997 14.6C3.67997 15.84 4.59997 16.91 5.59997 17.77"
                          stroke="white"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M8.42004 19.5301C9.56004 20.0101 10.77 20.2701 12 20.2701C15.53 20.2701 18.82 18.1901 21.11 14.5901C22.01 13.1801 22.01 10.8101 21.11 9.40005C20.78 8.88005 20.42 8.39005 20.05 7.93005"
                          stroke="white"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M15.5099 12.7C15.2499 14.11 14.0999 15.26 12.6899 15.52"
                          stroke="white"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M9.47 14.53L2 22"
                          stroke="white"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M22 2L14.53 9.47"
                          stroke="white"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </IconButton>
                  ),
                }}
                InputLabelProps={{
                  shrink: false,
                }}
                {...formik.getFieldProps("password")}
              />
              {isLogin && formik.errors.password ? (
                <Typography className="error ">
                  {formik.errors.password}
                </Typography>
              ) : null}
              <Link
                to="/forgot"
                className="mb-10 link"
                style={{ textAlign: "end", marginTop: "10px" }}
              >
                Forgot Passoword?
              </Link>
            </FormControl>

            <Button
              variant="contained"
              color="primary"
              fullWidth
              className="btn-primary fw-700   btn mb-10"
              sx={{fontSize:'16px'}}
              endIcon={
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.4399 14.62L19.9999 12.06L17.4399 9.5"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M9.76001 12.0601H19.93"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M11.76 20C7.34001 20 3.76001 17 3.76001 12C3.76001 7 7.34001 4 11.76 4"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              }
              type="submit"
              onClick={() => setIsLogin(true)}
            >
              Log In
            </Button>
          </Box>
        </form>

        <Typography sx={{ mt: 2 }} className="text-secondary">
          Don't have an account? &nbsp;{" "}
          <Link to="/" className="link">
            Sign Up
          </Link>
        </Typography>
      </RightSection>
    </Container>
  );
}

const RightSection = styled(Box)(({ theme }) => ({
  width: "50%",
  // padding: "4rem",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  padding: "70px 120px",
  alignItems: "center",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
  [theme.breakpoints.down("lg")]: {
  },
  [theme.breakpoints.down("sm")]: {
    padding: "40px 40px",
  },
}));

export default Login;
