

export const EnvironmentConfig = {
  DEV: 'https://devapi.wolfpack.com', // Development API
  STAGE: 'https://stageapi.wolfpack.com', // Staging API
  PROD: 'https://api.wolfpack.com', // Production API
};

export function getBaseUrl(): string {
  const environment = (localStorage.getItem('selectedEnvironment') || 'DEV').toUpperCase();
  return EnvironmentConfig[environment] || EnvironmentConfig.DEV; // Default to DEV
}

// Dynamic BASE_URL
export const BASE_URL = getBaseUrl();

export const UrlConstant = {
  Common: {
    CountryList: `${BASE_URL}/api/v1/countryDetails`,
  },

  Auth: {
    SignUp: `${BASE_URL}/api/v1/signup`,
    VerifyEmailByOTP: `${BASE_URL}/api/v1/user/verifyEmail`,
    ResendOTP: `${BASE_URL}/api/v1/user/resendOTPVerifyEmail`,
    Login: `${BASE_URL}/api/v1/login`,
    ForgotPassword: `${BASE_URL}/api/v1/forgotPassword`,
    ResetPassword: `${BASE_URL}/api/v1/resetPassword`,
    AcceptDisclosure: `${BASE_URL}/api/v1/user/acceptProductDisclosure`,
    CreatePin: `${BASE_URL}/api/v1/user/createPin`,
    LoginWithPin: `${BASE_URL}/api/v1/user/loginWithPin`,
    UploadProfilePic: `${BASE_URL}/api/v1/user/updateUserProfilePic`,
  },
  TradingAccount: {
    GetSecurityQuestion: `${BASE_URL}/api/v1/trading-block/getSecurityQuestions`,
    CreateUser: `${BASE_URL}/api/v1/trading-block/create-user`,
    CheckAccountStatus: `${BASE_URL}/api/v1/trading-block/get-account-status`,
    CreateAccount: `${BASE_URL}/api/v1/trading-block/create-account`,
    GetSectionAnswer: `${BASE_URL}/api/v1/trading-block/get-answers`,
    SubmitAnswer: `${BASE_URL}/api/v1/trading-block/submit-answer`,
    SubmitForm: `${BASE_URL}/api/v1/trading-block/submit-form`,
  },
  WachList: {
    GetWatchList: `${BASE_URL}/api/v1/watchlist/list`,
    CreateWatchList: `${BASE_URL}/api/v1/watchlist/create`,
    RemoveWatchList: `${BASE_URL}/api/v1/watchlist/remove`,
  },
  AiPicks: {
    GetAiPicks: `${BASE_URL}/api/v1/top-picks`,
    GetAiPicksOfFollowing: `${BASE_URL}/api/v1/user/topPicksOfFollowing?type={0}`,
  },
  MyPicks: {
    GetMyPicksLive: `${BASE_URL}/api/v1/recommendation/list?type={0}`,
    GetMyPicksHistory: `${BASE_URL}/api/v1/recommendation/history?type={0}`,
  },
  Stock: {
    GetStockDetail: `/api/v1/stockdata/{0}`,
    GetTiker: `/api/v1/trading-block/delayed-ticker?ticker={0}`
  },
  Discovery: {
    preferences: `${BASE_URL}/api/v1/preference`,
    updateActivation: `${BASE_URL}/api/v1/preference/updateActivation`,
    createPreference: `${BASE_URL}/api/v1/preference/createPreference`,
    getPreferenceDetails: `${BASE_URL}/api/v1/preference/getPreferenceDetails?preferenceId={0}`,
    getPreferenceFields: `${BASE_URL}/api/v1/field/getFieldList?type={0}`,
    updatePreferenceData: `${BASE_URL}/api/v1/preference/updatePreferenceData`,
    deletePreferenceData: `${BASE_URL}/api/v1/preference/deletePreference`
  },
  MarketMovers: {
    GetMarketMovers: `${BASE_URL}/api/v1//trading-block/market-movers?type={0}`,
  },
  BuySell: {
    AvailbleQty: `${BASE_URL}/api/v1/trading-block/getQuantity/{symbol}`,
    GetTradingBalance: `${BASE_URL}/api/v1/trading-block/check-trading-balance`,
    PlaceBuyOrderFake: `${BASE_URL}/api/v1/trading-block/place-order-fake`,
    PlaceBuyOrder: `${BASE_URL}/api/v1/trading-block/place-order`
  },
  BoosterLoan: {
    CheckBoosterEligibility: `${BASE_URL}/api/v1/booster-loan/check-loan-eligibility`,
    CheckBoosterLoan: `${BASE_URL}/api/v1/booster-loan/check-loan-for-stock?symbol={0}`,
    GetLoanAmount: `${BASE_URL}/api/v1/booster-loan/get-loan-amount?amount={0}`,
    GetPaymentMethod: `${BASE_URL}/api/v1/booster-loan/get-loan-payment-method`,
    UpdatePaymentMethod: `${BASE_URL}/api/v1/booster-loan/update-loan-payment-method?loanId={0}&customerPaymentProfileId={1}`,
    GetLoanDetails: `${BASE_URL}/api/v1/booster-loan/loan-draft?symbol={0}&price={1}`,
    GetBoosterSummary: `${BASE_URL}/api/v1/booster-loan/loan-home`,
    GetBoosterLoanRepayment: `${BASE_URL}/api/v1/booster-loan/loan-repayment`,
    GetBoosterLoanActive: `${BASE_URL}/api/v1/booster-loan/user-loan?isActive={0}`,
    GetBoosterLoanInstallment: `${BASE_URL}/api/v1/booster-loan/loan-installment?loanId={0}`,
    // https://devapi.wolfpack.com/api/v1/booster-loan/update-loan-payment-method?loanId=0b235fbe-7518-4e8b-abd7-965c6aa41bb6&customerPaymentProfileId=924714939
    // https://devapi.wolfpack.com/api/v1/booster-loan/loan-installment?loanId=0b235fbe-7518-4e8b-abd7-965c6aa41bb6
    // https://devapi.wolfpack.com/api/v1/booster-loan/user-loan?isActive=true
    // https://devapi.wolfpack.com/api/v1/booster-loan/loan-repayment

  },
  Market: {
    GetMarketSignalsFilters: `${BASE_URL}/api/v1/premium/get-filters`,
    GetMarketSignalsFiltersData: `${BASE_URL}/api/v1/premium/get-filter-data`
  },
  Portfolio: {
    GetPortfolio: `${BASE_URL}/api/v1/trading-block/portfolio-v1`,
    TransactionHistory: `${BASE_URL}/api/v1/trading-block/order-transaction-history`,
  },
};
