import moment from "moment";
export const isAuthenticated = () => {
  const user = localStorage.getItem("user");
  return user ? true : false;
};

export const getUserFromLocalStorage = () => {
  const userJson = localStorage.getItem("user");
  return userJson ? JSON.parse(userJson) : null;
};

export const getCurrentUserId = () => {
  const userJson = localStorage.getItem("user");
  return userJson ? JSON.parse(userJson)._id : null;
};
export const currencyFormate = (value: any) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(value || 0);
};
export function getDateFromTimestamp(timestamp: any) {
  if (parseInt(timestamp) === 0) {
    return "--/--/----";
  }
  const utcMoment = moment.utc(+timestamp);
  return utcMoment.format("DD/MM/YYYY");
}

export function throttle(callback: any, delay = 1000) {
  let shouldWait = false;

  return (...args: any) => {
    if (shouldWait) return;
    callback(...args);
    shouldWait = true;
    setTimeout(() => {
      shouldWait = false;
    }, delay);
  };
}
