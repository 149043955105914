import { Box, Button, Container, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { useLocation, useNavigate } from "react-router-dom";

import { useRef, useState } from "react";
import "../../style/style.css";
import LeftSide from "../common/Layout/LeftSide";
import Axios from "../../config/axios";
import { UrlConstant } from "../../commom/UrlConstant";

function Uploadimage() {
  const location = useLocation();
  const navigate = useNavigate();
  // debugger;
  const user = location.state;
  const [selectedImage, setSelectedImage] = useState<
    string | ArrayBuffer | null
  >(null);
  const [profilePic, setProfilePic] = useState(null);

  // Create a ref for the file input
  const fileInputRef = useRef<HTMLInputElement>(null);

  // Function to handle file selection
  const handleFileSelect = (event) => {
    const file = event.target.files?.[0]; // Get the first selected file
    setProfilePic(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result); // Set the preview image
      };
      reader.readAsDataURL(file); // Read the file as a data URL
    }
  };

  // Function to trigger the file input dialog
  const handleUploadClick = () => {
    fileInputRef.current?.click();
  };
  const goToCreatePin = () => {
    navigate("/createpin", { state: user });
  };

  const uploadProfilePic = async () => {
    if (profilePic !== null) {
      const myHeaders = {
        //Authorization: user.userToken,
        //"Content-Type": "multipart/form-data", // Set the content type for form data
      };
      const formData = new FormData();
      formData.append("file", profilePic);

      try {
        const response = await Axios.post(
          UrlConstant.Auth.UploadProfilePic,
          formData,
          { headers: myHeaders }
        );
        const resData = response.data;
        if (resData.result === true) {
          goToCreatePin();
        } else {
          console.error("Upload failed:", resData.message);
        }
      } catch (error: any) {
        console.error("ERRR : ", error);
      }
    }
  };

  return (
    <Container
      maxWidth={false}
      className="container"
      sx={{ maxWidth: "100%", margin: "0px" }}
    >
      <LeftSide />

      <RightSection className="RightSection bg-color">
        <Typography width="100%">
          <Typography
            className="Typography fw-700 mb-10 fs-24 heading"
            width="100%"
          >
            Profile Image
          </Typography>
          <Typography
            className="Typography fs-18 mb-50 text-secondary"
            variant="body1"
            sx={{ mb: { xs: 10, sm: 10 } }}
          >
            Add Your Profile Picture to Complete Your Account.{" "}
          </Typography>
        </Typography>
        <form style={{ width: "100%", height: "100%", marginBottom: "50px" }}>
          <Box
            width="100%"
            height="70%"
            my={5}
            sx={{
              border: "2px dashed #FFFFFF33", // Dashed border
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "16px", // Rounded corners
              backgroundColor: "transparent", // Dark background
              color: "#FFFFFF", // Text color
              position: "relative",
            }}
          >
            {/* Hidden input for selecting files */}
            <input
              ref={fileInputRef}
              type="file"
              accept="image/png, image/jpeg"
              style={{ display: "none" }}
              onChange={handleFileSelect} // Handles file selection
            />

            {selectedImage && ( // Show the preview if an image is selected
              <Box
                component="img"
                src={selectedImage as string}
                alt="Selected Preview"
                sx={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover", // Maintain aspect ratio
                  borderRadius: "16px", // Match border radius of the box
                  position: "absolute", // Position absolute to fill the box
                  top: 0,
                  left: 0,
                  zIndex: 1, // Make sure the image is above the border
                }}
              />
            )}
            <Box
              component="img"
              src="/image.png" // Your icon path here
              alt="Profile Image Icon"
              sx={{ width: 60, height: 60, marginBottom: 2 }}
            />

            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              <span
                onClick={handleUploadClick}
                style={{ color: "blue", cursor: "pointer" }}
              >
                Upload
              </span>{" "}
              or Drag here
            </Typography>

            <Typography variant="body1" sx={{ color: "#A5A5A5" }}>
              .PNG or .JPG supported
            </Typography>

            <Typography variant="body1" sx={{ color: "#A5A5A5" }}>
              Max 2 MB
            </Typography>
          </Box>

          <Button
            variant="contained"
            color="primary"
            fullWidth
            className="btn-primary fw-700 fs-18  btn mb-10"
            endIcon={
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.9098 7.02988L11.8798 4.99988L9.84977 7.02988C9.55977 7.31988 9.07977 7.31988 8.78977 7.02988C8.49977 6.73988 8.49977 6.25988 8.78977 5.96988L11.3498 3.40988C11.6398 3.11988 12.1198 3.11988 12.4098 3.40988L14.9698 5.96988C15.2598 6.25988 15.2598 6.73988 14.9698 7.02988C14.8198 7.17988 14.6298 7.24988 14.4398 7.24988C14.2498 7.24988 14.0598 7.17988 13.9098 7.02988Z"
                  fill="white"
                />
                <path
                  d="M11.1309 14.18V4.01001C11.1309 3.60001 11.4709 3.26001 11.8809 3.26001C12.2909 3.26001 12.6309 3.60001 12.6309 4.01001V14.18C12.6309 14.6 12.2909 14.93 11.8809 14.93C11.4709 14.93 11.1309 14.59 11.1309 14.18Z"
                  fill="white"
                />
                <path
                  d="M3.25 12C3.25 11.59 3.59 11.25 4 11.25C4.41 11.25 4.75 11.59 4.75 12C4.75 16.27 7.73 19.25 12 19.25C16.27 19.25 19.25 16.27 19.25 12C19.25 11.59 19.59 11.25 20 11.25C20.41 11.25 20.75 11.59 20.75 12C20.75 17.15 17.15 20.75 12 20.75C6.85 20.75 3.25 17.15 3.25 12Z"
                  fill="white"
                />
              </svg>
            }
            // type="submit"
            // disabled
            // onClick={uploadProfilePic}
            onClick={(e) => {
              e.preventDefault(); // Prevent form submission
              uploadProfilePic();
            }}
          >
            Upload
          </Button>
        </form>

        <button
          onClick={goToCreatePin}
          className="link"
          style={{
            backgroundColor: "transparent",
            boxShadow: "none",
            border: 0,
          }}
        >
          Skip
        </button>
      </RightSection>
    </Container>
  );
}

export default Uploadimage;
const RightSection = styled(Box)(({ theme }) => ({
  width: "50%",
  // padding: "4rem",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "40px 40px",
  },
}));
