import React, { useEffect, useState } from "react";
import {
  Container,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Step from "@mui/material/Step";
import StepConnector from "@mui/material/StepConnector";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Header3 from "../../Layout/Header3";
import Axios from "../../../../config/axios";
import { UrlConstant } from "../../../../commom/UrlConstant";
import { useLocation, useNavigate } from 'react-router-dom';

const steps = [1, 2];
const CustomConnector = styled(StepConnector)(({ theme }) => ({
  [`& .MuiStepConnector-line`]: {
    borderColor: "#FFFFFF1A",
    borderTopWidth: 10,
  },
}));

function Stepper2() {
  const location = useLocation();
  const navigation = useNavigate();

  const id = location?.state?.id

  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set<number>());
  const [preferenceName, setPreferenceName] = useState<string>("");
  const [preferenceType, setPreferenceType] = useState<string>("");
  const [profileLevel, setProfileLevel] = useState<string>("");
  const [investmentType, setInvestmentType] = useState<string>("");
  const [preferenceDetails, setPreferenceDetails] = useState<any>({});


  const isStepOptional = (step: number) => step === 1;
  const isStepSkipped = (step: number) => skipped.has(step);

  const getPreferenceDetails = async (id) => {
    try {
      const response = await Axios.get(
        UrlConstant.Discovery.getPreferenceDetails.replace("{0}", id)
      );
      const resData = response.data;
      setPreferenceDetails(resData.data);
    } catch (error: any) {
      console.error("ERRR : ", error);
    } finally {
    }
  };

  useEffect(() => {
    if (id) {
      getPreferenceDetails(id)
    }
  }, [id])

  useEffect(() => {
    if (preferenceDetails) {
      setPreferenceName(preferenceDetails?.preferenceName)
      setPreferenceType(preferenceDetails?.preferenceType)
      setProfileLevel(preferenceDetails?.level)
      setInvestmentType(preferenceDetails?.type)
    }

  }, [preferenceDetails])

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      throw new Error("You can't skip a step that isn't optional.");
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleSubmit = async () => {
    if (!preferenceName || !preferenceType || !profileLevel || !investmentType) {
      alert('Please fill out all the required fields.');
      return;
    }

    try {
      let payload
      let URL

      if (id) {
        URL = UrlConstant.Discovery.updatePreferenceData
        payload = {
          preferenceName: preferenceName,
          type: investmentType,
          preferenceType: preferenceType,
          stockExchangeName: "NYSE", // dynamic
          level: profileLevel,
          preferenceId: id
        };
      } else {
        URL = UrlConstant.Discovery.createPreference
        payload = {
          preferenceName: preferenceName,
          type: investmentType,
          preferenceType: preferenceType,
          stockExchangeName: "NYSE", // dynamic
          level: profileLevel,
        };
      }

      const response = await Axios.post(URL, payload);
      console.log("API Response:", response.data);


      alert('Preference saved successfully!');
      navigation("/maindashboard/discoveryFields", {
        state: {
          id: id ?? response.data.data._id,
        },
      });
    } catch (error) {
      console.error("API Error:", error);
      alert("An error occurred while saving your preference.");
    }
  };



  return (
    <Container
      maxWidth={false}
      sx={{
        height: "100vh",
        paddingLeft: "20px !important",
        paddingRight: "20px !important",
      }}
      className="bg-color"
    >
      <Header3 />
      <Divider />
      <Box mt={3} sx={{ width: "100%", height: "75%", boxSizing: "border-box" }}>
        <Stepper activeStep={activeStep} connector={<CustomConnector />} sx={{ width: "10%" }}>
          {steps.map((label, index) => {
            const stepProps: { completed?: boolean } = {};
            const labelProps: {
              optional?: React.ReactNode;
            } = {};

            return (
              <Step key={label} sx={{ paddingRight: "0px", paddingLeft: "0px" }} {...stepProps}>
                <StepLabel {...labelProps} sx={{ paddingRight: "0px", paddingLeft: "0px", "& .MuiStepLabel-iconContainer": { paddingRight: "0px" } }} />
              </Step>
            );
          })}
        </Stepper>
        {activeStep === steps.length ? (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }} />
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button onClick={handleReset}>Reset</Button>
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
            {activeStep === 0 && (
              <Box mt={3} display="flex" flexDirection="column" gap={5}>
                <Typography variant="h6" className=" text-white">
                  Start Creating Preference Profile
                </Typography>
                <Box display="flex" alignItems="center" width="100%" gap="20px" flexWrap="wrap">
                  <FormControl sx={{ width: "32%" }}>
                    <TextField
                      name="FullName"
                      InputLabelProps={{ shrink: false }}
                      label={<span>Profile Name <sup><i className="fa-solid fa-star-of-life" style={{ color: "#fa0000", fontSize: "0.5rem" }}></i></sup></span>}
                      value={preferenceName}
                      variant="outlined"
                      className="text-white"
                      onChange={(e) => setPreferenceName(e.target.value)}
                    />
                  </FormControl>
                  <FormControl sx={{ width: "32%" }}>
                    <InputLabel className="country fs-18" sx={{ color: "white !important" }} shrink={false}>
                      <span>Profile Type <sup><i className="fa-solid fa-star-of-life" style={{ color: "#fa0000", fontSize: "0.5rem" }}></i></sup></span>
                    </InputLabel>
                    <Select
                      className="text-white"
                      value={preferenceType}
                      onChange={(e) => setPreferenceType(e.target.value)}
                      sx={{ padding: "12px", "& .MuiSelect-icon": { color: "white" } }}
                    >
                      <MenuItem value={"BUY"}>Buy</MenuItem>
                      <MenuItem value={"SELL"}>Sell</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl sx={{ width: "32%" }}>
                    <InputLabel className="country fs-18" sx={{ color: "white !important" }} shrink={false}>
                      <span>Profile Level <sup><i className="fa-solid fa-star-of-life" style={{ color: "#fa0000", fontSize: "0.5rem" }}></i></sup></span>
                    </InputLabel>
                    <Select
                      className="text-white"
                      value={profileLevel}
                      onChange={(e) => setProfileLevel(e.target.value)}
                      sx={{ padding: "12px", "& .MuiSelect-icon": { color: "white" } }}
                    >
                      <MenuItem value={"BEGINNER"}>Beginner</MenuItem>
                      <MenuItem value={"PRO"}>Advanced</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl sx={{ mt: "20px", width: "32%" }}>
                    <InputLabel sx={{ color: "white !important" }} shrink={false}>
                      <span>Investment Type <sup><i className="fa-solid fa-star-of-life" style={{ color: "#fa0000", fontSize: "0.5rem" }}></i></sup></span>
                    </InputLabel>
                    <Select
                      className="text-white"
                      value={investmentType}
                      onChange={(e) => setInvestmentType(e.target.value)}
                      sx={{ padding: "12px", "& .MuiSelect-icon": { color: "white" } }}
                    >
                      <MenuItem value={"STOCK"}>Stock</MenuItem>
                      <MenuItem value={"ETF"}>ETF</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Box>
            )}
          </React.Fragment>
        )}
      </Box>

      <Box
        sx={{
          height: "2px", // Thickness of the divider
          background: "repeating-linear-gradient(to right, #FFFFFF1A 0px, #FFFFFF1A 20px, transparent 20px)",
          margin: "8px 0", // Spacing above and below the divider
        }}
      />

      <Box mt="auto">
        <Divider />
        <Box sx={{ display: "flex", justifyContent: "flex-end", paddingTop: "10px", paddingRight: "20px" }}>
          <Button
            className="btn-primary"
            onClick={handleSubmit}
            sx={{
              borderRadius: '50px',
              textTransform: 'none',
              fontSize: '16px',
              padding: '19px 50px',
              marginLeft: 'auto !important',
            }}
          >
            Save & Next
          </Button>
        </Box>
      </Box>
    </Container>
  );
}

export default Stepper2;
