import React from "react";
import Chart from "react-apexcharts";

type LineChartProps = {
  data?: { x: string; y: number }[]; // Optional data prop
  width?: string | number;
  height?: string | number;
};

const LineChart: React.FC<LineChartProps> = ({
  data,
  width = "100%",
  height = "300px",
}) => {
  // Fallback example data if no valid data is passed
  const defaultData = [
    { x: "2024-10-24T13:00:00.000Z", y: 15 },
    { x: "2024-10-24T13:05:00.000Z", y: 12 },
    { x: "2024-10-24T13:10:00.000Z", y: 13.8 },
    { x: "2024-10-24T13:15:00.000Z", y: 14.7 },
    { x: "2024-10-24T13:20:00.000Z", y: 15.2 },
    { x: "2024-10-24T13:25:00.000Z", y: 13 },
  ];

  // Use passed data or fallback to defaultData if it's empty or undefined
  const validatedData = data && data.length > 0 ? data : defaultData;

  const options: ApexCharts.ApexOptions = {
    chart: {
      id: "basic-line",
      background: "transparent",
      toolbar: { show: false },
    },
    xaxis: {
      type: "datetime",
      labels: { show: false },
      axisBorder: { show: false },
      axisTicks: { show: false },
      tooltip: { enabled: false },
    },
    yaxis: {
      axisBorder: {
        show: false,
        color: "#ccc",
        width: 1,
      },
      labels: { show: false },
    },
    stroke: {
      curve: "straight",
      width: 2,
      colors: ["#FF4500"],
    },
    tooltip: {
      enabled: true,
      custom: ({ series, seriesIndex, dataPointIndex, w }) => {
        const price = series[seriesIndex][dataPointIndex];
        const time = new Date(
          w.globals.seriesX[seriesIndex][dataPointIndex]
        ).toLocaleTimeString("en-US", {
          hour: "2-digit",
          minute: "2-digit",
        });
        return `<div style="background-color: #333; display: flex; gap: 5px; color: #fff; padding: 5px; border-radius: 5px;">
                  <span>$${price.toFixed(2)}</span>
                  <span>|</span>
                  <span>${time}</span>
                </div>`;
      },
    },
    grid: { show: false },
    markers: {
      size: 0,
      colors: ["#FF4500"],
      strokeWidth: 2,
      hover: { size: 7 },
    },
  };

  const series: ApexAxisChartSeries = [
    {
      name: "Price",
      data: validatedData,
    },
  ];

  return (
    <div className="line-chart">
      <Chart options={options} series={series} type="line" width={width} height={height} />
    </div>
  );
};

export default LineChart;
