import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Typography,
  Slider,
  Checkbox,
  Button,
  Divider,
  Grid,
  Link,
  IconButton,
  CircularProgress,
} from "@mui/material";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
interface LoanDetailsProps {
  setloaninfo: () => void;
  setpayment: () => void;
  loanAmountData: any;
  loanElogibilityData: any;
  setOnBoosterLoan: any;
  setRecalculate: any;
  setLoanAmount: any;
  setLoanEligibilityData: any;
  onRecalculate: any;
  stockQty: any;
  estimatedAmount: any;
  boosterLoading: any;
  recalculate: any;
  onBuyNow: any;
  acceptCondition: boolean;
  setacceptCondition: any;
  selectedPaymentMethod: any;
}

const LoanDetails: React.FC<LoanDetailsProps> = ({
  loanAmountData,
  loanElogibilityData,
  boosterLoading,
  setLoanEligibilityData,
  onRecalculate,
  setOnBoosterLoan,
  setRecalculate,
  stockQty,
  estimatedAmount,
  setLoanAmount,
  setloaninfo,
  recalculate,
  setpayment,
  onBuyNow,
  acceptCondition,
  setacceptCondition,
  selectedPaymentMethod
}) => {

  // const [loanAmount, setLoanAmount] = React.useState<number>();
  // const [recalculate, setRecalculate] = React.useState<boolean>(false);
  const totalEstimatedAmount = Number(estimatedAmount) + Number(loanAmountData?.totalAmount)

  const handleSliderChange = async (
    event: Event,
    newValue: number | number[]
  ) => {
    await setRecalculate(true);
    setLoanAmount(newValue as number);
  };



  const [timeLeft, setTimeLeft] = useState("");

  useEffect(() => {
    let timerInterval;

    const updateCountdown = () => {
      const currentTime = Date.now();
      const timeDifference = loanElogibilityData?.expiryTime - currentTime;

      if (timeDifference > 0) {
        const minutes = Math.floor(timeDifference / (1000 * 60));
        const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

        // Format time as MM:SS
        const formattedTime = `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
        setTimeLeft(formattedTime);
      } else {
        setTimeLeft("00:00"); // Timer has expired
        setOnBoosterLoan(false)
        setLoanEligibilityData()
        clearInterval(timerInterval); // Clear interval safely
      }
    };


    if (!boosterLoading) {
      updateCountdown();
    }

    // Update the timer every second
    timerInterval = setInterval(updateCountdown, 1000);

    return () => clearInterval(timerInterval);
  }, [loanElogibilityData?.expiryTime]);

  return (
    <>
      {
        boosterLoading ? (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="100%"
            width="100%"
          >
            <CircularProgress sx={{ color: "#f2674a" }} />
          </Box>
        ) :


          !recalculate ? (
            <Box sx={{ color: "white", borderRadius: 2, width: "100%" }}>
              <Typography className="text-secondary" variant="body1">
                Your Loan Amount
              </Typography>
              <Grid container alignItems="center" justifyContent="space-between">
                <Typography variant="h5">${loanAmountData?.loanAmount?.toFixed(2)}</Typography>
                <Box>
                  <Typography sx={{ color: "#f2674a" }} variant="h5">
                    {timeLeft}
                  </Typography>
                  <Typography className="text-secondary" variant="body2">
                    Remaining
                  </Typography>
                </Box>
              </Grid>

              <Slider
                value={loanAmountData?.loanAmount}
                onChange={handleSliderChange}
                min={loanElogibilityData?.minimumAmount}
                max={loanElogibilityData?.loanAmount}
                marks={loanElogibilityData?.sliderValues.map((step) => ({ value: step}))}
                step={null}
                sx={{
                  color: "#f2674a",
                  mt: 2,
                  "& .MuiSlider-thumb": {
                    backgroundColor: "#f2674a",
                  },
                }}
              />
              <Grid container justifyContent="space-between">
                <Typography variant="body2">${loanElogibilityData?.minimumAmount}</Typography>
                <Typography variant="body2">${loanAmountData?.loanAmount}</Typography>
              </Grid>

              <Box
                mt={2}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="body1" color="gray">
                  Interest
                </Typography>
                <Typography variant="body1">{loanAmountData?.interestRate}%</Typography>
              </Box>
              <Box
                mt={1}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="body1" color="gray">
                  Qty. (Whole)
                </Typography>
                <Typography variant="body1">{stockQty}</Typography>
              </Box>
              <Box
                mt={1}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="body1" color="gray">
                  Interest Amount
                </Typography>
                <Typography variant="body1">
                  ${loanAmountData?.interest.toFixed(2)}
                </Typography>
              </Box>
              <Box
                mt={1}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="body1" color="gray">
                  Total Loan Amount with Interest
                </Typography>
                <Typography variant="body1">
                  ${loanAmountData?.totalAmount?.toFixed(2)}
                </Typography>
              </Box>

              <IconButton onClick={setpayment} sx={{ width: "100%" }}>
                <Box
                  width="100%"
                  display="flex"
                  mt={2}
                  alignItems="center"
                  p={1}
                  sx={{ background: "linear-gradient(45deg, #4b2c20, #5e4028)" }}
                >
                  <CreditCardIcon
                    fontSize="small"
                    sx={{ color: "#ffbf00", mr: 1 }}
                  />
                  <Typography variant="body2" className="text-white">
                    Payment Method
                  </Typography>
                  <Box flexGrow={1} />
                  <AccountBalanceIcon sx={{ color: "white" }} />
                  <Typography variant="body2" sx={{ ml: 1 }} className="text-white">
                     {selectedPaymentMethod?.payment ? selectedPaymentMethod?.payment?.creditCard?.cardNumber : selectedPaymentMethod?.customerPaymentProfileId} &gt;
                  </Typography>
                </Box>
              </IconButton>

              <Box mt={2}>
                <Checkbox
                  value={acceptCondition}
                  onChange={(e) => { setacceptCondition(e.target.checked) }}
                  sx={{ color: "white", borderRadius: "50%", p: 0, mr: 1 }}
                />
                <Typography variant="body2" component="span">
                  I accept booster loan{" "}
                  <Link href="#" underline="hover" color="primary">
                    T&C
                  </Link>
                </Typography>
              </Box>
              <Divider sx={{ my: 2, bgcolor: "gray" }} />

              <Box display="flex" justifyContent="space-between">
                <Typography variant="body1" className="text-secondary">
                  Total Qty.
                </Typography>
                <Typography variant="body1" className="text-secondary">
                  {Number(stockQty) * 2}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="space-between" mt={1}>
                <Typography variant="body1" className="text-secondary">
                  Total Estimated Amount
                </Typography>
                <Typography variant="body1" className="text-secondary">
                  ${totalEstimatedAmount?.toFixed(2)}
                </Typography>
              </Box>

              <Divider sx={{ my: 2, bgcolor: "gray" }} />
              <Box my={2} display="flex" justifyContent="space-between">
                <Button
                  variant="outlined"
                  onClick={setloaninfo}
                  sx={{
                    color: "#4edf6d",
                    borderColor: "#4edf6d",
                    width: "48%",
                    borderRadius: "20px",
                    py: "10px",
                  }}
                >
                  View Details
                </Button>
                <Button
                  onClick={()=>{onBuyNow("Buy")}}
                  variant="contained"
                  className="btn-success"
                  sx={{
                    width: "48%",
                    borderRadius: "20px",
                    fontWeight: 600,
                    color: "black",
                  }}
                >
                  Buy Now
                </Button>
              </Box>
            </Box>
          ) : (
            <Box
              padding={10}
              width="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
                gap="20px"
              >
                <svg
                  width="64"
                  height="64"
                  viewBox="0 0 64 64"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_768_3851)">
                    <circle
                      cx="32"
                      cy="32"
                      r="32"
                      fill="url(#paint0_linear_768_3851)"
                    />
                    <path
                      d="M20 52.4443L28.8219 40.1179H21.3293L29.6677 28.5169H20.4835L32.9304 12.4443H43.4592L34.5016 22.4745L43.1333 22.2329L32.4115 35.2843H40.9064L20 52.4443Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <linearGradient
                      id="paint0_linear_768_3851"
                      x1="32"
                      y1="0"
                      x2="20.3902"
                      y2="67.5067"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#EEA415" />
                      <stop offset="1" stopColor="#FFD25E" />
                    </linearGradient>
                    <clipPath id="clip0_768_3851">
                      <rect width="64" height="64" fill="white" />
                    </clipPath>
                  </defs>
                </svg>

                <Typography variant="h6" className="text-white text-center">
                  Looks like you have changed your Order. Re-Calculate to get a new
                  Offer.
                </Typography>
                <Button
                  variant="contained"
                  onClick={onRecalculate}
                  className="text-white fw-700 fs-18 btn mb-10"
                  sx={{
                    // width: "70%",
                    bgcolor: "#f2674a !important",
                    padding: "10px !important",
                  }}
                >
                  Re-calculate
                </Button>
              </Box>
            </Box>
          )}
    </>
  );
};

export default LoanDetails;
