import React from "react";
import { Box, Button, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { AddCircleOutline } from "@mui/icons-material";

export default function Watchlistcard() {
  return (
    <Box
    className='watch-card'
      sx={{
        width:'100%',
        display: "flex",
        position:"relative",
        overflow:'hidden',
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: "#4A7AF21A", // Dark background color
        borderRadius: "22px",
        padding: "25px 50px",
        boxShadow: "0 4px 12px rgba(0, 0, 0, 0.2)", // Optional shadow
        color: "white", // Text color
      }}
    >
        

      {/* Text Section */}
      <Typography
        variant="h6"
        sx={{
          fontWeight: 500,
          fontSize: "24px",
          lineHeight: 1.5,
          width:'55%',
          zIndex:2,
        }}
      >
       Create a personalized watchlist to easily
       track your favourite content.
      </Typography>

      {/* Button */}
      <Button
        variant="contained"
        color="primary"
        endIcon={<AddCircleOutline sx={{height:'24px', width:'24px'}}/>}
        sx={{
          textTransform: "none", // Disable uppercase text
          padding: "13px 38px ",
          borderRadius: "50px",
          fontWeight: "bold",
          fontSize:'16px'
        }}
      >
        Create
      </Button>
    </Box>
  );
}
