import React, { useEffect, useState } from 'react';
import { Box, Typography, Divider, Grid, List, ListItem, ListItemText, CircularProgress } from '@mui/material';
import { useDispatch } from 'react-redux';
import { endLoading, startLoading } from '../../../Redux/loadingSlice';
import { UrlConstant } from '../../../commom/UrlConstant';
import Axios from '../../../config/axios';
import moment from 'moment';

const Loaninfo: React.FC<any> = ({ stockDetail, loanAmountData, stockQty }: any) => {
  const dispatch = useDispatch();
  const [loanInfo, setLoanInfo] = useState<any>();
  const [installementsList, setInstallementsList] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false); // Loading state


  const fetchLoanInfo = async () => {
    setLoading(true); // Start loading
    dispatch(startLoading());
    const symbol = stockDetail?.symbol;
    const stockPrice = loanAmountData?.loanAmount;
    try {
      const response = await Axios.get(
        UrlConstant.BoosterLoan.GetLoanDetails.replace("{0}", symbol).replace("{1}", stockPrice)
      );

      setLoanInfo(response.data?.data);
      const installements = response.data?.data?.installements?.map((item) => ({
        date: moment(item.dueDate).format("DD-MMM-YYYY"),
        amount: item.amount,
      }));
      setInstallementsList(installements);
    } catch (error: any) {
      console.error("ERRR : ", error);
    } finally {
      dispatch(endLoading());
      setLoading(false); // End loading
    }
  };

  useEffect(() => {
    fetchLoanInfo();
  }, []);

  return (
    <Box sx={{ margin: '0 auto', color: '#fff' }}>
      {/* Loading Spinner */}
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="300px">
          <CircularProgress sx={{ color: '#f2674a' }} />
        </Box>
      ) : (
        <>
          {/* Price Section */}
          {/* <Grid container sx={{ textAlign: 'center', justifyContent: 'space-between', padding: 3 }}>
            <Grid item>
              <Typography variant="body1" className="text-secondary">Ask Price</Typography>
              <Typography variant="h5">$18.90</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1" className="text-secondary">Bid Price</Typography>
              <Typography variant="h5">$18.90</Typography>
            </Grid>
          </Grid> */}

          <Divider sx={{ borderColor: '#FFFFFF70', marginY: 1 }} />

          {/* Loan Information */}
          <Box sx={{ paddingY: 1, padding: 3 }}>
            <Box display="flex" justifyContent="space-between" marginBottom={1}>
              <Typography variant="body1" className="text-secondary">Loan Amount</Typography>
              <Typography variant="body1">${loanAmountData?.loanAmount} </Typography>
            </Box>
            <Box display="flex" justifyContent="space-between" marginBottom={1}>
              <Typography variant="body1" className="text-secondary">Qty (Whole)</Typography>
              <Typography variant="body1">{stockQty}</Typography>
            </Box>
            <Box display="flex" justifyContent="space-between" marginBottom={1}>
              <Typography variant="body1" className="text-secondary">Interest</Typography>
              <Typography variant="body1">{loanAmountData?.interestRate}</Typography>
            </Box>
            <Box display="flex" justifyContent="space-between" marginBottom={1}>
              <Typography variant="body1" className="text-secondary">Interest Amount</Typography>
              <Typography variant="body1">${loanAmountData?.interest}</Typography>
            </Box>
            <Box display="flex" justifyContent="space-between" marginBottom={1}>
              <Typography variant="body1" className="text-secondary">Fees</Typography>
              <Typography variant="body1">${loanAmountData?.transactionFee}</Typography>
            </Box>
            <Box display="flex" justifyContent="space-between" marginBottom={1} sx={{ fontWeight: 'bold' }}>
              <Typography variant="body1" className="text-secondary">Total Amount</Typography>
              <Typography variant="body1">${loanAmountData?.totalAmount}</Typography>
            </Box>
          </Box>

          <Divider sx={{ borderColor: '#FFFFFF70', marginY: 1 }} />

          {/* Loan Installments */}
          <Box padding={3}>
            <Typography variant="h6" className="text-secondary" gutterBottom>
              Loan Installments
            </Typography>
            <List dense>
              {installementsList.length > 0 ? (
                installementsList.map((item, index) => (
                  <ListItem key={index} sx={{ paddingY: 0.5 }}>
                    <ListItemText
                      primary={`${index + 1}. ${item.date}`}
                      primaryTypographyProps={{ variant: 'body1' }}
                    />
                    <Typography variant="body1">${item.amount}</Typography>
                  </ListItem>
                ))
              ) : (
                <Typography variant="body1" color="textSecondary">
                  No installments available.
                </Typography>
              )}
            </List>
          </Box>
        </>
      )}
    </Box>
  );
};

export default Loaninfo;
