import React, { useEffect, useState } from 'react';
import Axios from '../../../config/axios';
import { Grid, CircularProgress } from '@mui/material';
import Discoverystock from '../Cards/Discoverystock';
import { UrlConstant } from '../../../commom/UrlConstant';

type Preference = {
  _id: string;
  preferenceName: string;
  preferenceType: 'BUY' | 'SELL';
  stockExchangeName: string;
  level: string;
  isActive: boolean;
  type: 'STOCK' | 'ETF';
};

function Discovery() {
  const [preferences, setPreferences] = useState<Preference[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchPreferences = async () => {
    try {
      const response = await Axios.get(UrlConstant.Discovery.preferences);
      setPreferences(response.data.data);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Something went wrong!');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPreferences();
  }, []);

  const handlePreferenceUpdate = (id: string, isActive: boolean) => {
    setPreferences((prev) =>
      prev.map((pref) =>
        pref._id === id ? { ...pref, isActive } : pref
      )
    );
  };

  if (loading) {
    return <div style={{ color: '#fff', textAlign: 'center' }}>Loading...</div>;
  }

  if (error) {
    return <div style={{ color: 'red', textAlign: 'center' }}>Error: {error}</div>;
  }

  const stockPreferences = preferences.filter((item) => item.type === 'STOCK');
  const etfPreferences = preferences.filter((item) => item.type === 'ETF');

  return (
    <Grid
      container
      style={{ padding: '24px', justifyContent: 'space-between' }}
      className="bg-dark"
      minHeight="calc(100vh - 70px)"
    >
      <Grid width="49%" item>
        <Discoverystock
          heading="Stock"
          preferences={stockPreferences}
          onPreferenceUpdate={handlePreferenceUpdate}
          fetchPreferences={fetchPreferences}
        />
      </Grid>
      <Grid width="49%" item>
        <Discoverystock
          heading="ETF"
          preferences={etfPreferences}
          onPreferenceUpdate={handlePreferenceUpdate}
          fetchPreferences={fetchPreferences}
        />
      </Grid>
    </Grid>
  );
}

export default Discovery;
