import React from "react";
import { Box, Typography, Button } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import img from '../../../commom/Assets/images/image.png'

const BoosterLoanCard: React.FC = () => {
  return (
    <Box
    className='booster-card'
      sx={{
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: "#4A7AF21A", // Dark background
        borderRadius: "16px",
        padding: "26px 40px",
      
        color: "white",
        overflow: "hidden", // Ensures the gradient doesn't spill out
        boxShadow: "0 4px 12px rgba(0, 0, 0, 0.2)", // Optional shadow
      }}
    >
      {/* Left Content (Text and Illustration) */}
      <Box sx={{ display: "flex", alignItems: "center", gap: '30px' }}>
        {/* Illustration */}
        <Box
          component="img"
          src={img}// Replace with the actual image URL
          alt="Illustration"
          sx={{ height: "120px", width: "165px" }}
        />

        {/* Text Content */}
        <Box>
          <Typography
            variant="body1"
            fontSize='24px'
            sx={{ fontWeight: "bold", marginBottom: "8px" }}
          >
            Activate Booster Loan
          </Typography>
          <Typography
            variant="body1"
          >
            Invest Now, Pay Later with our Booster Loans! <br />
            Minimum $100 Loan Amount.
          </Typography>
        </Box>
      </Box>

      {/* Right Content (Button) */}
      <Button
        variant="contained"
        color="primary"
        endIcon={<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.6802 14.62L14.2402 12.06L11.6802 9.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M4 12.0601H14.17" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12 4C16.42 4 20 7 20 12C20 17 16.42 20 12 20" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            }
        sx={{
          textTransform: "none", // Disable uppercase text
          padding: "13px 38px ",
          borderRadius: "50px",
          fontWeight: "bold",
          fontSize:'16px',
          zIndex:2,
        }}
      >
        Active
      </Button>

      {/* Gradient Background Arc */}
     
    </Box>
  );
};

export default BoosterLoanCard;
