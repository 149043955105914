import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Select,
  MenuItem,
  Box,
} from "@mui/material";
import { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Tooltip } from "@mui/material";

const FormDynamicElement = ({
  elementDetail,
  onChange,
  currentStepData,
  selectedValues,
  setSelectedValues,
  validationErrors,
}) => {
  const [childElements, setChildElements] = useState([]);
  const [showChildElements, setShowChildElements] = useState(false);

  const renderChildElements = () => {
    return currentStepData.filter((element, index) => {
      if (element.parent_question_id) {
        const parentSelectedValue = selectedValues[element.parent_question_id];
        const parentOptionSelected = selectedValues[element.parent_option_id];

        if (
          (parentSelectedValue && parentSelectedValue !== "") ||
          parentOptionSelected
        ) {
          return (
            <FormDynamicElement
              key={index}
              elementDetail={element}
              onChange={onChange}
              currentStepData={currentStepData}
              selectedValues={selectedValues}
              setSelectedValues={setSelectedValues}
              validationErrors={validationErrors}
            />
          );
        }
      }
      return false;
    });
  };

  useEffect(() => {
    if (elementDetail.options) {
      const selectedValue = selectedValues[elementDetail.id];
      if (selectedValue !== undefined) {
        renderChildElements();
      }
    }
  }, [selectedValues, elementDetail.id, currentStepData]);

  useEffect(() => {
    if (
      currentStepData[0] &&
      currentStepData[0].section_type === "beneficiaries_users"
    ) {
      if (selectedValues[0] === "false" || selectedValues[0] === false) {
        setChildElements([]);
        setShowChildElements(false);
      }
    } else if (elementDetail.parent_question_id) {
      const parentElement = currentStepData.find(
        (el) => el.id === elementDetail.parent_question_id
      );
      if (parentElement) {
        const parentSelectedValue = selectedValues[parentElement.id];
        const parentOptionSelected = selectedValues[parentElement.option_id];

        if (
          parentSelectedValue === "false" ||
          parentSelectedValue === false ||
          parentOptionSelected
        ) {
          setShowChildElements(true);
        } else {
          setChildElements([]);
          setShowChildElements(false);
        }
      }
    }
  }, [selectedValues, elementDetail.parent_question_id, currentStepData]);

  const handleChange = (event) => {
    const { name, value } = event.target;

    setSelectedValues((prev) => ({ ...prev, [name]: value }));
    onChange({ ...elementDetail, value });
  };

  const renderElement = (elDetail) => {
    const errorMessage = validationErrors[elDetail.id];

    switch (elDetail.type) {
      case "text":
        return (
          <FormControl
            style={{
              width: "30vw",
              display: elDetail.system_identifire.includes("address.state")
                ? "none"
                : "display",
            }}
            margin="normal"
          >
            <label className="text-secondary" style={{ marginBottom: "10px" }}>
              <span>
                {elDetail.title}
                {elDetail.isOptional === undefined && (
                  <sup>
                    <i
                      className="fa-solid fa-star-of-life"
                      style={{ color: "#fa0000", fontSize: "0.5rem" }}
                    ></i>
                  </sup>
                )}
                {elDetail.hint && (
                  <Tooltip
                    title={elDetail.hint}
                    arrow
                    sx={{ backgroundColor: "purple" }}
                  >
                    <i
                      className="fa-solid fa-info-circle"
                      style={{ color: "#c79826", fontSize: "1rem" }}
                    ></i>
                  </Tooltip>
                )}
              </span>
            </label>

            <TextField
              name={elDetail.id}
              value={elDetail.value || ""}
              variant="outlined"
              onChange={handleChange}
            />
            {errorMessage && <div style={{ color: "red" }}>{errorMessage}</div>}
          </FormControl>
        );

      case "radio":
        return (
          <Box width="90vw">
            <label className="text-secondary">
              <span>
                {elDetail.title}
                {elDetail.isOptional === undefined && (
                  <sup>
                    <i
                      className="fa-solid fa-star-of-life"
                      style={{ color: "#fa0000", fontSize: "0.5rem" }}
                    ></i>
                  </sup>
                )}
                {elDetail.hint && (
                  <Tooltip
                    title={elDetail.hint}
                    arrow
                    sx={{ backgroundColor: "purple" }}
                  >
                    <i
                      className="fa-solid fa-info-circle"
                      style={{ color: "#c79826", fontSize: "1rem" }}
                    ></i>
                  </Tooltip>
                )}
              </span>
            </label>
            <RadioGroup
              name={elDetail.id}
              value={elDetail.value || ""}
              onChange={handleChange}
            >
              {elDetail.options.map((option) => (
                <FormControlLabel
                  key={option.id}
                  value={option.answer}
                  control={<Radio />}
                  label={option.title}
                  className="text-secondary"
                />
              ))}
            </RadioGroup>

            {errorMessage && <div style={{ color: "red" }}>{errorMessage}</div>}
          </Box>
        );

      case "select":
        return (
          <div style={{ width: "30vw" }}>
            <label className="text-secondary">
              <span>
                {elDetail.title}
                {elDetail.isOptional === undefined && (
                  <sup>
                    <i
                      className="fa-solid fa-star-of-life"
                      style={{ color: "#fa0000", fontSize: "0.5rem" }}
                    ></i>
                  </sup>
                )}
                {elDetail.hint && (
                  <Tooltip
                    title={elDetail.hint}
                    arrow
                    sx={{ backgroundColor: "purple" }}
                  >
                    <i
                      className="fa-solid fa-info-circle"
                      style={{ color: "#c79826", fontSize: "1rem" }}
                    ></i>
                  </Tooltip>
                )}
              </span>
            </label>
            <FormControl fullWidth margin="normal">
              <Select
                name={elDetail.id}
                value={elDetail.value || ""}
                onChange={handleChange}
                variant="outlined"
                sx={{
                  p: "13px",
                  mb: 0,
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: errorMessage
                        ? "#f44336 !important"
                        : "#a3a3a3 !important",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: errorMessage
                        ? "#f44336 !important"
                        : "#f2674a !important",
                    },
                  },
                }}
              >
                {elDetail.options.map((option) => (
                  <MenuItem key={option.id} value={option.answer}>
                    {option.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {errorMessage && <div style={{ color: "red" }}>{errorMessage}</div>}
          </div>
        );

      case "checkbox":
        return (
          <div style={{ width: "90vw" }}>
            <label style={{ color: "white" }}>
              <span>
                {elDetail.button_text}
                {elDetail.isOptional === undefined && (
                  <sup>
                    <i
                      className="fa-solid fa-star-of-life"
                      style={{ color: "#fa0000", fontSize: "0.5rem" }}
                    ></i>
                  </sup>
                )}
                {elDetail.hint && (
                  <Tooltip
                    title={elDetail.hint}
                    arrow
                    sx={{ backgroundColor: "purple" }}
                  >
                    <i
                      className="fa-solid fa-info-circle"
                      style={{ color: "#c79826", fontSize: "1rem" }}
                    ></i>
                  </Tooltip>
                )}
              </span>
            </label>
            <input
              type="checkbox"
              style={{ height: "20px", width: "20px" }}
              checked={elDetail.value || false}
              onChange={(e) => {
                handleChange({
                  target: {
                    name: elDetail.id,
                    value: e.target.checked,
                  },
                });
              }}
            />
            {errorMessage && <div style={{ color: "red" }}>{errorMessage}</div>}
          </div>
        );

      case "date":
        const currentDate = new Date();
        const minDate = new Date(currentDate);
        minDate.setFullYear(currentDate.getFullYear() - 18);
        return (
          <div style={{ width: "31vw" }}>
            <label className="text-secondary" style={{ marginBottom: "2px" }}>
              <span>
                {elDetail.title}
                {elDetail.isOptional === undefined && (
                  <sup>
                    <i
                      className="fa-solid fa-star-of-life"
                      style={{ color: "#fa0000", fontSize: "0.5rem" }}
                    ></i>
                  </sup>
                )}
                {elDetail.hint && (
                  <Tooltip
                    title={elDetail.hint}
                    arrow
                    sx={{ backgroundColor: "purple" }}
                  >
                    <i
                      className="fa-solid fa-info-circle"
                      style={{ color: "#c79826", fontSize: "1rem" }}
                    ></i>
                  </Tooltip>
                )}
              </span>
            </label>

            <DatePicker
            
              showIcon
              selected={elDetail.value}
              
              maxDate={
                elementDetail.system_identifire === "dateOfBirth"
                  ? minDate
                  : undefined
              }
              onChange={(date) =>
                handleChange({ target: { name: elDetail.id, value: date } })
              }
            />
            {errorMessage && <div style={{ color: "red" }}>{errorMessage}</div>}
          </div>
        );
      case "link":
        return (
          <div dangerouslySetInnerHTML={{ __html: elementDetail.title }} />
        );

      default:
        return (
          <div style={{ color: "red" }}>
            Unknown Element Type: {elDetail.type}
          </div>
        );
    }
  };

  return (
    <div>
      {renderElement(elementDetail)}

      {/* Conditionally render child elements if applicable */}
      {showChildElements &&
        childElements.length > 0 &&
        childElements.map((childElement, index) => (
          <FormDynamicElement
            key={index + 100}
            elementDetail={childElement}
            onChange={onChange}
            currentStepData={currentStepData}
            selectedValues={selectedValues}
            setSelectedValues={setSelectedValues}
            validationErrors={validationErrors}
          />
        ))}
    </div>
  );
};

export default FormDynamicElement;


// { "section_type": "contact_informations", 
//   "data": 
//   [{ 
//     "question_id": 13923, 
//     "data": "201-232-3423", 
//     "system_identifire": "phoneNumber", 
//     "answer": "201-232-3423" }, 
//     { "question_id": 13915, 
//       "data": "USA", 
//       "system_identifire": "address.country", 
//       "answer": "USA" }, 
//       { "question_id": 13917, 
//         "data": "530 Lytton Ave", 
//         "system_identifire": "address.address1", 
//         "answer": "530 Lytton Ave" }, 
//         { "question_id": 15975, 
//           "data": "", 
//           "system_identifire": "address.address2", 
//           "answer": "" }, 
//           { "question_id": 13919, 
//             "data": "Palo Alto", 
//             "system_identifire": "address.city", 
//             "answer": "Palo Alto" }, 
//             { "question_id": 13921, 
//               "data": "CA", 
//               "system_identifire": "address.state", 
//               "answer": "CA" }, 
//               { "question_id": 13922, 
//                 "data": "94301", 
//                 "system_identifire": "address.postalanswer", 
//                 "answer": "94301" }, 
//                 { "question_id": 13925, 
//                   "data": "14084", 
//                   "system_identifire": "differentMailingAddress", 
//                   "answer": "true" }] }