import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const PublicRoute = ({ element: Element }) => {
  const isAuthenticated = useSelector(
    (state: any) => state.auth.isAuthenticated
  );
  return !isAuthenticated ? (
    <Element />
  ) : (
    <Navigate to="/maindashboard/dashboard" />
  );
};

export default PublicRoute;
