import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider, CssBaseline } from "@mui/material"; // Import MUI's ThemeProvider and CssBaseline
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { createTheme } from "@mui/material/styles";

// Create a custom MUI theme
const theme = createTheme({
  typography: {
    fontFamily: "'Avenir', sans-serif",
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Avenir';
          src: url('/fonts/Avenir/Avenir-Light.ttf') format('truetype');
          font-weight: 300;
          font-style: normal;
        }
        @font-face {
          font-family: 'Avenir';
          src: url('/fonts/Avenir/Avenir-Black.ttf') format('truetype');
          font-weight: 700;
          font-style: normal;
        }
        body {
          font-family: 'Avenir', sans-serif !important;
        }
      `,
    },
  },
});

// Create the root for React 18
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

// Render the app inside ThemeProvider and BrowserRouter
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ThemeProvider>
  </React.StrictMode>
);

// Measure performance
reportWebVitals();
