import { Box, Button, Container, TextField, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { useFormik } from "formik";
import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { UrlConstant } from "../../commom/UrlConstant";
import Axios from "../../config/axios";
import { login } from "../../Redux/authSlice";
import "../../style/style.css";
import LeftSide from "../common/Layout/LeftSide";
import Swal from "sweetalert2";

interface FormValues {
  input1_1: string;
  input1_2: string;
  input1_3: string;
  input1_4: string;
  input2_1: string;
  input2_2: string;
  input2_3: string;
  input2_4: string;
}

const initialValues: FormValues = {
  input1_1: "",
  input1_2: "",
  input1_3: "",
  input1_4: "",
  input2_1: "",
  input2_2: "",
  input2_3: "",
  input2_4: "",
};

const validationSchema = Yup.object().shape({
  input1_1: Yup.string()
    .length(1, "Only 1 character is allowed")
    .required("Required"),
  input1_2: Yup.string()
    .length(1, "Only 1 character is allowed")
    .required("Required"),
  input1_3: Yup.string()
    .length(1, "Only 1 character is allowed")
    .required("Required"),
  input1_4: Yup.string()
    .length(1, "Only 1 character is allowed")
    .required("Required"),
  input2_1: Yup.string()
    .length(1, "Only 1 character is allowed")
    .required("Required"),
  input2_2: Yup.string()
    .length(1, "Only 1 character is allowed")
    .required("Required"),
  input2_3: Yup.string()
    .length(1, "Only 1 character is allowed")
    .required("Required"),
  input2_4: Yup.string()
    .length(1, "Only 1 character is allowed")
    .required("Required"),
});

function Createpin() {
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  // const [submitForm, setSubmitForm] = useState<boolean>(false);
  const [isLogin, setIsLogin] = useState<boolean>(false);
  const [showPin] = useState<boolean>(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const user = location.state;
  React.useEffect(() => {
    if (user === undefined || user === null) {
      navigate("/login");
    }
  }, [user, navigate]);
  const onSubmit = (values: FormValues) => {
    const isMatch = checkpin(values);
    if (isMatch === true) {
      setPin(values);
    } else {
      Swal.fire("PIN not matched");
    }
  };
  const checkpin = (values) => {
    const creatpin =
      values.input1_1 + values.input1_2 + values.input1_3 + values.input1_4;
    const confirmpin =
      values.input2_1 + values.input2_2 + values.input2_3 + values.input2_4;
    if (creatpin === confirmpin) {
      return true;
    } else {
      return false;
    }
  };
  const handleKeyUp = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    if (e.key === "Enter") {
      formik.handleSubmit(); // Set the field as touched for validation
    } else if (
      // e.currentTarget.value.length === 1 &&
      inputRefs.current[index + 1]
    ) {
      inputRefs.current[index + 1]?.focus();
    }
  };
  const formik = useFormik<FormValues>({
    initialValues,
    onSubmit,
    validationSchema,
  });

  const setPin = async (values: any) => {
    const payLoad = {
      pin:
        values.input1_1 + values.input1_2 + values.input1_3 + values.input1_4,
    };
    try {
      const response = await Axios.post(
        UrlConstant.Auth.CreatePin,
        payLoad
        //   , {
        //   headers: {
        //     Authorization: user.userToken,
        //   },
        // }
      );

      const resData = response.data;
      if (resData.result === true) {
        dispatch(login(user));
        navigate("/maindashboard", { state: user });
      }
    } catch (error: any) {
      console.error("ERRR : ", error);
    }
  };

  return (
    <Container
      maxWidth={false}
      className="container"
      sx={{ maxWidth: "100%", margin: "0px" }}
    >
      <LeftSide />
      <RightSection className="RightSection bg-color">
        <Typography width="100%">
          <Typography className="Typography fw-700 mb-10 fs-24 heading">
            Create Pin{" "}
          </Typography>
          <Typography
            className="Typography fs-18 mb-50 text-secondary"
            variant="body1"
            sx={{ mb: { xs: 10, sm: 10 } }}
          >
            Set up a new PIN to secure your account.{" "}
          </Typography>
        </Typography>

        <form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
          <Typography className="heading mb-20 text-secondary">
            <span>
              Create Pin{" "}
              <sup>
                <i
                  className="fa-solid fa-star-of-life"
                  style={{ color: "#fa0000", fontSize: "0.5rem" }}
                ></i>
              </sup>
            </span>
          </Typography>
          <Box
            mt={2}
            mb={0}
            display="flex"
            justifyContent="space-between"
            width="100%"
          >
            {[1, 2, 3, 4].map((i) => {
              const inputName = `input1_${i}` as keyof FormValues;

              return (
                <TextField
                  key={i}
                  type={showPin ? "text" : "password"}
                  variant="outlined"
                  className="text-secondary "
                  inputRef={(el: any) => (inputRefs.current[i] = el)}
                  onKeyUp={(e: any) => {
                    handleKeyUp(e, i);
                  }}
                  sx={{
                    ".css-j882ge-MuiInputBase-root-MuiOutlinedInput-root": {
                      width: "105px",
                      fontSize: "20px",
                    },
                    "& .MuiOutlinedInput-input": {
                      textAlign: "center",
                    },
                    " & .MuiOutlinedInput-notchedOutline": {
                      borderRadius: "123px",
                      borderColor: `${
                        formik.errors[inputName] && isLogin
                          ? "red !important"
                          : " "
                      } `,
                    },
                  }}
                  inputProps={{
                    maxLength: 1,
                  }}
                  size="medium"
                  InputLabelProps={{
                    shrink: false,
                    classes: {
                      focused: "focused-label",
                    },
                  }}
                  {...formik.getFieldProps(inputName)}
                />
              );
            })}
          </Box>
          <Typography className="heading mb-20 text-secondary " mt={5}>
            <span>
              Confirm Pin{" "}
              <sup>
                <i
                  className="fa-solid fa-star-of-life"
                  style={{ color: "#fa0000", fontSize: "0.5rem" }}
                ></i>
              </sup>
            </span>
          </Typography>
          <div className="otpgrp" style={{ marginBottom: "50px" }}>
            {[1, 2, 3, 4].map((i) => {
              const inputName = `input2_${i}` as keyof FormValues;

              return (
                <TextField
                  key={i}
                  type={showPin ? "text" : "password"}
                  variant="outlined"
                  className="text-secondary "
                  inputRef={(el: any) => (inputRefs.current[i + 4] = el)}
                  onKeyUp={(e: any) => {
                    handleKeyUp(e, i + 4);
                  }}
                  sx={{
                    ".css-j882ge-MuiInputBase-root-MuiOutlinedInput-root": {
                      width: "105px",
                      fontSize: "20px",
                    },
                    "& .MuiOutlinedInput-input": {
                      textAlign: "center",
                    },
                    " & .MuiOutlinedInput-notchedOutline": {
                      borderRadius: "123px",
                      borderColor: `${
                        formik.errors[inputName] && isLogin
                          ? "red !important"
                          : " "
                      } `,
                    },
                  }}
                  inputProps={{
                    maxLength: 1,
                  }}
                  size="medium"
                  InputLabelProps={{
                    shrink: false,
                    classes: {
                      focused: "focused-label",
                    },
                  }}
                  {...formik.getFieldProps(inputName)}
                />
              );
            })}
          </div>

          <Button
            //  sx={{marginTop:'20px'}}
            variant="contained"
            color="primary"
            fullWidth
            className="btn-primary fw-700 fs-18  btn mb-10"
            endIcon={
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15 22C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22H15Z"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.75 12L10.58 14.83L16.25 9.17004"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            }
            type="submit"
            onClick={() => setIsLogin(true)}
          >
            Confirm
          </Button>
        </form>

        {/* <Typography sx={{ mt: 2 }} className="text-secondary"> */}
        <Link to="/" className="link">
          Skip
        </Link>
        {/* </Typography> */}
      </RightSection>
    </Container>
  );
}

export default Createpin;

const RightSection = styled(Box)(({ theme }) => ({
  width: "50%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "70px 120px",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
  [theme.breakpoints.down("lg")]: {
  },
  [theme.breakpoints.down("sm")]: {
    padding: "40px 40px",
  },
}));
