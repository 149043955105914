export class Constant {
  public static POPULAR = "mostPopular";
  public static DIVIDENT_PAYING = "topDividendPaying";
  public static GROWTH = "topGrowth";
  public static FOLLOWING = "FOLLOWING";

  public static BUY = "buy";
  public static SELL = "sell";

  public static STOCK = "STOCK";
  public static ETF = "ETF";

  public static CONTINUE = "Continue";
}
