import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isOpen: false,
  // You can add more state variables related to your dialog if needed
};

const dialogSlice = createSlice({
  name: "dialog",
  initialState,
  reducers: {
    openDialog: (state) => {
      state.isOpen = true;
    },
    closeDialog: (state) => {
      state.isOpen = false;
    },
  },
});

export const { openDialog, closeDialog } = dialogSlice.actions;

export const selectDialogOpen = (state: any) => state.dialog.isOpen;

export default dialogSlice.reducer;
