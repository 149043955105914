import { CircularProgress } from "@material-ui/core";
import DataTable from "react-data-table-component";
// import "react-data-table-component-extensions/dist/index.css";
import { useSelector } from "react-redux";

const customStyles = {
  headCells: {
    style: {
      fontSize: "13px",
      justifyContent: "left", // Center-align the headers
      whiteSpace: "wrap", // Prevent text from wrapping
      overflow: "hidden",
      color: "white", // Font color for header
      backgroundColor: "#212529", // Background color for header
      borderBottom: "1px solid #ffffff33",
    },
  },
  cells: {
    style: {
      justifyContent: "left", // Center-align the headers
      whiteSpace: "nowrap", // Prevent text from wrapping in cells
      overflow: "hidden",
      textOverflow: "ellipsis",
      color: "white", // Font color for header
      backgroundColor: "#212529", // Background color for header
      border: "none",
    },
  },
  rows: {
    style: {
      border: "none", // Remove borders from rows
    },
  },
};

export function CommonDataTable({
  data,
  totalRows,
  setPage,
  setRowsPerPage,
  columns,
  ...props
}) {
  const { isLoading } = useSelector((state: any) => state.loading);
  return (
    <div style={{ position: "relative" }}>
      <DataTable
        columns={columns}
        data={data}
        // pagination
        // paginationServer
        fixedHeaderScrollHeight="600px"
        // conditionalRowStyles={conditionalRowStyles}
        onChangePage={(value) => {
          setPage(value);
        }}
        responsive={true}
        fixedHeader
        onChangeRowsPerPage={setRowsPerPage}
        paginationTotalRows={totalRows}
        progressPending={isLoading}
        customStyles={customStyles}
        progressComponent={
          <div style={{ width: "100%" }}>
            <CircularProgress size="40px" color="primary" />
          </div>
        }
        {...props}
      />
    </div>
  );
}
