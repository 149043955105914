import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Avatar,
  Typography,
  Box,
  Chip,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';



interface ActiveLoansProps {
  portfolioListData: any;
}

const PortfolioTabel: React.FC<ActiveLoansProps> = ({ portfolioListData }) => {
  const navigation = useNavigate();


  const getLogoUrl = (symbol) => {
    if (!symbol) {
      throw new Error("Symbol is required");
    }
    return `https://logos-api.extend.apexclearing.com/logos/${symbol.toLowerCase().trim()}.png?api_key=4RJeGywzAE&api_secret=isUE1ZzPNi5IzXbs9Qg0S01eaFUcxX09c4U88R9JBiJgJ6WxXHrXz3VtEima`;
  };

  const navigateToDetail = async (data) => {
    //stockdetail
    navigation("/maindashboard/stockdetail", {
      state: {
        symbol: data.symbol,
        icon: data.quote.branding?.logo_url ?? data.quote.branding?.icon_url,
        title: "Portfolio"
      },
    });
  };
  const navigateToInfo = async (data) => {
    //stockdetail
    navigation("/maindashboard/portfolioStockInfo", {
      state: {
        symbol: data.symbol,
        stockInfo: data,
        icon: data.quote.branding?.logo_url ?? data.quote.branding?.icon_url,
        title: "Portfolio"
      },
    });
  };

  return (
    <TableContainer component={Paper} style={{ marginTop: '30px', color: 'white', background: 'transparent', borderRadius: '20px', border: '2px solid #FFFFFF1A' }}>
      <Table>
        <TableHead>
          <TableRow sx={{
            '& th': { borderColor: '#FFFFFF1A' },
          }}>
            <TableCell style={{ fontSize: '12px', color: 'white' }}>Company</TableCell>
            <TableCell style={{ fontSize: '12px', color: 'white', textAlign: 'end' }}>Code</TableCell>
            <TableCell style={{ fontSize: '12px', color: 'white', textAlign: 'end' }}>Price</TableCell>
            <TableCell style={{ fontSize: '12px', color: 'white', textAlign: 'end' }}>Cash</TableCell>
            <TableCell style={{ fontSize: '12px', color: 'white', textAlign: 'end' }}>Qty.</TableCell>
            <TableCell style={{ fontSize: '12px', color: 'white', textAlign: 'center' }}>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {portfolioListData?.map((row, index) => (
            <TableRow key={index} sx={{
              '& td': { borderBottom: 'none !important' },
            }}>
              <TableCell>
                <Box display="flex" alignItems="center">
                  <Avatar
                    src={getLogoUrl(row.symbol)}
                    alt={row.symbol}
                    style={{ marginRight: '8px', height: '36px', width: '36px' }}
                  />
                  <Typography style={{ fontSize: '14px', color: 'white' }}>{row.quote.companyName}</Typography>
                </Box>
              </TableCell>
              <TableCell style={{ fontSize: "16px", color: 'white', textAlign: 'end' }}>{row.symbol}</TableCell>
              <TableCell style={{ fontSize: "16px", color: 'white', textAlign: 'end' }}>
                ${row.quote?.lastPrice} <span style={{ color: row.quote?.change_percent > 0 ? "#4EDF6D" : "red" }}>({row.quote?.change_percent > 0 ? "+" : ""} {row.quote?.change_percent})</span> </TableCell>
              <TableCell style={{ fontSize: "16px", color: 'white', textAlign: 'end' }}>{row.cash}</TableCell>
              <TableCell style={{ fontSize: "16px", color: 'white', textAlign: 'end' }}>{row.quantity?.toFixed(2)}</TableCell>

              <TableCell style={{ textAlign: 'center' }}>
                <Chip
                  label={"Trade"}
                  style={{
                    fontSize: '14px',
                    backgroundColor: "#F2674A",
                    color: "#FFFFFF",
                    padding: '5px 23px',
                    cursor: "pointer"
                  }}
                  onClick={() => { navigateToDetail(row) }}
                />
                <Chip
                  label={"Info"}
                  style={{
                    fontSize: '14px',
                    marginLeft: "5px",
                    backgroundColor: "#4A7AF2",
                    color: "#FFFFFF",
                    padding: '5px 23px',
                    cursor: "pointer"
                  }}
                  onClick={() => { navigateToInfo(row) }}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PortfolioTabel;
