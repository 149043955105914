// import { Avatar, Container,ToggleButton,Typography,Box,FormControl } from "@mui/material";
import { BookmarkBorderOutlined } from "@mui/icons-material";
import { ExpandCircleDownOutlined } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Container,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  CardContent,
  IconButton,
  CircularProgress
} from "@mui/material";
import React, { useState, useRef, useEffect } from "react";
import { Card, Row } from "react-bootstrap";
import { useLocation, useNavigate, Link } from "react-router-dom";
import LineChart from "../charts/Linechart";
import { useDispatch } from "react-redux";
import { endLoading, startLoading } from "../../../Redux/loadingSlice";
import Axios from "../../../config/axios";
import { UrlConstant } from "../../../commom/UrlConstant";
import Swal from "sweetalert2";
import { getCurrentUserId } from "../../../Utils/utils";
import BuySell from "../Cards/BuySell";
import axios from "axios";
import { Grid } from "@material-ui/core";
import moment from "moment";

const BAR_CHART_KEY = "0J7lfEpAefOuHSE6uSHdiW_5N0sThxfa";
const YAHOO_FINANCE_KEY = "W6WQ7T5PIt63txWaMW9yVsDCVVYbUD81i0eERo32";
const BASE_URL = "https://yfapi.net/ws/insights/v1/finance/insights";


function StockDetail() {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const [stockDetail, setStockDetail] = React.useState<any>({});
  const [loading, setLoading] = useState(false);
  const [chartData, setChartData] = useState<any>([]);
  const [tickerData, setTickerData] = useState<any>([]);
  const [tickerDataAll, setTickerAllData] = useState<any>([]);
  const [selection, setSelection] = useState<string>("1 Day");
  const [expanded, setExpanded] = useState(false);
  const scrollRef = useRef<HTMLDivElement>(null);
  console.log(tickerData, "tickerDatatickerData")
  const handleReadMore = () => {
    setExpanded(!expanded);
  };
  const handleScrollLeft = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollLeft += 200;
    }
  };

  const handleselect = (
    event: React.MouseEvent<HTMLElement>,
    newselection: string
  ) => {
    setSelection(newselection);
  };

  const stock = location.state?.symbol;
  const icon = location.state?.icon;
  const title = location.state?.title


  function calculatePriceDetails(tickerData) {
    const lastPrice = parseFloat(tickerData?.Last || "0.00");
    const previousClose = parseFloat(tickerData?.PreviousClose || "0.00");
    const diffAmount = lastPrice - previousClose;
    const percentage = (diffAmount * 100.0) / previousClose;

    return {
      lastPrice,
      previousClose,
      diffAmount,
      percentage
    };
  }

  const getStockDetail = async () => {
    dispatch(startLoading());
    setLoading(true)
    try {
      const response = await Axios.get(
        UrlConstant.Stock.GetStockDetail.replace("{0}", stock)
      );
      const resDataTicker = await Axios.get(
        UrlConstant.Stock.GetTiker.replace("{0}", stock))

      if (resDataTicker.data) {
        setTickerData(calculatePriceDetails(resDataTicker.data?.data[0]))
        setTickerAllData(resDataTicker.data?.data[0])
      }
      const resData = response.data;
      if (resData.result === true) {
        setStockDetail(resData.data);
      }
    } catch (error: any) {
      console.error("ERRR : ", error);
    } finally {
      dispatch(endLoading());
      setLoading(false)
    }
  };


  const getUrlRange = (): string => {
    const now = new Date(new Date().setDate(new Date().getDate() - 1));
    let endPoint = "";

    const formatDate = (date: Date) =>
      date.toISOString().split("T")[0]; // Format as yyyy-MM-dd

    const adjustDate = (date: Date, adjust: { days?: number; months?: number; years?: number }) => {
      if (adjust.days) date.setDate(date.getDate() + adjust.days);
      if (adjust.months) date.setMonth(date.getMonth() + adjust.months);
      if (adjust.years) date.setFullYear(date.getFullYear() + adjust.years);
      return date;
    };
    console.log(selection, adjustDate(new Date(now), { days: -1 }), "selectionselection")
    switch (selection) {
      case "1 Day":
        endPoint = `/range/15/minute/${formatDate(adjustDate(new Date(now), { days: -1 }))}/${formatDate(now)}`;
        break;
      case "5 Days":
        endPoint = `/range/1/day/${formatDate(adjustDate(new Date(now), { days: -8 }))}/${formatDate(now)}`;
        break;
      case "1 Month":
        endPoint = `/range/1/day/${formatDate(adjustDate(new Date(now), { months: -1 }))}/${formatDate(now)}`;
        break;
      case "6 Months":
        endPoint = `/range/1/week/${formatDate(adjustDate(new Date(now), { months: -6 }))}/${formatDate(now)}`;
        break;
      case "1 Year":
        endPoint = `/range/1/month/${formatDate(adjustDate(new Date(now), { years: -1 }))}/${formatDate(now)}`;
        break;
      case "5 Years":
        endPoint = `/range/1/month/${formatDate(adjustDate(new Date(now), { years: -5 }))}/${formatDate(now)}`;
        break;
      case "All":
        endPoint = `/range/1/month/1980-01-01/${formatDate(now)}`;
        break;
      default:
        endPoint = `/range/1/day/${formatDate(adjustDate(new Date(now), { days: -1 }))}/${formatDate(now)}`;
    }
    return endPoint;
  };

  const getChartData = async () => {
    dispatch(startLoading());
    console.log(getUrlRange(), "getUrlRange()")
    try {
      const url = `https://api.polygon.io/v2/aggs/ticker/${stock}${getUrlRange()}?apiKey=${BAR_CHART_KEY}&sort=asc&limit=5000`;
      const response = await axios.get(url);
      console.log(response.data, "response.dataresponse.data")
      const apiResponse = response.data.results
      const chartData = apiResponse.length && apiResponse.map(item => ({
        x: new Date(item.t),  // Convert Unix timestamp to Date
        y: item.c            // Extract closing price "c"
      }));

      console.log(chartData, "chartDatachartDatachartData")
      setChartData(chartData || []);
    } catch (error) {
      console.error("Error fetching chart data:", error);
    } finally {
      dispatch(endLoading());
    }
  };


  const fetchFinanceInsights = async () => {
    try {
      const response = await axios.get(`${BASE_URL}?symbol=${stock}`, {
        headers: {
          "x-api-key": YAHOO_FINANCE_KEY,
          "useQueryString": true,
        },
      });
      console.log("Response Data:", response.data);
      // return response.data;
    } catch (error) {
      console.error("Error fetching finance insights:", error);
    }
  };

  useEffect(() => {
    getStockDetail();
    fetchFinanceInsights();
  }, [stock]);

  useEffect(() => {
    getChartData();
  }, [selection]);

  const CreateWatchList = async () => {
    dispatch(startLoading());

    const payLoad = {
      userId: getCurrentUserId(),
      symbol: stock,
    };
    try {
      const response = await Axios.post(
        UrlConstant.WachList.CreateWatchList,
        payLoad
      );
      const resData = response.data;
      Swal.fire(resData.msg);
    } catch (error: any) {
      console.error("ERRR : ", error);
    } finally {
      dispatch(endLoading());
    }
  };

  return (
    <Box className="bg-dark" display="flex">
      <Container
        maxWidth={false}
        className="bg-dark p-3 px-5"
        style={{
          // display: "flex",
          height: "fit-content",
          width: "70%",
        }}
      >
        <Typography
          className="text-secondary"
          sx={{ marginTop: "30px", marginBottom: "30px" }}
        >
          {" "}
          <span
            onClick={() => navigation(-2)}
            style={{ color: "#4A7AF2", cursor: "pointer" }}
          >
            Dashboard{" "}
          </span>{" "}
          <span
            onClick={() => navigation(-1)}
            style={{ color: "#4A7AF2", cursor: "pointer" }}
          >
            / {title}
          </span>
          <span> / {stock}</span>
        </Typography>

        <Container
          style={{ width: "100%", left: "0", margin: "0" }}
          className="p-0"
        >
          <Box>
            <Box
              display="flex"
              alignItems="start"
              justifyContent="space-between"
            >
              <Box className="text-white">
                <Box display="flex" alignItems="center" gap="20px" mb={2}>
                  {icon ? (
                    <Avatar
                      alt={stock}
                      src={icon}
                      sx={{
                        width: 64,
                        height: 64,
                        backgroundColor: "white",
                        objectFit: "fill",
                      }}
                      className="icon"
                    />
                  ) : (
                    <Box
                      sx={{
                        width: 64,
                        height: 64,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "#f0f0f0",
                        borderRadius: "50%",
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{ fontWeight: "bold", color: "#212529" }}
                      >
                        {stock}
                      </Typography>
                    </Box>
                  )}
                  <Box>
                    <Typography mb={1} variant="h5">
                      {stockDetail?.displayName}
                    </Typography>
                    <Typography variant="body1" className="text-secondary">
                      {stockDetail?.stockExchangeCode}:{stockDetail?.symbol}
                    </Typography>
                  </Box>
                </Box>
                <Box display="flex" gap="10px">
                  <Typography variant="h5" textAlign="end">
                    $ {tickerData?.lastPrice}
                  </Typography>
                  <Typography variant="h5" style={{ color: tickerData.diffAmount > 0 ? "#4edf6d" : "#ff2a2a" }}>
                    {/* -$1.35(0.01%) */}
                    ${tickerData.diffAmount?.toFixed(2)}({tickerData.percentage?.toFixed(2)}%)
                  </Typography>
                </Box>
              </Box>

              <ToggleButton
                className="text-secondary"
                value="watchlist"
                sx={{
                  padding: "10px",
                  borderRadius: "10px",
                  borderColor: "#FFFFFF33",
                }}
                onClick={CreateWatchList}
              >
                <BookmarkBorderOutlined
                  sx={{ marginRight: "10px" }}
                ></BookmarkBorderOutlined>{" "}
                watchList
              </ToggleButton>
            </Box>
          </Box>
          <Row className="dark-tbl no-border p-0 mt-5">
            <Card
              className="custom-card overflow-hidden bg-dark w-100"
              style={{ height: "fit-content" }}
            >
              <Card.Body className="ps-12 p-2 h-100">
                <LineChart data={chartData} />
                <hr style={{ borderColor: "#FFFFFF", borderWidth: "1px" }}></hr>
                <Box mb={1}>
                  <ToggleButtonGroup
                    // className="border-white"
                    // color="primary"
                    // fullWidth
                    value={selection}
                    exclusive
                    onChange={handleselect}
                    aria-label="Platform"
                  >
                    <ToggleButton value="1 Minute">1 Minute</ToggleButton>
                    <ToggleButton value="5 Minutes">5 Minutes</ToggleButton>
                    <ToggleButton value="1 Hour">1 Hour</ToggleButton>
                    <ToggleButton value="1 Day">1 Day</ToggleButton>
                    <ToggleButton value="1 Week">1 Week</ToggleButton>
                    <ToggleButton value="1 Month">1 Month</ToggleButton>
                    <ToggleButton value="1 Year">1 Year</ToggleButton>
                    <ToggleButton value="3 Years">3 Years</ToggleButton>
                    <ToggleButton value="5 Years">5 Years</ToggleButton>
                    <ToggleButton value="All">All</ToggleButton>
                  </ToggleButtonGroup>
                </Box>
              </Card.Body>
            </Card>
          </Row>
        </Container>

        {/* <Container style={{ width: "70%", left: "0", margin: "0" }}>
            <TotalFunds />
          </Container> */}
        {/* --------------------------------------------------- Market State --------------------------------- */}
        <Box my={5} pt={2}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography
              className="text-white"
              mb={2}
              style={{ fontSize: "1.5rem" }}
            >
              {" "}
              Market Stats{" "}
            </Typography>
            <Link
              to="/"
              style={{
                color: "#4A7AF2",
                textDecoration: "underline !importan",
                textDecorationLine: "underline !important",
              }}
            >
              view all
            </Link>
          </Box>

          <Box>
            <Grid container spacing={1}>
              {[
                { label: "Previous Close", value: tickerDataAll?.PreviousClose },
                { label: "Ask", value: tickerDataAll?.Ask },
                { label: "Bid", value: tickerDataAll?.Bid },
                { label: "52 Week High", value: stockDetail?.fiftyTwoWeekHigh },
                { label: "52 Week Low", value: stockDetail?.fiftyTwoWeekLow },
                { label: "Average Volume", value: stockDetail?.averageVolumeFmt },
                { label: "Market Cap", value: stockDetail?.marketCapFmt },
                { label: "PE Ratio", value: stockDetail?.peRatio },
                { label: "Dividend Yield", value: stockDetail?.dividendYieldFmt },
              ].map((item, index) => (
                <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={index}>
                  <Card className="card"
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      minWidth: "190px",
                      height: '100%',
                    }}
                  >
                    <CardContent className="text-white">
                      <Typography gutterBottom>{item.label}</Typography>
                      <Typography mb="0px" mr={10}>
                        $ {item.value ?? "0"}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
        {/* --------------------------------------------------- Insights --------------------------------- */}
        <Box my={5} pt={2}>
          <Typography
            className="text-white"
            mb={2}
            style={{ fontSize: "1.5rem" }}
          >
            {" "}
            Insights{" "}
          </Typography>
          <Box display='flex' alignItems='center' justifyContent='space-between' width='100%'>
            <Box ref={scrollRef} sx={{
              '&::-webkit-scrollbar': { display: 'none' }, // Hide scrollbar in Chrome, Safari, and Edge
            }} style={{ scrollBehavior: 'smooth', overflowX: 'auto' }} className='scroll' display='flex' gap='20px' width='95%' overflow='hidden'>
              <Card className="card" style={{ minWidth: '530px', maxWidth: "530px" }}>
                <CardContent className="text-white">
                  <Typography gutterBottom>Who Should you Follow?</Typography>
                  <Typography variant="body2" gutterBottom className="text-secondary">November 12, 2021</Typography>
                  <Typography
                    variant="body2"
                    onClick={handleReadMore}
                    style={{ overflow: 'hidden', height: expanded ? 'fit-content' : '40px', transition: 'max-height 0.3s ease' }}
                  >
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet
                    <span style={{ color: 'rgba(74, 122, 242, 1)' }}>{expanded ? '' : 'read more'}</span> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet
                    {/* <span style={{color:'rgba(74, 122, 242, 1)'}}>{expanded ? 'show less' : ''}</span> */}

                  </Typography>


                </CardContent>
              </Card>
              <Card className="card" style={{ minWidth: '530px', maxWidth: "530px" }}>
                <CardContent className="text-white">
                  <Typography gutterBottom>Who Should you Follow?</Typography>
                  <Typography variant="body2" gutterBottom className="text-secondary">November 12, 2021</Typography>
                  <Typography
                    variant="body2"
                    onClick={handleReadMore}
                    style={{ overflow: 'hidden', height: expanded ? 'fit-content' : '40px', transition: 'max-height 0.3s ease' }}
                  >
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet
                    <span style={{ color: 'rgba(74, 122, 242, 1)' }}>{expanded ? '' : 'read more'}</span> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet
                    {/* <span style={{color:'rgba(74, 122, 242, 1)'}}>{expanded ? 'show less' : ''}</span> */}

                  </Typography>


                </CardContent>
              </Card>
              <Card className="card" style={{ minWidth: '530px', maxWidth: "530px" }}>
                <CardContent className="text-white">
                  <Typography gutterBottom>Who Should you Follow?</Typography>
                  <Typography variant="body2" gutterBottom className="text-secondary">November 12, 2021</Typography>
                  <Typography
                    variant="body2"
                    onClick={handleReadMore}
                    style={{ overflow: 'hidden', height: expanded ? 'fit-content' : '40px', transition: 'max-height 0.3s ease' }}
                  >
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet
                    <span style={{ color: 'rgba(74, 122, 242, 1)' }}>{expanded ? '' : 'read more'}</span> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet
                    {/* <span style={{color:'rgba(74, 122, 242, 1)'}}>{expanded ? 'show less' : ''}</span> */}

                  </Typography>


                </CardContent>
              </Card>
            </Box>
            <IconButton sx={{ pl: "0px" }} onClick={handleScrollLeft}>
              <ExpandCircleDownOutlined
                sx={{ rotate: "-90deg" }}
              ></ExpandCircleDownOutlined>
            </IconButton>
          </Box>
        </Box>

        {/* --------------------------------------------------- Dividents --------------------------------- */}
        <Box my={5} pt={2}>

          <Typography
            className="text-white"
            mb={2}
            style={{ fontSize: "1.5rem" }}
          >
            {" "}
            Dividends{" "}
          </Typography>


          <Box>
            <Card className="card" style={{ width: '260px' }}>
              <CardContent className="text-white d-flex justify-content-between">
                <Typography >{moment(stockDetail?.lastDividendDateFmt).format("DD-MM-YYYY")}</Typography>
                <Typography mb="0px">
                  {stockDetail?.lastDividendValueFmt}
                </Typography>
              </CardContent>
            </Card>
          </Box>
        </Box>
        {/* --------------------------------------------------- About --------------------------------- */}
        <Box my={5} pt={2}>

          <Typography
            className="text-white"
            mb={2}
            style={{ fontSize: "1.5rem" }}
          >
            {" "}
            About{" "}
          </Typography>


          <Box>
            <Card className="card" style={{ minWidth: '530px', maxWidth: "100%" }}>
              <CardContent className="text-white">
                <Typography gutterBottom>{stockDetail?.displayName}</Typography>
                <Typography variant="body2" gutterBottom className="text-secondary">{moment(stockDetail?.updatedAt).format("MMMM DD, YYYY")}</Typography>
                <Typography
                  variant="body2"
                // style={{ overflow: 'hidden', height: expanded ? 'fit-content' : '40px', transition: 'max-height 0.3s ease' }}
                >
                  {stockDetail?.companyDescription}
                </Typography>


              </CardContent>
            </Card>
          </Box>
        </Box>
      </Container>
      <Container style={{ width: "30%", paddingTop: "20px" }}>
        <Box width="100%">
          <Typography
            component="h1"
            fontSize={24}
            className="text-white"
            mb={2}
          >
            Buy/Sell
          </Typography>
          <BuySell stockDetail={stockDetail} tickerData={tickerData} tickerDataAll={tickerDataAll} />
        </Box>
      </Container>
      {loading && (
        <Box
          position="fixed"
          top={0}
          left={0}
          width="100vw"
          height="100vh"
          bgcolor="rgba(0, 0, 0, 0.5)" // Semi-transparent black background
          display="flex"
          justifyContent="center"
          alignItems="center"
          style={{
            backdropFilter: "blur(5px)", // Blur effect
            zIndex: 9999, // Ensures the loader stays above all elements
          }}
        >
          <CircularProgress color="primary" />
        </Box>
      )}
    </Box>
  );
}
export default StockDetail;
