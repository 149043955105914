import {
  Avatar,
  Box,
  CircularProgress,
  Container,
  FormControl,
  MenuItem,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import React, { useEffect, useState } from "react";
import { Card, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Constant } from "../../../commom/Constants";
import { UrlConstant } from "../../../commom/UrlConstant";
import Axios from "../../../config/axios";
import { endLoading, startLoading } from "../../../Redux/loadingSlice";
import Yourinvestment from "../Cards/Yourinvestment";
import { CommonDataTable } from "../SharedComponent/CommonDataTable";
function MarketSignals() {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState("");


  const [totalRows] = useState(10);
  const [marketsignalselect, setmarketsignalselect] = React.useState<any>("bullish");
  const [marketSignalOpt, setMarketsignalOpt] = React.useState<String>("");
  const [marketSignalFilers, setMarketSignalFilers] = useState<any>([])
  const [marketSignalFilersData, setMarketSignalFilersData] = useState<any>([])
  const [loading, setLoading] = useState(false);


  // ** Table Headers

  const columns = [
    {
      name: "Company",
      selector: (row) => [row.company],
      cell: (row) => (
        <div
          onClick={() => {
            navigateToDetail(row);
          }}
          className="stock-label"
          style={{
            display: "flex",
            alignItems: "center",
            gap: "5px",
            cursor: "pointer",
          }}
        >
          <Avatar
            className="stock-icon"
            alt={row.ticker}
            src={row.branding?.icon_url}
            sx={{
              width: 30,
              height: 30,
              backgroundColor: "white",
              objectFit: "fill",
            }}
          />
          <span
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {row.company}
          </span>
        </div>
      ),
      sortable: true,
      center: false,
      width: "350px",
      //width: "auto",
    },
    {
      name: "Market Price",
      selector: (row) => [row.price],
      cell: (row) => (
        <div>
          {row.price === null ? "-" : `$${row.price?.toFixed(2)}`}
        </div>
      ),
      // width: "130px",
      width: "auto",
      sortable: true,
      center: true,
    },
    {
      name: "sector",
      selector: (row) => [row.sector],
      cell: (row) => <div >{row.sector}</div>,
      sortable: true,
      width: "100px",
      center: true,
    },

    {
      name: "Percentage",
      selector: (row) => [row.change],
      sortable: true,
      cell: (row) => {
        const numericChange = parseFloat(row.change.replace('%', ''));

        return (
          <div
            style={{
              color: numericChange === 0 ? '' : numericChange > 0 ? '#4edf6d' : 'red',
            }}
          >
            {numericChange > 0 ? `+${row.change}` : row.change}
          </div>
        );
      },
      width: "150px",
      center: true,
    },
  ];
  const navigateToDetail = async (data) => {
    //stockdetail
    navigation("/maindashboard/stockdetail", {
      state: {
        symbol: data.ticker,
        icon: data.branding?.logo_url ?? data.branding?.icon_url,
        title:"Market Signals"
      },
    });
  };

  const handleChangeMarketSingnalsSelect = (event: SelectChangeEvent) => {
    setmarketsignalselect(event.target.value as string);
    setMarketsignalOpt(marketSignalFilers[event.target.value]?.[0].key);
  };

  const onMarketSignalFilterChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    setMarketsignalOpt(newAlignment);
  };


  const getMarketSignalsFilters = async () => {
    setLoading(true);
    try {
      const response = await Axios.get(
        UrlConstant.Market.GetMarketSignalsFilters
      );
      const resData = response.data;
      if (resData.status === true) {
        setMarketSignalFilers(resData.data);
        setMarketsignalOpt(resData.data[marketsignalselect]?.[0].key);
      }
    } catch (error: any) {
      console.error("ERRR : ", error);
    } finally {
      setLoading(false);
    }
  };


  const getMarketSignalsFilterData = async () => {
    setLoading(true);
    const data = {
      filterKey: marketSignalOpt,
    };
    try {
      const response = await Axios.post(
        UrlConstant.Market.GetMarketSignalsFiltersData,
        data
      );
      const resData = response.data;
      if (resData.status === true) {
        setMarketSignalFilersData(resData.data);
      }
    } catch (error: any) {
      console.error("ERRR : ", error);
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    getMarketSignalsFilters();
  }, []);

  useEffect(() => {
    if (marketSignalOpt) {
      getMarketSignalsFilterData()
    }
  }, [marketSignalOpt])


  return (
    <Box className="bg-dark" display="flex">
      <Container
        maxWidth={false}
        className="bg-dark p-3 px-5"
        style={{
          minHeight: "calc(100vh -  70px)",
          // height:'100vh',
          width: "70%",
        }}
      >
        <Typography
          className="text-secondary"
          sx={{ marginTop: "30px", marginBottom: "30px" }}
        >
          {" "}
          <span
            onClick={() => navigation(-1)}
            style={{ color: "#4A7AF2", cursor: "pointer" }}
          >
            Dashboard{" "}
          </span>{" "}
          / Market Signals
        </Typography>
        <Box mb={5}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box display="flex" gap={2} alignItems="center" mb={1}>
              <Typography
                className="text-white"
                mb={0}
                style={{ fontSize: "1.5rem" }}
              >
                Market Signals
              </Typography>

              <FormControl>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select2"
                  value={marketsignalselect}
                  className="text-white"
                  sx={{
                    // color:'!important',
                    borderRadius: "9px !important",
                    padding: "0px !important",
                    '.MuiSvgIcon-root': {
                        color:'white !important'
                      }
                  }}
                  onChange={handleChangeMarketSingnalsSelect}
                >
                  <MenuItem value="bullish" selected>Bulish</MenuItem>
                  <MenuItem value="bearish">Bearish</MenuItem>
                </Select>
              </FormControl>

            </Box>
          </Box>

          <Box mb={1}>
            <ToggleButtonGroup
              // className="border-white"
              // color="primary"
              value={marketSignalOpt}
              exclusive
              onChange={onMarketSignalFilterChange}
              aria-label="Platform"
            >
              {
                marketSignalFilers[marketsignalselect]?.map((filter) => {
                  return (
                    <ToggleButton value={filter.key} >
                      <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Avatar
                          alt={filter?.name}
                          src={marketSignalOpt == filter.key ? filter?.selectedIconUrl : filter?.iconUrl}
                          sx={{ width: 20, height: 20, marginRight: "5px" }}
                        />
                        {filter.name}
                      </Box>
                    </ToggleButton>
                  )
                })
              }
            </ToggleButtonGroup>

          </Box>
        </Box>
        <Container
          style={{ width: "100%", left: "0", margin: "0" }}
          className="p-0"
        >
          <Row className="dark-tbl no-border p-0">
            <Card className="custom-card overflow-hidden bg-dark w-100">
              <Card.Body className="ps-12 p-2">
                <div>
                  {loading ? (
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      height="200px"
                    >
                      <CircularProgress color="primary" />
                    </Box>
                  ) : (
                    <CommonDataTable
                      setPage={setPage}
                      setRowsPerPage={setRowsPerPage}
                      data={marketSignalFilersData}
                      totalRows={totalRows}
                      columns={columns}
                      setSearch={setSearch}
                    />
                  )}
                </div>
              </Card.Body>
            </Card>
          </Row>
        </Container>

        {/* <Container style={{ width: "70%", left: "0", margin: "0" }}>
          <TotalFunds />
        </Container> */}
      </Container>
      <Container style={{ width: "30%", paddingTop: "20px" }}>
        <Box width="100%">
          <Typography
            component="h1"
            fontSize={24}
            className="text-white"
            mb={2}
          >
            Your Investment
          </Typography>
          <Yourinvestment />
        </Box>
      </Container>
    </Box>
  );
}
export default MarketSignals;
