import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from 'react'
import { Container, Typography, Box, Card, CardContent, Avatar, ToggleButton, ToggleButtonGroup, CircularProgress, FormControl, Select, MenuItem, SelectChangeEvent, RadioGroup, FormControlLabel, Radio } from "@mui/material";
import { UrlConstant } from "../../../commom/UrlConstant";
import Axios from "../../../config/axios";
import DonutChart from "../charts/DonutChart";
import { getUserFromLocalStorage } from "../../../Utils/utils";
import PortfolioTabel from "../Tabels/PortfolioTabel";

function Portfolio() {

  const user = getUserFromLocalStorage();


  const [loading, setLoading] = useState<any>(false);
  const [tradingInfo, setTradingInfo] = useState<any>();
  const [investmentChart, setInvestmentChart] = useState<any>()
  const [stockChart, setStockChart] = useState<any>()
  const [stockChartLabel, setStockLabel] = useState<any>()
  const [portfolioData, setPortfolioData] = useState<any>()
  const [portfolioListData, setPortfolioListData] = useState<any>()
  const [stockChartType, setStockChartType] = React.useState<any>('stockChart');

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [selectedType, setSelectedType] = useState("Stock");

  const handleRadioChange = (event) => {
    setSelectedType(event.target.value);
    console.log("Radio button changed to:", event.target.value);
  };

  const handleChange = (event: SelectChangeEvent) => {
    setStockChartType(event.target.value as string);
  };

  const convertToPercentage = (data: number[]) => {
    const total = data?.reduce((acc, value) => acc + value, 0);
    const percentages = data?.map((value) => (total === 0 ? 0 : (value / total) * 100));

    // Round each percentage to two decimal places
    const roundedPercentages = percentages?.map((percentage) => parseFloat(percentage.toFixed(2)));

    // Adjust rounding errors so the total adds up to 100
    const sum = roundedPercentages?.reduce((acc, percentage) => acc + percentage, 0);
    const difference = 100 - sum;

    // Add the difference to the first element to ensure the total is 100
    if (difference !== 0) {
      roundedPercentages[0] += difference;
    }

    return roundedPercentages;
  };


  const fetchTradingBalance = async () => {
    // check-trading-balance
    setLoading(true)
    const data = {
      "accountId": user?.tradingblock_application_id
    }
    try {
      const response = await Axios.post(
        UrlConstant.BuySell.GetTradingBalance, data)

      setTradingInfo(response.data?.data?.Payload)
    } catch (error: any) {
      console.error("ERRR : ", error);
    } finally {
      setLoading(false)
    }
  }
  const fetchPortfolioData = async () => {
    setLoading(true)
    const data = {
      "accountId": user?.tradingblock_application_id
    }
    try {
      const response = await Axios.post(
        UrlConstant.Portfolio.GetPortfolio, data)

      setPortfolioData(response.data?.data)
      setPortfolioListData(response.data?.data?.entries)
    } catch (error: any) {
      console.error("ERRR : ", error);
    } finally {
      setLoading(false)
    }
  }


  useEffect(() => {
    fetchTradingBalance()
    fetchPortfolioData()
  }, [])

  useEffect(() => {
    console.log(tradingInfo, "tradingInfo", portfolioData)
    const cash = tradingInfo?.Balances?.CashBalance
    const stock = portfolioData?.stockChart?.stockTotal
    const ETF = portfolioData?.etfChart?.etfTotal
    const data = [cash, stock, ETF]
    const finalDataPer = data?.length && convertToPercentage(data)
    setInvestmentChart(finalDataPer)
  }, [tradingInfo, portfolioData])

  useEffect(() => {
    let data = portfolioData?.[stockChartType].chartDataTest
    if (selectedType == "ETF") {
      data = portfolioData?.["etfChart"].chartDataTest
    }
    const finalData = data?.map((item) => item.amount)
    const finalDataLabel = data?.map((item) => item.symbol)
    const finalDataPer = finalData?.length && convertToPercentage(finalData)
    setStockChart(finalDataPer)
    setStockLabel(finalDataLabel)
  }, [selectedType, stockChartType, portfolioData])

  console.log(stockChartLabel, stockChart, "==================")



  return <>
    <Container maxWidth={false} className="bg-dark" style={{ padding: '30px' }}>
      {loading && (
        <Box
          position="fixed"
          top={0}
          left={0}
          width="100vw"
          height="100vh"
          bgcolor="rgba(0, 0, 0, 0.5)" // Semi-transparent black background
          display="flex"
          justifyContent="center"
          alignItems="center"
          style={{
            backdropFilter: "blur(5px)", // Blur effect
            zIndex: 9999, // Ensures the loader stays above all elements
          }}
        >
          <CircularProgress color="primary" />
        </Box>
      )}
      <Typography variant="body1" fontSize='24px' fontWeight={400} className="text-white">Booster Loan Details</Typography>
      <Box display="flex" flexDirection={{ xs: "column", md: "row" }} gap="10px" my="20px" alignItems="stretch">
        {/* First Card with Chart */}
        <Box flexGrow={1} p="0px">
          <Card className="card" style={{ width: "100%", padding: "12px 10px" }}>
            <CardContent className="text-white">
              <Grid container spacing={2}>
                {/* Title */}
                <Grid item xs={12}>
                  <Typography variant="h6" className="text-white" style={{ textAlign: "left" }}>
                    Investment Portfolio
                  </Typography>
                </Grid>

                {/* Chart */}
                <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
                  <DonutChart
                    series={investmentChart?.length ? investmentChart : []}
                    labels={["Stocks", "Cash", "ETFs"]}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>

        {/* Second Card with Chart */}
        <Box flexGrow={1} p="0px">
          <Card className="card" style={{ width: "100%", padding: "12px 10px" }}>
            <CardContent className="text-white">
              <Grid container spacing={2}>
                {/* Title */}
                <Grid item xs={12}>
                  <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                    <RadioGroup
                      row
                      value={selectedType}
                      onChange={handleRadioChange}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <FormControlLabel
                        value="Stock"
                        control={<Radio size="small" sx={{ color: "#3F8EFC" }} />}
                        label={<Typography sx={{ color: "#FFFFFF", fontSize: "14px" }}>Stock</Typography>}
                      />
                      <FormControlLabel
                        value="ETF"
                        control={<Radio size="small" sx={{ color: "#3F8EFC" }} />}
                        label={<Typography sx={{ color: "#FFFFFF", fontSize: "14px" }}>ETF</Typography>}
                      />
                    </RadioGroup>
                    <FormControl >
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={stockChartType}
                        className="text-white"
                        sx={{
                          borderRadius: '9px !important',
                          padding: '0px !important',

                        }}
                        onChange={handleChange}
                      >
                        <MenuItem value={`stockChart`}>
                          Sector
                        </MenuItem>
                        <MenuItem value={`sectorChart`}>
                          Assets
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>

                {/* Chart */}
                <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
                  {selectedType === "Stock" ?
                    <DonutChart
                      series={stockChart?.length ? stockChart : []}
                      labels={stockChartLabel?.length ? stockChartLabel : []}
                    />
                    :
                    <DonutChart
                      series={stockChart?.length ? stockChart : []}
                      labels={stockChartLabel?.length ? stockChartLabel : []}
                    />
                  }
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      </Box>
      <PortfolioTabel
        portfolioListData={portfolioListData}
      />

    </Container>
  </>;
}

export default Portfolio;
