import { Box, Button, TextField } from "@mui/material";
import React, { useState } from "react";

const PriceAdjuster = ({value, setValue}:any) => {
  // const [value, setValue] = useState<number>(0);

  const handleIncrease = () =>
    setValue((prev) => parseFloat((prev + 1).toFixed(2)));
  const handleDecrease = () =>
    setValue((prev) => parseFloat(Math.max(prev - 1, 0).toFixed(2)));
  const handlechnage = (e) => setValue(e.target.value);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        border: "1px solid #cccccc",
        borderRadius: "10px",
        padding: "5px 10px",
        // backgroundColor: "#333",
      }}
      className="w-25"
    >
      <Button
        onClick={handleDecrease}
        sx={{ color: "white", p: 0, py: "5px", minWidth: "10px" }}
      >
        −
      </Button>

      <TextField
        id="outlined-basic"
        value={value}
        onChange={handlechnage}
        sx={{
          "& .MuiOutlinedInput-root": {
            border: "none !important",
            outline: "none !important",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          "& .MuiOutlinedInput-input": {
            padding: "0px",
            textAlign: "center",
            color: "white",
            // fontSize: "1.2rem",
            // fontSize:{
            //     md:'small',
            //     lg:'medium',
            //     xl:'large'
            // }
          },
        }}
        variant="outlined"
      />

      <Button
        onClick={handleIncrease}
        sx={{ color: "white", p: 0, minWidth: "10px" }}
      >
        +
      </Button>
    </Box>
  );
};

export default PriceAdjuster;
