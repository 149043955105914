import React, { useEffect, useState } from 'react';
import { Grid, CircularProgress } from '@mui/material';
import MoreOptionsCards from '../Cards/MoreOptionsCards';

function MoreOptions() {
  return (
    <Grid
      container
      style={{ padding: '24px', justifyContent: 'space-between' }}
      className="bg-dark"
      minHeight="calc(100vh - 70px)"
    >
      <Grid width="49%" item>
        <MoreOptionsCards />
      </Grid>
    </Grid>
  );
}

export default MoreOptions;