import React, { useState, useEffect } from 'react';
import { Route, Routes, useNavigate } from "react-router-dom";
import {
  Card,
  CardHeader,
  Typography,
  Button,
  Divider,
  Switch,
  Stack,
  IconButton,
} from '@mui/material';
import { Add } from '@mui/icons-material';
import Axios from '../../../config/axios';
import { UrlConstant } from '../../../commom/UrlConstant';
import MainDashboard from '../Layout/MainDashboard';
import Swal from 'sweetalert2';

type Preference = {
  _id: string;
  preferenceName: string;
  preferenceType: 'BUY' | 'SELL';
  stockExchangeName: string;
  level: string;
  type: 'STOCK' | 'ETF';
  isActive: boolean;
};

type DiscoverystockProps = {
  heading: string;
  preferences: Preference[];
  onPreferenceUpdate: (id: string, isActive: boolean) => void;
  fetchPreferences: () => void;
};

const Discoverystock: React.FC<DiscoverystockProps> = ({
  heading,
  preferences,
  onPreferenceUpdate,
  fetchPreferences
}) => {
  const [switchStates, setSwitchStates] = useState<Record<string, boolean>>({});
  const navigate= useNavigate()
  const navigation = useNavigate();


  // Synchronize switchStates with preferences prop
  useEffect(() => {
    const initialStates = preferences.reduce(
      (acc, pref) => ({ ...acc, [pref._id]: pref.isActive }),
      {}
    );
    setSwitchStates(initialStates);
  }, [preferences]);

  const handleSwitchToggle = async (id: string) => {
    const currentState = switchStates[id];

    setSwitchStates((prev) => ({ ...prev, [id]: !currentState }));

    try {
      const response = await Axios.post(UrlConstant.Discovery.updateActivation, {
        preferenceId: id,
      });

      if (response.data.result) {
        onPreferenceUpdate(id, !currentState);
      } else {
        alert('Failed to update preference activation');
      }
    } catch (error) {
      console.error('Error updating preference:', error);
      alert('An error occurred while updating the preference.');

      setSwitchStates((prev) => ({ ...prev, [id]: currentState }));
    }
  };


  const onDeletePreference = async (id: string) => {
    try {
      const response = await Axios.post(UrlConstant.Discovery.deletePreferenceData, {
        preferenceId: id,
      });

      if (response.data.result) {
        fetchPreferences()
      } else {
        alert('Failed to update preference activation');
      }
    } catch (error) {
      console.error('Error updating preference:', error);
      alert('An error occurred while updating the preference.');

    }
  };

  const handleDeleteClick = (id: string) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you really want to delete this preference?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.isConfirmed) {
        onDeletePreference(id);
        Swal.fire(
          'Deleted!',
          'The preference has been deleted.',
          'success'
        );
      }
    });
  };

  const onEditPreference = (profile)=>{
    navigation("/maindashboard/discoveryform", {
      state: {
        id: profile._id,
      },
    });

  }

  const PreferenceItem = ({ profile }: { profile: Preference }) => (
    <React.Fragment>
      <Divider sx={{ borderColor: 'rgba(255, 255, 255, 0.1)' }} />
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        spacing={2}
        sx={{ marginTop: 1 }}
      >
        <Stack direction="column" spacing={1}>
          <Typography variant="body1" sx={{ fontSize: '18px', color: '#fff' }}>
            {profile.preferenceName}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontSize: '16px',
              color: profile.preferenceType === 'BUY' ? '#4edf6d' : '#ff2a2a',
            }}
          >
            <span style={{ color: '#fff' }}>{profile.type}</span> |{' '}
            {profile.preferenceType}
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Switch
            checked={switchStates[profile._id] || false}
            onChange={() => handleSwitchToggle(profile._id)}
            sx={{
              '& .MuiSwitch-switchBase.Mui-checked': { color: '#f2674a' },
              '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                bgcolor: '#f2674a',
              },
              '& .MuiSwitch-track': { bgcolor: 'rgba(255,255,255,0.4)' },
            }}
          />
          <IconButton color="primary" onClick={()=>onEditPreference(profile)}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22H15C20 22 22 20 22 15V13"
                stroke="#4A7AF2"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M16.04 3.02025L8.16 10.9003C7.86 11.2003 7.56 11.7903 7.5 12.2203L7.07 15.2303C6.91 16.3203 7.68 17.0803 8.77 16.9303L11.78 16.5003C12.2 16.4403 12.79 16.1403 13.1 15.8403L20.98 7.96025C22.34 6.60025 22.98 5.02025 20.98 3.02025C18.98 1.02025 17.4 1.66025 16.04 3.02025Z"
                stroke="#4A7AF2"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </IconButton>
          <IconButton color="error" onClick={()=>handleDeleteClick(profile._id)}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21 5.98047C17.67 5.65047 14.32 5.48047 10.98 5.48047C9 5.48047 7.02 5.58047 5.04 5.78047L3 5.98047"
                stroke="#FF2A2A"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8.5 4.97L8.72 3.66C8.88 2.71 9 2 10.69 2H13.31C15 2 15.13 2.75 15.28 3.67L15.5 4.97"
                stroke="#FF2A2A"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M18.85 9.13965L18.2 19.2096C18.09 20.7796 18 21.9996 15.21 21.9996H8.79002C6.00002 21.9996 5.91002 20.7796 5.80002 19.2096L5.15002 9.13965"
                stroke="#FF2A2A"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M10.33 16.5H13.66"
                stroke="#FF2A2A"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M9.5 12.5H14.5"
                stroke="#FF2A2A"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </IconButton>
        </Stack>
      </Stack>
      <hr style={{ borderColor: '#FFFFFF', margin: '10px 0px' }} />
    </React.Fragment>
  );

  return (
    <Card
      className="card"
      sx={{ width: '100%', height: '100%', color: '#fff', padding: 3 }}
    >
      <CardHeader
        sx={{ padding: '0px' }}
        title={
          <Typography sx={{ fontSize: '20px', color: '#fff' }}>
            {heading}
          </Typography>
        }
        action={

          <Button
          variant="contained"
          onClick={()=>{navigate('/maindashboard/discoveryform')}}
            sx={{
              padding: '5px 13px !important',
              marginTop: 2,
              backgroundColor: '#f2674a',
              '&:hover': { backgroundColor: '#e05d3f' },
            }}
            startIcon={<Add />}
          >
            Add New
          </Button>
        }
      />
      <hr style={{ borderColor: '#FFFFFF', margin: '20px 0px' }} />
      {preferences.map((profile) => (
        <PreferenceItem key={profile._id} profile={profile} />
      ))}
    </Card>
  );
};

export default Discoverystock;