import { ExpandCircleDownOutlined } from "@mui/icons-material";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import { Dialog, DialogActions, DialogContent } from "@mui/material";

import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  FormControl,
  IconButton,
  MenuItem,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import React, { useEffect, useState,useRef } from "react";
import { useDispatch } from "react-redux";
import '../../../style/style.css'
import { Link, NavLink, useNavigate } from "react-router-dom";
import { logout } from "../../../Redux/authSlice";
import { endLoading, startLoading } from "../../../Redux/loadingSlice";
import { getUserFromLocalStorage } from "../../../Utils/utils";
import { Constant } from "../../../commom/Constants";
import { UrlConstant } from "../../../commom/UrlConstant";
import Axios from "../../../config/axios";
import createaccount from "../../../style/images/createaccount.png";
import Yourinvestment from "../Cards/Yourinvestment";
import TradingACDisclosure from "./TradingAccount/TradingACDisclosure";
import TradingACNotice from "./TradingAccount/TradingACNotice";
import Watchlistcard from "../Cards/watchlistcard";
import BoosterLoanCard from "../Cards/BoosterLoancard";
import moment from "moment";

function Dashboard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isTradingAcExist, setTradingAcExist] = React.useState<boolean>(false); //tradingblock_application_id
  const [isNoticeOpen, setNoticeOpen] = React.useState<boolean>(false);
  const [isDisclosureOpen, setDisclosureOpen] = React.useState<boolean>(false);

  const [isDialogOpen, setDialogOpen] = React.useState<boolean>(false);
  const [applicationStatus, setApplicationStatus] = useState("");

  const user = getUserFromLocalStorage();
  React.useEffect(() => {
    if (user && user.tradingblock_application_id) {
      setTradingAcExist(true);
    }
  }, [user]);

  // AI Picks Starts
  const [allAiPickData, setAllAiPickData] = React.useState<any>({});
  const [aiPicksList, setAiPicksList] = React.useState([]);
  const [buyOrSell, setBuyOrSell] = React.useState<any>(Constant.BUY);
  const [stockOrETF, setStockOrETF] = React.useState<any>(Constant.STOCK);
  const [selectedCategory, setSelectedCategory] = React.useState<String>(
    Constant.POPULAR
  );
  // AI Picks Starts

  const [watchList, setWatchList] = React.useState([]);
  const [allMyPickData, setAllMyPickData] = React.useState<any>([]);
  const [myPickData, setMyPickData] = React.useState<any>([]);
  const [marketMoversType, setMarketMoversType] = React.useState<any>("gainers");
  const [marketMovers, setMarketMovers] = React.useState<any>([]);
  const [marketMoversData, setMarketMoversData] = React.useState<any>([]);
  const [marketsignalselect, setmarketsignalselect] = React.useState<any>("bullish");
  const [myPicsType, setMyPicsType] = React.useState<any>("STOCK");
  const [myPicsPreference, setMyPicsPreference] = React.useState<any>("BUY");
  const [marketMoversFilter, setMarketMoversFilter] = React.useState<String>("L");
  const [marketSignalOpt, setMarketsignalOpt] = React.useState<String>("");
  const [myPicsOpt, setMyPicsOpt] = React.useState<String>("Live");
  const [boosterLoanRepaymentData, setBoosterLoanRepaymentData] = React.useState<any>([]);

  const scrollRef = useRef<HTMLDivElement>(null);
  // Market Signals

  const [marketSignalFilers, setMarketSignalFilers] = useState<any>([])
  const [marketSignalFilersData, setMarketSignalFilersData] = useState<any>([])


  const getSelectedAiPicksData = async (
    allStockData,
    categoryKey: string,
    actionKey: string
  ) => {
    if (allStockData?.[categoryKey]?.[actionKey]) {
      const list = allStockData[categoryKey][actionKey].map((stock: any) => {
        const item = stock.stockdata;
        const v1 = item.regularMarketPrice ?? 0;
        const v2 = item.Last ?? 0;
        const diffrence = v2 - v1;
        const div = diffrence / v1;
        const res = div * 100;
        const itemDetail = {
          displayName: item.displayName,
          stockPrice: item.Last,
          res: res,
          diffrence: diffrence,
          branding: item.branding,
          symbol: item.symbol,
          icon: item.branding?.logo_url ?? item.branding?.icon_url,
        };

        return itemDetail;
      });
      setAiPicksList(list);
    }
  };
  const handleScrollLeft = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({
        left: 300, // Amount to scroll
        behavior: 'smooth', // Enables smooth scrolling
      });
    }
  };
  const handleAiPickBuyOrSell = (event: SelectChangeEvent) => {
    const value = event.target.value as string;
    setBuyOrSell(value);
    getSelectedAiPicksData(allAiPickData, selectedCategory.toString(), value);
  };
  const handleStockOrETF = (event: SelectChangeEvent) => {
    setStockOrETF(event.target.value as string);
  };
  const handleSelectedCategory = (
    event: React.MouseEvent<HTMLElement>,
    newCategory: string
  ) => {
    setSelectedCategory(newCategory);
    if (newCategory === Constant.FOLLOWING) {
      getFollowiList();
    } else {
      getAiPickList(newCategory);
    }
  };

  const handleChangeMarketMovers = (event: SelectChangeEvent) => {
    setMarketMoversType(event.target.value as string);
  };

  const handleChangeMarketSingnalsSelect = (event: SelectChangeEvent) => {
    setmarketsignalselect(event.target.value as string);
    setMarketsignalOpt(marketSignalFilers[event.target.value]?.[0].key);
  };
  const handleChangemypicks1 = (event: SelectChangeEvent) => {
    setMyPicsType(event.target.value as string);
  };
  const handleChangeMyPicksPreference = (event: SelectChangeEvent) => {
    setMyPicsPreference(event.target.value as string);
  };

  const handleChangeMarketMoversFilter = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    setMarketMoversFilter(newAlignment);
  };
  const onMarketSignalFilterChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    setMarketsignalOpt(newAlignment);
  };
  const handleChangeMyPicksFilter = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    setMyPicsOpt(newAlignment);
  };

  const getSelectedData = async (
    allStockData,
    categoryKey: string,
    actionKey: string
  ) => {
    if (allStockData?.[categoryKey]?.[actionKey]) {
      const list = allStockData[categoryKey][actionKey].map((stock: any) => {
        const item = stock.stockdata;
        const v1 = item.regularMarketPrice ?? 0;
        const v2 = item.Last ?? 0;
        const diffrence = v2 - v1;
        const div = diffrence / v1;
        const res = div * 100;
        const itemDetail = {
          displayName: item.displayName,
          stockPrice: item.Last,
          res: res,
          diffrence: diffrence,
          branding: item.branding,
          symbol: item.symbol,
          icon: item.branding?.logo_url ?? item.branding?.icon_url,
        };

        return itemDetail;
      });
      setAiPicksList(list);
    }
  };

  const getAiPickList = async (category) => {
    dispatch(startLoading());

    try {
      const response = await Axios.get(
        `${UrlConstant.AiPicks.GetAiPicks}?type=${stockOrETF}`
      );
      const resData = response.data;
      if (resData.result === true) {
        setAllAiPickData(resData.data);
        getSelectedData(resData.data, category, buyOrSell.toString());
      }
    } catch (error: any) {
      console.error("ERRR : ", error.response.status);
      if (error.response.status === 403) {
        dispatch(logout());
        navigate("/login");
      }
    } finally {
      dispatch(endLoading());
    }
  };

  const getMyPickList = async () => {
    dispatch(startLoading());
    let URL;

    if (myPicsOpt == "Live") {
      URL = UrlConstant.MyPicks.GetMyPicksLive.replace("{0}", myPicsType)
    } else {
      URL = UrlConstant.MyPicks.GetMyPicksHistory.replace("{0}", myPicsType)
    }

    try {
      const response = await Axios.get(URL);
      const resData = response.data;
      if (resData.result === true) {
        setAllMyPickData(resData.data);
      }
    } catch (error: any) {
      console.error("ERRR : ", error.response.status);
      if (error.response.status === 403) {
        dispatch(logout());
        navigate("/login");
      }
    } finally {
      dispatch(endLoading());
    }
  };


  const getWatchList = async () => {
    dispatch(startLoading());

    try {
      const response = await Axios.get(UrlConstant.WachList.GetWatchList);
      const resData = response.data;
      if (resData.result === true) {
        const list = resData.data.map((item) => {
          const v1 = item.regularMarketPrice ?? 0;
          const v2 = item.Last ?? 0;
          const diffrence = v2 - v1;
          const div = diffrence / v1;
          const res = div * 100;
          const itemDetail = {
            displayName: item.displayName,
            stockPrice: item.Last,
            res: res,
            diffrence: diffrence,
            branding: item.branding,
            symbol: item.symbol,
            icon: item.branding?.logo_url ?? item.branding?.icon_url,
          };

          return itemDetail;
        });
        setWatchList(list);
      }
    } catch (error: any) {
      console.error("ERRR : ", error);
    } finally {
      dispatch(endLoading());
    }
  };

  const getBoosterLoanRepayment = async () => {
    try {
      const response = await Axios.get(
        UrlConstant.BoosterLoan.GetBoosterLoanRepayment
      );
      const resData = response.data;
      if (resData.status === true) {
        setBoosterLoanRepaymentData(resData.data);
      }
    } catch (error: any) {
      console.error("ERRR : ", error);
    } finally {
    }
  };

  const getFollowiList = async () => {
    dispatch(startLoading());

    try {
      const response = await Axios.post(
        UrlConstant.AiPicks.GetAiPicksOfFollowing.replace("{0}", stockOrETF)
      );
      const resData = response.data;
      if (resData.result === true) {
        setAllAiPickData(resData.data);
        const list = resData.data[buyOrSell].map((stock: any) => {
          const item = stock.stockdata;
          const v1 = item.regularMarketPrice ?? 0;
          const v2 = item.Last ?? 0;
          const diffrence = v2 - v1;
          const div = diffrence / v1;
          const res = div * 100;
          const itemDetail = {
            displayName: item.displayName,
            stockPrice: item.Last,
            res: res,
            diffrence: diffrence,
            branding: item.branding,
            symbol: item.symbol,
            icon: item.branding?.logo_url ?? item.branding?.icon_url,
          };

          return itemDetail;
        });
        setAiPicksList(list);
      }
    } catch (error: any) {
      console.error("ERRR : ", error);
    } finally {
      dispatch(endLoading());
    }
  };
  const checkAccountStatus = async () => {
    try {
      const response = await Axios.get(
        UrlConstant.TradingAccount.CheckAccountStatus
      );
      const resData = response.data;
      if (resData.status === true) {
        setApplicationStatus(resData.data.status);
      }
    } catch (error: any) {
      console.error("ERRR : ", error);
    } finally {
    }
  };
  const getMarketMovers = async () => {
    try {
      const response = await Axios.get(
        UrlConstant.MarketMovers.GetMarketMovers.replace("{0}", marketMoversType)
      );
      const resData = response.data;
      setMarketMovers(resData.data);
    } catch (error: any) {
      console.error("ERRR : ", error);
    } finally {
    }
  };
  const getMarketSignalsFilters = async () => {
    try {
      const response = await Axios.get(
        UrlConstant.Market.GetMarketSignalsFilters
      );
      const resData = response.data;
      if (resData.status === true) {
        setMarketSignalFilers(resData.data);
        setMarketsignalOpt(resData.data[marketsignalselect]?.[0].key);
      }
    } catch (error: any) {
      console.error("ERRR : ", error);
    } finally {
    }
  };

  const getMarketSignalsFilterData = async () => {
    const data = {
      filterKey: marketSignalOpt
    }
    try {
      const response = await Axios.post(
        UrlConstant.Market.GetMarketSignalsFiltersData, data
      );
      const resData = response.data;
      if (resData.status === true) {
        setMarketSignalFilersData(resData.data);
      }
    } catch (error: any) {
      console.error("ERRR : ", error);
    } finally {
    }
  };

  React.useEffect(() => {
    if (selectedCategory === Constant.FOLLOWING) {
      getFollowiList();
    } else {
      getAiPickList(selectedCategory);
    }
  }, [selectedCategory, stockOrETF]);

  useEffect(() => {
    getWatchList();
    checkAccountStatus();
    getMarketSignalsFilters();
    getBoosterLoanRepayment();
  }, []);

  useEffect(() => {
    if (marketSignalOpt) {
      getMarketSignalsFilterData()
    }
  }, [marketSignalOpt])

  useEffect(() => {
    getMyPickList()
  }, [myPicsOpt, myPicsType])

  useEffect(() => {
    getMarketMovers()
  }, [marketMoversType])

  useEffect(() => {
    const filteredData = marketMovers?.length && marketMovers.filter(item => item.key === marketMoversFilter);
    setMarketMoversData(filteredData?.[0]?.data)
  }, [marketMoversFilter, marketMovers])

  useEffect(() => {
    const data = allMyPickData.length && allMyPickData.filter((item: any) => item.preferenceId?.preferenceType === myPicsPreference)
    setMyPickData(data)
  }, [allMyPickData, myPicsPreference])


  const createTradingAc = () => {
    setDialogOpen(true);
  };

  const getLogoUrl = (symbol) => {
    if (!symbol) {
      throw new Error('Symbol is required');
    }
    return `https://logos-api.extend.apexclearing.com/logos/${symbol.toLowerCase().trim()}.png?api_key=4RJeGywzAE&api_secret=isUE1ZzPNi5IzXbs9Qg0S01eaFUcxX09c4U88R9JBiJgJ6WxXHrXz3VtEima`;
  };

  return (
    <>
      <Container
        maxWidth={false}
        
        className="bg-dark"
        style={{ display: "flex", padding: " 20px 30px"}}
      >
        {/* ....................................... Left Side Content ........................................ */}
        <Box sx={{ width: "70%", left: "0", margin: "0", }}>
          {/* .......................................... Indices.................................................... */}
          <Box display='flex'>
          <Box mb={5} width='100%'>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography
                mb={2}
                style={{ fontSize: "1.5rem" ,color:'#F2674A' }}
              >
                {" "}
                Indices{" "}
              </Typography>
              <Link
                to="/"
                style={{
                  color: "#4A7AF2",
                  textDecoration: "underline !importan",
                  textDecorationLine: "underline !important",
                }}
              >
                view all
              </Link>
            </Box>
            <Box>
              <Card className="card">
                <CardContent className="text-white">
                  <Typography variant="body2" gutterBottom>NIFTY</Typography>
                  <Typography variant="body2" mb="0px" mr={10}>
                    $ 27,794.40{" "}
                    <span style={{ color: "#ff2a2a" }}>-1.35(0.01%)</span>
                  </Typography>
                </CardContent>
              </Card>
            </Box>
          </Box>
          <IconButton sx={{ pl: "0px", marginTop: "50px" }}>
                <ExpandCircleDownOutlined
                  sx={{ rotate: "-90deg" }}
                ></ExpandCircleDownOutlined>
              </IconButton>
          </Box>
          {/* ............................................ Market Movers ................................................... */}
          <Box display='flex'>
          <Box mb={5} width='100%'>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box display="flex" gap={2} alignItems="center" mb={1}>
                <Typography
                  className="text-white"
                  mb={0}
                  style={{ fontSize: "1.5rem" }}
                >
                  {" "}
                  Market Movers
                </Typography>

                <FormControl>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={marketMoversType}
                    className="text-white"
                    sx={{
                      // color:'!important',
                      borderRadius: "9px !important",
                      borderColor: 'red !important',
                      padding: "0px !important",
                      '.MuiSvgIcon-root': {
                        color:'white !important'
                      }

                    }}
                    onChange={handleChangeMarketMovers}
                  >
                    <MenuItem value="gainers">Gainers</MenuItem>
                    <MenuItem value="losers">Losers</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Link
                to="/mainDashboard/marketMovers"
                style={{
                  color: "#4A7AF2",
                  textDecoration: "underline !importan",
                  textDecorationLine: "underline !important",
                }}
              >
                view all
              </Link>
            </Box>

            <Box mb={1}>
              <ToggleButtonGroup
                // className="border-white"
                // color="primary"
                value={marketMoversFilter}
                exclusive
                onChange={handleChangeMarketMoversFilter}
                aria-label="Platform"

              >
                <ToggleButton value="L" sx={{textTransform:'none'}}>Large</ToggleButton>
                <ToggleButton value="M" sx={{textTransform:'none'}}>Mid</ToggleButton>
                <ToggleButton value="S" sx={{textTransform:'none'}}>Small</ToggleButton>
              </ToggleButtonGroup>
            </Box>
            <Box display="flex" gap={"20px"}>
              {marketMoversData?.length === 0 && (
                <Card className="stock card" >
                  <CardContent className="text-white">
                    <div
                      style={{
                        width: "100%",
                        color: "red",
                        textAlign: "center",
                      }}
                    >
                      No Data
                    </div>
                  </CardContent>
                </Card>
              )}
              {marketMoversData?.length && marketMoversData.map((item) => {
                return (
                  <Card className="stock card">
                    <CardContent className="text-white">
                      <Box display="flex" alignItems="center" gap="10px" mb={1}>
                        <Avatar
                          alt={item.ticker}
                          src={item.branding.icon_url}
                          sx={{ width: 30, height: 30 }}
                        />
                        <Typography variant="body2" fontWeight={600} mb={0}>{item?.ticker}</Typography>
                      </Box>
                      <Typography variant="body1" fontWeight={600} mb="0px">$ {item.price?.toFixed(2)}</Typography>

                      <Typography variant="caption" mr={2} sx={{ color: item.todaysChange > 0 ? "#4edf6d" : "red" }}>
                        {item.todaysChange?.toFixed(2)}({item.todaysChangePerc?.toFixed(2)}%)
                      </Typography>
                    </CardContent>
                  </Card>
                )
              })}

            </Box>
          </Box>
          <IconButton sx={{ pl: "0px", marginTop: "50px" }}>
                <ExpandCircleDownOutlined
                  sx={{ rotate: "-90deg" }}
                ></ExpandCircleDownOutlined>
              </IconButton>
              </Box>
          {/* ................................................ Market Signals ................................................... */}
          <Box display='flex'>
          <Box mb={5} width='100%'>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box display="flex" gap={2} alignItems="center" mb={1}>
                <Typography
                  className="text-white"
                  mb={0}
                  style={{ fontSize: "1.5rem" }}
                >
                  {" "}
                  <SignalCellularAltIcon /> Market Signals
                </Typography>

                <FormControl>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select2"
                    value={marketsignalselect}
                    className="text-white"
                    sx={{
                      // color:'!important',
                      borderRadius: "9px !important",
                      padding: "0px !important",
                      '.MuiSvgIcon-root': {
                        color:'white !important'
                      }
                    }}
                    onChange={handleChangeMarketSingnalsSelect}
                  >
                    <MenuItem value="bullish" selected>Bulish</MenuItem>
                    <MenuItem value="bearish">Bearish</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Link
                to="/mainDashboard/marketSignals"
                style={{
                  color: "#4A7AF2",
                  textDecoration: "underline !importan",
                  textDecorationLine: "underline !important",
                }}
              >
                view all
              </Link>
            </Box>

            <Box mb={1}>
              <ToggleButtonGroup
                // className="border-white"
                // color="primary"
                value={marketSignalOpt}
                exclusive
                onChange={onMarketSignalFilterChange}
                aria-label="Platform"
              >
                {
                  marketSignalFilers[marketsignalselect]?.map((filter) => {
                    return (
                      <ToggleButton value={filter.key} sx={{textTransform:'none'}} >
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                          <Avatar
                            alt={filter?.name}
                            src={marketSignalOpt == filter.key ? filter?.selectedIconUrl : filter?.iconUrl}
                            sx={{ width: 20, height: 20, marginRight: "5px" }}
                          />
                          {filter.name}
                        </Box>
                      </ToggleButton>
                    )
                  })
                }
              </ToggleButtonGroup>

            </Box>
            <Box display="flex" gap={"20px"} >
              {marketSignalFilersData?.length === 0 && (
                <Card className="stock card" >
                  <CardContent className="text-white">
                    <div
                      style={{
                        width: "100%",
                        color: "red",
                        textAlign: "center",
                      }}
                    >
                      No Data
                    </div>
                  </CardContent>
                </Card>
              )}
              {
                marketSignalFilersData?.length && marketSignalFilersData.slice(0, 5).map((item) => {
                  return (
                    <Card className="stock card">
                      <CardContent className="text-white">
                        <Box display="flex" alignItems="center" gap="10px" mb={1}>
                          <Avatar
                            alt="Remy Sharp"
                            src={item.branding?.icon_url}
                            sx={{ width: 30, height: 30 }}
                          />
                          <Typography variant="body2" fontWeight={600} className="stock-label" mb={0}>{item.company}</Typography>
                        </Box>
                        <Typography variant="body1" fontWeight={600} mb="0px">${item.price}</Typography>
                        <Typography variant="caption" mr={2} sx={{ color: "#4edf6d" }}>
                          -1.35(0.01%)
                        </Typography>
                      </CardContent>
                    </Card>
                  )
                })
              }

            </Box>
          </Box>
          <IconButton sx={{ pl: "0px", marginTop: "50px" }}>
                <ExpandCircleDownOutlined
                  sx={{ rotate: "-90deg" }}
                ></ExpandCircleDownOutlined>
              </IconButton>
              </Box>
          {/* ...................................................My Picks .......................................................... */}
          <Box display='flex'>
          <Box mb={5} width='100%'>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"

            >
              <Box display="flex" gap={2} alignItems="center" mb={1}>
                <Typography
                  className="text-white"
                  mb={0}
                  style={{ fontSize: "1.5rem" }}
                >
                  My Picks
                </Typography>
                <FormControl>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select2"
                    value={myPicsType}
                    className="text-white"
                    sx={{
                      // color:'!important',
                      borderRadius: "9px !important",
                      padding: "0px !important",
                      '.MuiSvgIcon-root': {
                        color:'white !important'
                      }
                    }}
                    onChange={handleChangemypicks1}
                  >
                    <MenuItem value="STOCK">Stock</MenuItem>
                    <MenuItem value="ETF" selected>
                      ETF
                    </MenuItem>
                  </Select>
                </FormControl>
                <FormControl>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select2"
                    value={myPicsPreference}
                    className="text-white"
                    sx={{
                      // color:'!important',
                      borderRadius: "9px !important",
                      padding: "0px !important",
                      '.MuiSvgIcon-root': {
                        color:'white !important'
                      }
                    }}
                    onChange={handleChangeMyPicksPreference}
                  >
                    <MenuItem value="BUY">Buy</MenuItem>
                    <MenuItem value="SELL" selected>
                      Sell
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Link
                to="/mainDashboard/mypicks"
                style={{
                  color: "#4A7AF2",
                  textDecoration: "underline !importan",
                  textDecorationLine: "underline !important",
                }}
              >
                view all
              </Link>
            </Box>
            <Box mb={1}>
              <ToggleButtonGroup
                // className="border-white"
                // color="primary"
                value={myPicsOpt}
                exclusive
                onChange={handleChangeMyPicksFilter}
                aria-label="Platform"
              >
                <ToggleButton value="Live" sx={{textTransform:'none'}}>
                  Live
                </ToggleButton>
                <ToggleButton value="History" sx={{textTransform:'none'}}>
                  History
                </ToggleButton>

              </ToggleButtonGroup>
            </Box>
            <Box ref={scrollRef} display="flex" gap="20px" width={"100%"} overflow="auto"
            sx={{
              "&::-webkit-scrollbar": {
                height: "6px", // Adjust scrollbar height
                // marginTop:'10px !important'
                display:'none'
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "rgba(255,255,255,0.1)", // Scrollbar thumb color
                borderRadius: "4px", // Rounded scrollbar thumb
              },
              "&::-webkit-scrollbar-thumb:hover": {
                backgroundColor: "", // Thumb color on hover
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: "transparent", // Scrollbar track color
              },
            }}
            >
              {myPickData.length === 0 && (
                <Card className="stock card" >
                  <CardContent className="text-white">
                    <div
                      style={{
                        width: "100%",
                        color: "red",
                        textAlign: "center",
                      }}
                    >
                      No Data
                    </div>
                  </CardContent>
                </Card>
              )}
              {myPickData.length && myPickData.slice(0, 5).map((item) => {
                return (
                  <Card className="card" sx={{ minWidth: "300px" }}>
                    <CardContent
                      className="text-white"
                      style={{ display: "flex", alignItems: "center", gap: "40px" }}
                    >
                      <Box display="flex" alignItems="center" gap="10px" mb={1}>
                        <Avatar
                          alt="Remy Sharp"
                          src="/static/images/avatar/1.jpg"
                          sx={{ width: 30, height: 30 }}
                        />
                        <Typography variant="body2" fontWeight={600} mb={0} sx={{
                          overflow: "hidden",
                          display: "-webkit-box",
                          WebkitLineClamp: 2,
                          WebkitBoxOrient: "vertical",
                          textOverflow: "ellipsis",
                          whiteSpace: "normal",
                          fontWeight: "bold",
                        }}
                        >{item.displayName}</Typography>
                      </Box>
                      <Box>
                        <Typography variant="body1" fontWeight={600} mb="0px" textAlign="end">
                          $ {item.stockPrice}
                        </Typography>
                        <Typography variant="caption" mb={0} style={{ color: "#ff2a2a" }}>
                          -1.35(0.01%)
                        </Typography>
                      </Box>
                    </CardContent>
                  </Card>
                )
              })}

            </Box>
          </Box>
          <IconButton sx={{ pl: "0px", marginTop: "50px" }}>
                <ExpandCircleDownOutlined
                  sx={{ rotate: "-90deg" }}
                ></ExpandCircleDownOutlined>
              </IconButton>
              </Box>

          {/* ............................................ AI Picks ................................................... */}
          <Box display='flex'>
          <Box mb={5} width='100%'>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box display="flex" gap={2} alignItems="center" mb={1}>
                <Typography
                  className="text-white"
                  mb={0}
                  style={{ fontSize: "1.5rem" }}
                >
                  AI Picks
                </Typography>

                <FormControl>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={buyOrSell}
                    className="text-white"
                    sx={{
                      borderRadius: "9px !important",
                      padding: "0px !important",
                      '.MuiSvgIcon-root': {
                        color:'white !important'
                      }
                    }}
                    onChange={handleAiPickBuyOrSell}
                  >
                    <MenuItem value={Constant.BUY}>Buy</MenuItem>
                    <MenuItem value={Constant.SELL}>Sell</MenuItem>
                  </Select>
                </FormControl>
                <FormControl>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={stockOrETF}
                    className="text-white"
                    sx={{
                      borderRadius: "9px !important",
                      padding: "0px !important",
                      '.MuiSvgIcon-root': {
                        color:'white !important'
                      }
                    }}
                    onChange={handleStockOrETF}
                  >
                    <MenuItem value={Constant.STOCK}>Stock</MenuItem>
                    <MenuItem value={Constant.ETF}>ETF</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <NavLink
                to="/maindashboard/aipicks"
                style={{
                  color: "#4A7AF2",
                  textDecoration: "underline !important",
                  textDecorationLine: "underline !important",
                }}
              >
                view all
              </NavLink>
            </Box>

            <Box mb={1}>
              <ToggleButtonGroup
                value={selectedCategory}
                exclusive
                onChange={handleSelectedCategory}
                aria-label="Platform"
              >
                <ToggleButton value={Constant.POPULAR} sx={{textTransform:'none'}}>Popular</ToggleButton>
                <ToggleButton value={Constant.DIVIDENT_PAYING} sx={{textTransform:'none'}}>
                  Dividend Paying
                </ToggleButton>
                <ToggleButton value={Constant.GROWTH} sx={{textTransform:'none'}}>growth</ToggleButton>
                <ToggleButton value={Constant.FOLLOWING} sx={{textTransform:'none'}}>
                  Following
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
            <Box display="flex" gap="20px">
              {aiPicksList.length === 0 && (
                <Card className="stock card" >
                  <CardContent className="text-white">
                    <div
                      style={{
                        width: "100%",
                        color: "red",
                        textAlign: "center",
                      }}
                    >
                      No Data
                    </div>
                  </CardContent>
                </Card>
              )}

              {aiPicksList.map((item: any, index: any) => (
                <IconButton onClick={() => {
                  navigate("/maindashboard/stockdetail", {
                    state: {
                      symbol: item.symbol,
                      icon: item.branding?.logo_url ?? item.branding?.icon_url,
                      title: "AI Picks"
                    },
                  });
                }}>
                  <Card className="stock card" key={index}>
                    <CardContent className="text-white" sx={{display:'flex', flexDirection:'column'}}>
                      <Box display="flex" alignItems="center" gap="10px" mb={1}>
                        {item.icon ? (
                          <Avatar
                            className="stock-icon"
                            alt={item.icon}
                            src={item.icon}
                            sx={{
                              width: 30,
                              height: 30,
                              backgroundColor: "white",
                              objectFit: "fill",
                            }}
                          />
                        ) : (
                          <Box
                            sx={{
                              width: 30,
                              height: 30,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              backgroundColor: "#f0f0f0",
                              borderRadius: "50%",
                            }}
                          >
                            <Typography
                              // variant="h3"
                              sx={{
                                fontWeight: "bold",
                                color: "#212529",
                                fontSize: "14px",
                              }}
                            >
                              {item.symbol}
                            </Typography>
                          </Box>
                        )}

                        <Typography variant="body2" fontWeight={600} className="stock-label" mb={0}>
                          {item.displayName}
                        </Typography>
                      </Box>
                      <Typography variant="body1" textAlign='left' fontWeight='bold' mb="0px">
                        {/* $ {item.stockPrice?.toFixed(2)} */}
                        {item.stockPrice === null
                          ? "-"
                          : `$${item.stockPrice?.toFixed(2)}`}
                      </Typography>
                      <Typography
                        mr={2}
                        textAlign='left'
                        variant="caption"
                        sx={{ position:'relative', color: item.diffrence > 0 ? "#4edf6d" : "red" }}
                      >
                        {item.diffrence > 0 ? "+$" : "$"}
                        {item.res?.toFixed(2)}(?)
                      </Typography>
                    </CardContent>
                  </Card>
                </IconButton>
              ))}
              {/* <Card className="card">
                <CardContent className="text-white">
                  <Box display="flex" alignItems="center" gap="10px" mb={1}>
                    <Avatar
                      alt="Remy Sharp"
                      src="/static/images/avatar/1.jpg"
                      sx={{ width: 30, height: 30 }}
                    />
                    <Typography mb={0}>Strike</Typography>
                  </Box>
                  <Typography mb="0px">$ 27,794.40</Typography>
                  <Typography mr={2} sx={{ color: "#4edf6d" }}>
                    -1.35(0.01%)
                  </Typography>
                </CardContent>
              </Card> */}
            </Box>
          </Box>
          <IconButton sx={{ pl: "0px", marginTop: "50px" }}>
                <ExpandCircleDownOutlined
                  sx={{ rotate: "-90deg" }}
                ></ExpandCircleDownOutlined>
              </IconButton>
              </Box>

          {/* .............................................. Watch List.......................................................... */}
          <Box display='flex'>
          <Box mb={5} width='100%'>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography
                className="text-white"
                mb={2}
                style={{ fontSize: "1.5rem" }}
              >
                Watchlist
              </Typography>
              <NavLink
                to="/maindashboard/wachlist"
                style={{
                  color: "#4A7AF2",
                  textDecoration: "underline !importan",
                  textDecorationLine: "underline !important",
                }}
              >
                view all
              </NavLink>
            </Box>
            <Box display="flex" gap="20px">
              {watchList.length > 0 ? watchList.slice(0, 5).map((item: any, index: any) => (
                <Card className="stock card" key={index}>
                  <CardContent className="text-white">
                    <Box display="flex" alignItems="center" gap="10px" mb={1}>
                      {item.icon ? (
                        <Avatar
                          className="stock-icon"
                          alt={item.icon}
                          src={item.icon}
                          sx={{
                            width: 30,
                            height: 30,
                            backgroundColor: "white",
                            objectFit: "fill",
                          }}
                        />
                      ) : (
                        <Box
                          sx={{
                            width: 30,
                            height: 30,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            backgroundColor: "#f0f0f0",
                            borderRadius: "50%",
                          }}
                        >
                          <Typography
                            sx={{
                              fontWeight: "bold",
                              color: "#212529",
                              fontSize: "14px",
                            }}
                          >
                            {item.symbol}
                          </Typography>
                        </Box>
                      )}
                      <Typography variant="body2" fontWeight={600} className="stock-label" mb={0}>
                        {item.displayName}
                      </Typography>
                    </Box>
                    <Typography variant="body1" textAlign='left' fontWeight={600} mb="0px">
                      $ {item.stockPrice?.toFixed(2)}
                    </Typography>
                    <Typography variant="caption" textAlign='left'
                      mr={2}
                      sx={{ color: item.diffrence > 0 ? "#4edf6d" : "red" }}
                    >
                      {item.diffrence > 0 ? "+$" : "$"}
                      {item.res?.toFixed(2)}(?)
                    </Typography>
                  </CardContent>
                </Card>
              )) : <Watchlistcard></Watchlistcard>}
            </Box>

          </Box>
          <IconButton sx={{ pl: "0px", marginTop: "50px" }}>
                <ExpandCircleDownOutlined
                  sx={{ rotate: "-90deg" }}
                ></ExpandCircleDownOutlined>
              </IconButton>
              </Box>

          {/* ..................................................... Boosterloan.................................................... */}
          <Box display='flex'>
          <Box mb={5} width='100%'>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            // gap="20px"
            >
              <Typography
                className="text-white"
                variant="body2"
                fontWeight={600}
                mb={2}
                style={{ fontSize: "1.5rem" }}
              >
                Booster Loan
              </Typography>
              <Link
                to="/maindashboard/boosterloan"
                style={{
                  color: "#4A7AF2",
                  textDecoration: "underline !importan",
                  textDecorationLine: "underline !important",
                }}
              >
                details
              </Link>
            </Box>
            <Box display="flex" gap="20px">
            {boosterLoanRepaymentData.length === 0 && (
                <BoosterLoanCard />
              )}
              {boosterLoanRepaymentData?.map((item) => {
                return (
                  <Card className="card">
                    <CardContent className="text-white">
                      <Box>
                        <Box display="flex" gap="60px" mb={2}>
                          <Box display="flex" alignItems="center" gap="10px">
                            <Avatar
                              src={getLogoUrl(item.symbol)}
                              alt={item.symbol}
                              sx={{ width: 30, height: 30 }}
                            />
                            <Typography variant="body2"
                              fontWeight={600} mb={0}>{item.symbol}</Typography>
                          </Box>
                          <Button className="btn">upcoming</Button>
                        </Box>
                        <Box display="flex" justifyContent="space-between">
                          <Box>
                            <Typography variant="body2"
                              fontWeight={600} mb="0px">$ {item.amount?.toFixed(2)}</Typography>
                            {/* <Typography variant="body2"
                            fontWeight={600} mr={2} sx={{ color: "#4edf6d" }}>
                            -1.35(0.01%)
                          </Typography> */}
                          </Box>
                          <Box>
                            <Typography variant="body2" fontWeight={600} mb="0px">{moment(+item.due_date).format('DD-MMM-YYYY')}</Typography>
                            <Typography variant="caption" textAlign='end' mb={0}>{moment(+item.due_date).format('dddd')}</Typography>
                          </Box>
                        </Box>
                      </Box>
                      <Box></Box>
                    </CardContent>
                  </Card>
                )
              })}
            </Box>
            {/* <BoosterLoanCard></BoosterLoanCard> */}
          </Box>
          <IconButton sx={{ pl: "0px", marginTop: "50px" }}>
                <ExpandCircleDownOutlined
                  sx={{ rotate: "-90deg" }}
                ></ExpandCircleDownOutlined>
              </IconButton>
              </Box>
        </Box>

        {/* ...................................... Right Side Content ................................... */}
        <Container
          //
          style={{ width: "30%", paddingRight: "0px" }}
        >
          <Box display="flex" height="100%">
            <Box width='100%'>
              <Typography
                component="h1"
                fontSize={24}
                className="text-white"
                mb={2}
              >
                Your Investment
              </Typography>
              <Box className={isTradingAcExist ? "" : "d-none"}>
                <Yourinvestment />
              </Box>
              <Box
                className={isTradingAcExist ? "d-none" : ""}
                position="relative"
                width="100%"
                sx={{
                  background: `url(${createaccount})`,
                  backgroundSize: "100% 100%",
                  backgroundPosition: "center",
                }}
                height="40%"
                borderRadius="30px"
              >
                <Box
                  textAlign="center"
                  bottom="5%"
                  width="100%"
                  sx={{ position: "absolute" }}
                >
                  <Typography
                    variant="h6"
                    fontWeight="700"
                    className="text-white"
                    textAlign="center"
                  >
                    Don’t have Trading Account?
                  </Typography>
                  <Typography variant="body2" fontWeight={600} className="text-secondary">
                    Let’s Simplify your Trading Experience.
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      width: "90% !important",
                      marginTop: "40px",
                      paddingTop: "8px !important",
                      paddingBottom: "8px !important",
                      fontSize:'16px',
                      // paddingX:'0px 0px !important'
                    }}
                    className="btn-primary fw-700   btn"
                    
                    endIcon={
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M17.4399 14.62L19.9999 12.06L17.4399 9.5"
                          stroke="white"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M9.76001 12.0601H19.93"
                          stroke="white"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M11.76 20C7.34001 20 3.76001 17 3.76001 12C3.76001 7 7.34001 4 11.76 4"
                          stroke="white"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    }
                    // type="submit"
                    onClick={() => {
                      if (applicationStatus === Constant.CONTINUE) {
                        navigate("/maindashboard/createApplication");
                      } else {
                        setNoticeOpen(true);
                        createTradingAc();
                      }
                    }}
                  >
                    {applicationStatus === ""
                      ? "Create Now"
                      : applicationStatus}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Container>

        {/* <Container
          className={!isTradingAcExist ? "" : "d-none"}
          style={{ width: "30%", paddingTop: "20px" }}
        >
           <Box display="flex" height="100%">
            <Box width="85%">
              <Typography
                component="h1"
                fontSize={24}
                className="text-white"
                mb={2}
              >
                Your Investment
              </Typography> 
             
              <img
                height={"auto"}
                width={"100%"}
                style={{ borderRadius: "11px" }}
                src={require("../../../commom/Assets/images/createAc.jpg")}
              /> 
             <Button
                variant="contained"
                color="primary"
                fullWidth
                className="btn-primary fw-700 fs-18  btn mb-10"
                endIcon={
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.4399 14.62L19.9999 12.06L17.4399 9.5"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9.76001 12.0601H19.93"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M11.76 20C7.34001 20 3.76001 17 3.76001 12C3.76001 7 7.34001 4 11.76 4"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                }
                // type="submit"
                onClick={() => {
                  if (applicationStatus === Constant.CONTINUE) {
                    navigate("/maindashboard/createApplication");
                  } else {
                    setNoticeOpen(true);
                    createTradingAc();
                  }
                }}
              >
                {applicationStatus === "" ? "Create Now" : applicationStatus}
              </Button> 
             </Box> 
           </Box> 
        </Container> */}
      </Container >

      <Dialog
        open={isDialogOpen}
        onClose={() => {
          setDialogOpen(false);
        }}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        // className="bg-dark"
        sx={{
          '&.MuiDialog-Paper': {
            bgcolor: 'red !important'
          }
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
            marginTop: "20px",
            marginRight: "30px",
          }}
        >
          <IconButton
            onClick={() => {
              setDialogOpen(false);
            }}
          >
            <svg
              width="36"
              height="36"
              viewBox="0 0 36 36"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18 33C26.25 33 33 26.25 33 18C33 9.75 26.25 3 18 3C9.75 3 3 9.75 3 18C3 26.25 9.75 33 18 33Z"
                stroke="white"
                stroke-width="2.25"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M13.7549 22.2449L22.2449 13.7549"
                stroke="white"
                stroke-width="2.25"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M22.2449 22.2449L13.7549 13.7549"
                stroke="white"
                stroke-width="2.25"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </IconButton>
        </Box>

        <DialogContent
          sx={{ padding: "0px 40px 30px 40px" }}
          className={isNoticeOpen ? "" : "d-none"}
        >
          <TradingACNotice />
        </DialogContent>
        <DialogContent
          sx={{ padding: "0px 40px 30px 40px" }}
          className={isDisclosureOpen ? "" : "d-none"}
        >
          <TradingACDisclosure />
        </DialogContent>
        <DialogActions
          sx={{ justifyContent: "center", paddingBottom: "30px" }}
          className={isNoticeOpen ? "" : "d-none"}
        >
          <Button
            onClick={() => {
              setDialogOpen(false);
            }}
            variant="outlined"
            className="btn btn-outline-primary"
            sx={{
              padding: "10px 40px !important",
              borderRadius: "10px",
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              setNoticeOpen(false);
              setDisclosureOpen(true);
            }}
            variant="contained"
            className="btn btn-primary"
            sx={{ padding: "10px 40px !important", borderRadius: "10px" }}
          >
            Proceed
          </Button>
        </DialogActions>

        <DialogActions
          sx={{ justifyContent: "center", paddingBottom: "30px" }}
          className={isDisclosureOpen ? "" : "d-none"}
        >
          <Button
            onClick={() => {
              setNoticeOpen(false);
              setDisclosureOpen(false);
              setDialogOpen(false);
              navigate("/maindashboard/createTradingBlockUser");
            }}
            className="btn btn-primary"
            sx={{ padding: "10px 40px !important", borderRadius: "10px" }}
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Dashboard;
