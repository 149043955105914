import React from "react";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts"; // Import ApexOptions type

interface TotalFundsProps {
  series: number[];
  labels: string[];
}

const DonutChart: React.FC<TotalFundsProps> = ({ series, labels }) => {
  const options: ApexOptions = {
    chart: {
      type: "donut", 
    },
    stroke: { show: false, width: 0 },
    legend: { show: false },
    dataLabels: { enabled: false },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            name: { show: true },
            value: {
              show: true,
              formatter: (val: string) => `${val}`, 
            },
            total: {
              show: true,
              label: "Total Funds",
              formatter: (w: any) => {
                const total = w.globals.seriesTotals.reduce(
                  (a: number, b: number) => a + b,
                  0
                );
                return `$${total.toLocaleString()}`;
              },
            },
          },
        },
      },
    },
    tooltip: {
      enabled: true,
      theme: "dark",
      y: {
        formatter: (value: number, opts: any) => {
          const label = labels[opts.seriesIndex];
          return `${value}`; 
        },
      },
    },
    labels: labels, // Assign labels to the chart
  };

  return (
    <div className="donut">
      <Chart options={options} series={series} type="donut" width="300" />
    </div>
  );
};

export default DonutChart;
