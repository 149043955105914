import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Box,
  IconButton,
  Divider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CelebrationIcon from "@mui/icons-material/Celebration"; // Confetti-like icon
import OrderSuccessCard from "../Cards/OrderSuccesscard";

// Type definition for order details
interface OrderDetails {
  stockName: string;
  orderId: string;
  dateTime: string;
  estimatedCash: string;
  boosterLoan: string;
  totalQty: number;
  totalAmount: string;
}

// Props for the component
interface OrderSuccessPopupProps {
  open: boolean;
  onClose: () => void;
  orderDetails: OrderDetails;
}

const OrderSuccessPopup: React.FC<any> = ({ open, onClose, orderDetails }) => {


  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <Box sx={{ position: "relative", textAlign: "center", p: 2 }}>
        <DialogTitle>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon sx={{background:"black"}}/>
          </IconButton>
        </DialogTitle>
         <OrderSuccessCard></OrderSuccessCard>
      </Box>
      <Divider />
   
    </Dialog>
  );
};

export default OrderSuccessPopup;
