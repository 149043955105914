import React, { useEffect, useState } from 'react';
import {
    Card,
    Box,
    CardContent,
    Typography,
    FormControl,
    MenuItem,
    Select,
    SelectChangeEvent
} from '@mui/material';
import TotalFunds from '../charts/TotalFund';
import { CCarousel, CCarouselItem } from '@coreui/react';
import { getUserFromLocalStorage } from '../../../Utils/utils';
import Axios from '../../../config/axios';
import { UrlConstant } from '../../../commom/UrlConstant';
import DonutChart from '../charts/DonutChart';
// import { CarouselItem } from 'react-bootstrap';

function Yourinvestment() {

    const user = getUserFromLocalStorage();

    const [age, setAge] = useState<any>('ms1');
    const [loading, setLoading] = useState<any>(false);
    const [tradingInfo, setTradingInfo] = useState<any>();
    const [portfolioData, setPortfolioData] = useState<any>()
    const [portfolioListData, setPortfolioListData] = useState<any>()
    const [investmentChart, setInvestmentChart] = useState<any>()

    const [stockChart, setStockChart] = useState<any>()
    const [stockChartLabel, setStockLabel] = useState<any>()

    const [ETFChart, setETFChart] = useState<any>()
    const [ETFChartLabel, setETFLabel] = useState<any>()






    const handleChange = (event: SelectChangeEvent) => {
        setAge(event.target.value as string);
    };


    const totalcards = [
        { heading: "Investment Portfolio", data: investmentChart, label: ["Stocks", "Cash", "ETFs"] },
        { heading: "Stocks", Selectoptions: ["Assets", "Sector"], data: stockChart, label: stockChartLabel },
        { heading: "ETFs", Selectoptions: ["Assets", "Discovery"], data: ETFChart, label: ETFChartLabel }
    ];

    const convertToPercentage = (data: number[]) => {
        const total = data?.reduce((acc, value) => acc + value, 0);
        const percentages = data?.map((value) => (total === 0 ? 0 : (value / total) * 100));

        // Round each percentage to two decimal places
        const roundedPercentages = percentages?.map((percentage) => parseFloat(percentage.toFixed(2)));

        // Adjust rounding errors so the total adds up to 100
        const sum = roundedPercentages?.reduce((acc, percentage) => acc + percentage, 0);
        const difference = 100 - sum;

        // Add the difference to the first element to ensure the total is 100
        if (difference !== 0) {
            roundedPercentages[0] += difference;
        }

        return roundedPercentages;
    };


    const fetchTradingBalance = async () => {
        // check-trading-balance
        setLoading(true)
        const data = {
            "accountId": user?.tradingblock_application_id
        }
        try {
            const response = await Axios.post(
                UrlConstant.BuySell.GetTradingBalance, data)

            setTradingInfo(response.data?.data?.Payload)
        } catch (error: any) {
            console.error("ERRR : ", error);
        } finally {
            setLoading(false)
        }
    }
    const fetchPortfolioData = async () => {
        setLoading(true)
        const data = {
            "accountId": user?.tradingblock_application_id
        }
        try {
            const response = await Axios.post(
                UrlConstant.Portfolio.GetPortfolio, data)

            setPortfolioData(response.data?.data)
            setPortfolioListData(response.data?.data?.entries)
        } catch (error: any) {
            console.error("ERRR : ", error);
        } finally {
            setLoading(false)
        }
    }


    useEffect(() => {
        fetchTradingBalance()
        fetchPortfolioData()
    }, [])

    useEffect(() => {
        console.log(tradingInfo, "tradingInfo", portfolioData)
        const cash = tradingInfo?.Balances?.CashBalance
        const stock = portfolioData?.stockChart?.stockTotal
        const ETF = portfolioData?.etfChart?.etfTotal
        const data = [cash, stock, ETF]
        const finalDataPer = data?.length && convertToPercentage(data)
        setInvestmentChart(finalDataPer)
    }, [tradingInfo, portfolioData])

    useEffect(() => {
        let data = portfolioData?.["stockChart"].chartDataTest
        const finalData = data?.map((item) => item.amount)
        const finalDataLabel = data?.map((item) => item.symbol)
        const finalDataPer = finalData?.length && convertToPercentage(finalData)
        setStockChart(finalDataPer)
        setStockLabel(finalDataLabel)
    }, [portfolioData])

    useEffect(() => {
        let data = portfolioData?.["etfChart"].chartDataTest
        const finalData = data?.map((item) => item.amount)
        const finalDataLabel = data?.map((item) => item.symbol)
        const finalDataPer = finalData?.length && convertToPercentage(finalData)
        setETFChart(finalDataPer)
        setETFLabel(finalDataLabel)
    }, [portfolioData])

    return (
        <Card className="card" sx={{ width: '100%', paddingBottom: '0px' }}>
            <CardContent sx={{ paddingBottom: '0px' }}>
                <CCarousel controls indicators interval={false}
                >
                    {totalcards.map((card, index) => (
                        <CCarouselItem key={index} style={{ color: 'white', padding: '0px 10px' }}>
                            <Box display='flex' alignItems='center' justifyContent='space-between'>
                                <Typography className="text-white" variant="h6">
                                    {card.heading}
                                </Typography>
                                <Box display='flex' alignItems='center' justifyContent='space-between'>

                                    {/* Conditional rendering for Select options */}
                                    {card.Selectoptions && (
                                        <FormControl >
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={age}
                                                className="text-white"
                                                sx={{
                                                    borderRadius: '9px !important',
                                                    padding: '0px !important',
                                                    '.MuiSvgIcon-root': {
                                                        color:'white !important'
                                                      }

                                                }}
                                                onChange={handleChange}
                                            >
                                                {card.Selectoptions.map((option, idx) => (
                                                    <MenuItem key={idx} value={`ms${idx}`}>
                                                        {option}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    )}
                                </Box>
                            </Box>
                            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">

                                {/* TotalFunds Chart Component */}
                                <Box display="flex" justifyContent="center" alignItems="center" margin='30px 40px 0px 40px'>
                                    <DonutChart
                                        series={card?.data?.length ? card?.data : []}
                                        labels={card?.label?.length ? card?.label : []}
                                    />
                                </Box>
                            </Box>
                        </CCarouselItem>
                    ))}
                </CCarousel>

                {/* Bottom Section: Cash, Stocks, and ETFs */}
                <Box display="flex" width="100%" justifyContent="space-between" mt={2}>
                    <Box p={3} width="34%" borderRight="1px solid #666666" borderTop="1px solid #666666" justifyContent="center">
                        <Typography fontSize="12px" className="text-secondary" textAlign="center" gutterBottom>Cash</Typography>
                        <Typography fontSize="16px" className="text-white" textAlign="center">$ {tradingInfo?.Balances?.CashBalance?.toFixed(2)}</Typography>
                    </Box>
                    <Box p={3} width="34%" borderRight="1px solid #666666" borderTop="1px solid #666666" justifyContent="center">
                        <Typography fontSize="12px" className="text-secondary" textAlign="center" gutterBottom>Stocks</Typography>
                        <Typography fontSize="16px" className="text-white" textAlign="center">$ {portfolioData?.stockChart?.stockTotal?.toFixed(2)}</Typography>
                    </Box>
                    <Box p={3} width="32%" borderTop="1px solid #666666" justifyContent="center">
                        <Typography fontSize="12px" className="text-secondary" textAlign="center" gutterBottom>ETFs</Typography>
                        <Typography fontSize="16px" className="text-white" textAlign="center">$ {portfolioData?.etfChart?.etfTotal?.toFixed(2)}</Typography>
                    </Box>
                </Box>
            </CardContent>
        </Card>
    );
}

export default Yourinvestment;
