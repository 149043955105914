import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "../config/axios";
import { endBlocking, startBlocking } from "./loadingSlice";

// Thunk to handle the API call
export const fetchNotifications = createAsyncThunk(
  "notifications/fetchNotifications",
  async (_, { dispatch }) => {
    dispatch(startBlocking());
    try {
      const response = await Axios.get("/notifications");
      const resData = response.data;
      return resData;
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(endBlocking());
    }
  }
);

export const deleteNotifications = createAsyncThunk(
  "notifications/deleteNotifications",
  async (id, { dispatch, rejectWithValue }) => {
    try {
      await Axios.delete(`/notifications/${id}`);
      return id;
    } catch (error: any) {
      console.error(error);
      return rejectWithValue(error.message); // Return error to handle it in the UI if necessary
    }
  }
);

export const updateNotificationCount = createAsyncThunk(
  "notifications/updateNotificationCount",
  async () => {
    try {
      const data = await Axios.get(`/notifications/update-count`);
      return data;
    } catch (error) {
      console.error(error);
    } finally {
    }
  }
);

const notificationSlice = createSlice({
  name: "notifications",
  initialState: {
    data: [],
    loading: false,
    error: null,
    unreadCount: 0,
  },
  reducers: {
    // Other non-async reducers if necessary
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchNotifications.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchNotifications.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.notifications;
        state.unreadCount = action.payload.unreadCount;
      })
      .addCase(fetchNotifications.rejected, (state: any, action: any) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteNotifications.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteNotifications.fulfilled, (state, action) => {
        state.loading = false;
        state.data = state.data.filter(
          (notification: any) => notification.id !== action.payload
        );
      })
      .addCase(deleteNotifications.rejected, (state: any, action) => {
        state.loading = false;
        state.error = action.error.message;
      });

    builder
      .addCase(updateNotificationCount.pending, (state) => {
        state.error = null;
      })
      .addCase(updateNotificationCount.fulfilled, (state) => {
        state.unreadCount = 0;
      })
      .addCase(updateNotificationCount.rejected, (state: any, action) => {
        state.error = action.payload;
      });
  },
});

export default notificationSlice.reducer;
