import {
  Box,
  Button,
  Container,
  FormControl,
  IconButton,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import ReactFlagsSelect from "react-flags-select";
import "../../style/style.css";

import { styled } from "@mui/system";
import { useFormik } from "formik";
import PhoneInput, { CountryData } from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { UrlConstant } from "../../commom/UrlConstant";
import Axios from "../../config/axios";
import LeftSide from "../common/Layout/LeftSide";

interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  country: string;
  countryCode: string;
  phoneNumber: string;
  referral_code: string;
}

const RightSection = styled(Box)(({ theme }) => ({
  width: "50%",
  // padding: "4rem",
  // height:'fit-content',
  display: "flex",
  padding: "70px 120px",
  flexDirection: "column",
  // justifyContent: "center",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
  [theme.breakpoints.down("lg")]: {
  },
}));

const Signup: React.FC = () => {
  const navigation = useNavigate();

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [submitForm, setSubmitForm] = useState<boolean>(false);
  const [strength, setStrength] = useState<string>("");
  const [strong, setStrong] = useState<number>(0);

  const initialValues: FormValues = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    country: "",
    countryCode: "",
    phoneNumber: "",
    referral_code: "",
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Enter a valid format")
      .required("Enter Your Email"),
    password: Yup.string()
      .min(6, "Password should be at least 6 characters long")
      .required("Enter a Valid Password"),
    phoneNumber: Yup.string()
      // .max(10, "Enter a valid 10-digit phone number")
      .min(10, "Enter a valid  phone number")
      .required("Enter Your Phone no"),
    referral_code: Yup.string().optional(),
    firstName: Yup.string().required("Enter FirstName"),
    lastName: Yup.string().required("Enter LastName"),
  });

  const formik = useFormik<FormValues>({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      setSubmitForm(true);
      signUp(values);
      setSubmitForm(false);
    },
  });

  const signUp = async (values: any) => {
    try {
      const response = await Axios.post(UrlConstant.Auth.SignUp, values);

      const resData = response.data;
      if (resData.result === true) {
        navigation("/otp", { state: { email: values.email } });
      } else {
        Swal.fire({
          title: "Failed",
          text: resData.msg,
          icon: "error",
        });
      }
    } catch (error: any) {
      console.error("ERRR : ", error);
      Swal.fire({
        title: "Failed",
        text: error.response.data.msg,
        icon: "error",
      });
    } finally {
    }
  };

  const keyup = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.code === "Enter") {
      formik.handleSubmit();
    }
  };

  const passwordstrength = () => {
    const pass = formik.values.password;
    if (pass.match(/(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])/)) {
      setStrength("strong");
      setStrong(100);
    } else if (pass.match(/(?=.*[0-9])(?=.*[a-zA-Z])/)) {
      setStrength("medium");
      setStrong(66);
    } else if (pass.match(/(?=.*[a-zA-Z])/)) {
      setStrength("weak");
      setStrong(33);
    } else {
      // setStrength("weak");
      setStrong(0);
    }
  };

  const handlePhoneChange = (value: string, data: CountryData) => {
    formik.setFieldValue("phoneNumber", value);
    formik.setFieldValue("countryCode", data.dialCode);
  };

  return (
    <Container
      maxWidth={false}
      className="container"
      sx={{ margin: "0px", maxWidth: "fit-content" }}
    >
      <LeftSide></LeftSide>

      <RightSection overflow="auto" className="RightSection bg-color">
        <Typography
          variant="h5"
          m={0}
          p={0}
          className="Typography fw-700 mb-10  heading"
        >
          Join the Journey
        </Typography>
        <Typography
          m={0}
          mb="0px !important"
          className="Typography  text-secondary"
          // variant="body1"
          sx={{ fontSize: '18px', mb: { xs: 7, sm: 10 } }}
        >
          Start exploring endless possibilities with just a few clicks.
        </Typography>

        <form onSubmit={formik.handleSubmit}>
          <Box display="flex" flexWrap="wrap" justifyContent="space-between">
            <FormControl
              className="mb-20"
              sx={{
                width: {
                  xs: "100%", // full width on extra small screens
                  // sm: "75%", // 75% width on small screens
                  // md: "50%", // 50% width on medium screens
                  lg: "48%", // 48% width on large screens and up
                },
              }}
            >
              <TextField
                // name="FullName"
                // label="FirstName"
                label={
                  <span>
                    FirstName{" "}
                    <sup>
                      <i
                        className="fa-solid fa-star-of-life"
                        style={{ color: "#fa0000", fontSize: "0.5rem" }}
                      ></i>
                    </sup>
                  </span>
                }
                variant="outlined"
                className="text-secondary"
                {...formik.getFieldProps("firstName")}
                onKeyUp={keyup}
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor:
                      formik.errors.firstName && submitForm
                        ? "red !important"
                        : "",
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <IconButton>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M12 12C9.23858 12 7 9.76142 7 7C7 4.23858 9.23858 2 12 2C14.7614 2 17 4.23858 17 7C17 9.76142 14.7614 12 12 12Z"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M20.5899 22C20.5899 18.13 16.7399 15 11.9999 15C7.25991 15 3.40991 18.13 3.40991 22"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </IconButton>
                  ),
                }}
                InputLabelProps={{
                  shrink: false,

                }}
              />
              {submitForm ? (
                <Typography className="error">
                  {formik.errors.firstName}
                </Typography>
              ) : null}
            </FormControl>
            <FormControl
              className="mb-20"
              sx={{
                width: {
                  xs: "100%", // full width on extra small screens

                  lg: "48%", // 48% width on large screens and up
                },
                mt: {
                  xs: "20px",
                  lg: "0px",
                },
              }}
            >
              <TextField
                // name="FullName"
                // label="LastName"
                label={
                  <span>
                    LastName{" "}
                    <sup>
                      <i
                        className="fa-solid fa-star-of-life"
                        style={{ color: "#fa0000", fontSize: "0.5rem" }}
                      ></i>
                    </sup>
                  </span>
                }
                variant="outlined"
                className="text-secondary"
                {...formik.getFieldProps("lastName")}
                onKeyUp={keyup}
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor:
                      formik.errors.lastName && submitForm
                        ? "red !important"
                        : "",
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <IconButton>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M12 12C9.23858 12 7 9.76142 7 7C7 4.23858 9.23858 2 12 2C14.7614 2 17 4.23858 17 7C17 9.76142 14.7614 12 12 12Z"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M20.5899 22C20.5899 18.13 16.7399 15 11.9999 15C7.25991 15 3.40991 18.13 3.40991 22"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </IconButton>
                  ),
                }}
                InputLabelProps={{
                  shrink: false,
                }}
              />
              {submitForm ? (
                <Typography className="error">
                  {formik.errors.lastName}
                </Typography>
              ) : null}
            </FormControl>
          </Box>
          <FormControl fullWidth margin="normal" className="mb-20">
            <TextField
              label={
                <span>
                  Email ID{" "}
                  <sup>
                    <i
                      className="fa-solid fa-star-of-life"
                      style={{ color: "#fa0000", fontSize: "0.5rem" }}
                    ></i>
                  </sup>
                </span>
              }
              variant="outlined"
              className="text-secondary"
              {...formik.getFieldProps("email")}
              onKeyUp={keyup}
              sx={{
                " & .MuiOutlinedInput-notchedOutline": {
                  borderColor: `${formik.errors.email && submitForm ? "red !important" : " "
                    }`,
                },
              }}
              slotProps={{
                input: {
                  startAdornment: (
                    <IconButton sx={{ background: "transparent" }}>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7 20.5C4 20.5 2 19 2 15.5V8.5C2 5 4 3.5 7 3.5H17C20 3.5 22 5 22 8.5V15.5C22 19 20 20.5 17 20.5H7Z"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M17 9L13.87 11.5C12.84 12.32 11.15 12.32 10.12 11.5L7 9"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </IconButton>
                  ),
                },
              }}
              InputLabelProps={{
                shrink: false,
              }}
            />
            {submitForm ? (
              <Typography className="error">{formik.errors.email}</Typography>
            ) : null}
          </FormControl>

          <FormControl fullWidth margin="normal" className="mb-20">
            <TextField
              // name="Password"
              label={
                <span>
                  Password{" "}
                  <sup>
                    <i
                      className="fa-solid fa-star-of-life"
                      style={{ color: "#fa0000", fontSize: "0.5rem" }}
                    ></i>
                  </sup>
                </span>
              }
              type={showPassword ? "text" : "password"}
              variant="outlined"
              className="text-secondary "
              size="medium"
              {...formik.getFieldProps("password")}
              onKeyUp={(e: any) => {
                passwordstrength();
                keyup(e);
              }}
              sx={{
                " & .MuiOutlinedInput-notchedOutline": {
                  borderColor: `${formik.errors.password && submitForm
                      ? "red !important"
                      : " "
                    } `,
                },
              }}
              InputProps={{
                startAdornment: (
                  <IconButton>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6 10V8C6 4.69 7 2 12 2C17 2 18 4.69 18 8V10"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M7 22C3 22 2 21 2 17V15C2 11 3 10 7 10H17C21 10 22 11 22 15V17C22 21 21 22 17 22H7Z"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M15.9965 16H16.0054"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M11.9955 16H12.0045"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M7.99451 16H8.00349"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </IconButton>
                ),
                endAdornment: (
                  <IconButton onClick={() => setShowPassword(!showPassword)}>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.47004 14.53C8.82004 13.88 8.42004 12.99 8.42004 12C8.42004 10.02 10.02 8.42004 12 8.42004C12.99 8.42004 13.88 8.82004 14.53 9.47004L9.47004 14.53Z"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M17.82 5.76998C16.07 4.44998 14.07 3.72998 12 3.72998C8.46997 3.72998 5.17997 5.80998 2.88997 9.40998C1.98997 10.82 1.98997 13.19 2.88997 14.6C3.67997 15.84 4.59997 16.91 5.59997 17.77"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M8.42004 19.5301C9.56004 20.0101 10.77 20.2701 12 20.2701C15.53 20.2701 18.82 18.1901 21.11 14.5901C22.01 13.1801 22.01 10.8101 21.11 9.40005C20.78 8.88005 20.42 8.39005 20.05 7.93005"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M15.5099 12.7C15.2499 14.11 14.0999 15.26 12.6899 15.52"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M9.47 14.53L2 22"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M22 2L14.53 9.47"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </IconButton>
                ),
              }}
              InputLabelProps={{
                shrink: false,
              }}
            />
            <Box
              component="div" // Specify the component type
              sx={{
                width: "100%", // Use 'width' instead of 'fullWidth'
                display: "flex", // Set display as flex
                mt: '10px', // Margin top
                mb: '10px', // Margin bottom
                justifyContent: "space-between", // Space items evenly
              }}
            >
              <style>{`
 
                    progress::-webkit-progress-value {
                    background-color: ${strong === 33 ? "red" : strong === 66 ? "yellow" : ""
                     } ; /* Color of the progress bar */
                       border-radius: 10px;
                                }
                  `}</style>
              <progress
                value={strong}
                max="25"
                className="progress border-50"
                style={{}}
              ></progress>
              <progress
                value={strong - 25}
                max="25"
                className="progress border-50"
              ></progress>
              <progress
                value={strong - 50}
                max="25"
                className="progress border-50"
              ></progress>
              <progress
                value={strong - 75}
                max="25"
                className="progress border-50"
              ></progress>
            </Box>
            <Typography className="text-secondary">
              Password Strength : {strength}
            </Typography>
            {submitForm ? (
              <Typography className="error">
                {formik.errors.password}
              </Typography>
            ) : null}
          </FormControl>

          {/* <FormControl fullWidth margin="normal" className="">
            <InputLabel className="country fs-18 text-secondary" shrink={false}>
              Country
            </InputLabel>
            <ReactFlagsSelect
              selected={formik.values.country}
              searchable
              onSelect={(code: any) => formik.setFieldValue("country", code)}
              className="custom-select"
            />
          </FormControl> */}

          <FormControl fullWidth margin="normal" className="mb-20" sx={{'& .MuiInputBase-root':{height:'48px !important'}}}>
            <InputLabel className="country  text-secondary" sx={{fontSize:'14px !important'}} shrink={false}>
              <span>
                Country{" "}
                <sup>
                  <i
                    className="fa-solid fa-star-of-life"
                    style={{ color: "#fa0000", fontSize: "0.5rem" }}
                  ></i>
                </sup>
              </span>
            </InputLabel>
            {/* 
            <Select
              labelId="country-label"
              id="Country"
              name="country"
              //  label="Country"
              value={formik.values.country} // Controlled value from Formik state
              onChange={(e: any) => {
                const selectedCountry: any = countryList.find(
                  (country: any) => country.name === e.target.value
                );
                if (selectedCountry) {
                  formik.setFieldValue("country", selectedCountry.name);
                }
              }}
              sx={{
                color: "white !important",
                "& .MuiSvgIcon-root": {
                  color: "white",
                },
              }}
            >...........................
              {countryList.map((country: any) => (
                <MenuItem key={country._id} value={country.name}>
                  <div
                    className="d-flex"
                    style={{ gap: "3px", paddingLeft: "5px" }}
                  >
                    <img src={country.flag} style={{ width: "20px" }} />
                    {country.name}
                  </div>
                </MenuItem>
              ))}
            </Select> */}{" "}
            <ReactFlagsSelect
              selected={formik.values.country || "AU"}
              searchable
              onSelect={(code: any) => formik.setFieldValue("country", code)}
              className="custom-select"
            />
          </FormControl>

          <FormControl fullWidth margin="normal" className="mb-20">
            <InputLabel className="country  text-secondary" shrink={false}>
              <span>
                Mobile{" "}
                <sup>
                  <i
                    className="fa-solid fa-star-of-life"
                    style={{ color: "#fa0000", fontSize: "0.5rem" }}
                  ></i>
                </sup>
              </span>
            </InputLabel>
            <PhoneInput
              value={formik.values.phoneNumber}
              onChange={handlePhoneChange}
              inputStyle={{
                borderColor:
                  submitForm && formik.errors.phoneNumber ? "red" : '#a3a3a3',
                borderWidth: "1px",
                borderStyle: "solid",
                borderRadius: "50px",
                height: "48px",
                width: "99%",
                outline: "none",
                boxShadow: "none",
                fontSize: "16px",
                background: "transparent",
                color: "white !important",
                
              }}
              country={formik.values.country.toLowerCase() || "au"}
              //  onlyCountries={['us']}
              disableDropdown={true}
              containerClass="phone-input-custom text-secondary "
            />
            {submitForm && formik.errors.phoneNumber && (
              <Typography className="error">
                {formik.errors.phoneNumber}
              </Typography>
            )}
          </FormControl>

          <FormControl fullWidth margin="normal" className="mb-20" sx={{marginTop:'20px'}}>
            <TextField
              // name="RefCode"

              label={
                <span>
                  Referral Code (Optional){" "}
                  <sup>
                    <i
                      className="fa-solid fa-star-of-life"
                      style={{ color: "#fa0000", fontSize: "0.5rem" }}
                    ></i>
                  </sup>
                </span>
              }
              variant="outlined"
              className="text-secondary "
              {...formik.getFieldProps("referral_code")}
              onKeyUp={keyup}
              sx={{
                " & .MuiOutlinedInput-notchedOutline": {
                  borderColor: `${formik.errors.referral_code && submitForm
                      ? "red !important"
                      : " "
                    } `,
                },
              }}
              InputProps={{
                startAdornment: (
                  <IconButton>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.02 19.5H7.5C6.88 19.5 6.33 19.48 5.84 19.41C3.21 19.12 2.5 17.88 2.5 14.5V9.5C2.5 6.12 3.21 4.88 5.84 4.59C6.33 4.52 6.88 4.5 7.5 4.5H10.96"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M15.02 4.5H16.5C17.12 4.5 17.67 4.52 18.16 4.59C20.79 4.88 21.5 6.12 21.5 9.5V14.5C21.5 17.88 20.79 19.12 18.16 19.41C17.67 19.48 17.12 19.5 16.5 19.5H15.02"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M15 2V22"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M11.0945 12H11.1035"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M7.09448 12H7.10346"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </IconButton>
                ),
              }}
              InputLabelProps={{
                shrink: false,
              }}
            />
            {submitForm ? (
              <Typography className="error">
                {formik.errors.referral_code}
              </Typography>
            ) : null}
          </FormControl>

          <Button
            variant="contained"
            color="primary"
            fullWidth
            className="btn-primary fw-700 btn mb-10"
            sx={{fontSize:'16px'}}
            endIcon={
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.4399 14.62L19.9999 12.06L17.4399 9.5"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M9.76001 12.0601H19.93"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M11.76 20C7.34001 20 3.76001 17 3.76001 12C3.76001 7 7.34001 4 11.76 4"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            }
            type="submit"
            onClick={() => setSubmitForm(true)}
          >
            Sign Up
          </Button>
        </form>

        <Typography
          sx={{ mt:'36px' }}
          className="text-secondary"
          textAlign="center"
        >
          Already have an account? &nbsp;{" "}
          <Link to="/login" className="link">
            Log In
          </Link>
        </Typography>
      </RightSection>
    </Container>
  );
};

export default Signup;
