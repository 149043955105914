import {
  Box,
  Button,
  Container,
  FormControl,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import { Form, Formik, FormikHelpers, useFormik } from "formik";
import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { UrlConstant } from "../../commom/UrlConstant";
import Axios from "../../config/axios";
import "../../style/style.css";
import LeftSide from "../common/Layout/LeftSide";

interface FormValues {
  Password: string;
  Confirm_Password: string;
  otp: string;
}

function Reset() {
  const [showPassword, setshowPassword] = useState(false);
  const [submitform, setsubmitform] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const userEmail = location.state?.email;
  const email = userEmail;

  const initialValues: FormValues = {
    Password: "",
    Confirm_Password: "",
    otp: "",
  };

  const onSubmit = (values: FormValues, actions: FormikHelpers<FormValues>) => {
    resetPasswordWithOTP(values);
    actions.setSubmitting(false);
  };

  const resetPasswordWithOTP = async (values) => {
    const payLoad = {
      email: email,
      code: values.otp,
      newPassword: values.Password,
    };
    try {
      const response = await Axios.post(
        UrlConstant.Auth.ResetPassword,
        payLoad
      );

      const resData = response.data;
      if (resData.result === true) {
        navigate("/login");
      } else {
        Swal.fire({
          title: "Failed",
          text: resData.msg,
          icon: "error",
        });
      }
    } catch (error: any) {
      console.error(error.response.data.msg);
      Swal.fire({
        title: "Failed",
        text: error.response.data.msg,
        icon: "error",
      });
    }
  };
  const validationSchema = Yup.object({
    Password: Yup.string()
      .min(8, "Password must be at least 8 characters long")
      .required("Password is required"),
    Confirm_Password: Yup.string()
      .oneOf([Yup.ref("Password")], "Passwords must match")
      .required("Confirm Password is required"),
    otp: Yup.string()
      .length(6, "Otp must be 6 digit long")
      .matches(/^\d{6}$/, "Otp must be digits only")
      .required("Required"),
  });

  const formik = useFormik<FormValues>({
    initialValues,
    onSubmit,
    validationSchema,
  });

  return (
    <Container
      maxWidth={false}
      className="container"
      sx={{ maxWidth: "100%", margin: "0px" }}
    >
      <LeftSide />

      <RightSection className="RightSection bg-color">
        <Typography width="100%">
          <Typography
            className="heading Typography fw-700 mb-10 fs-24"
            width="100%"
          >
            A Fresh Start
          </Typography>
          <Typography
            className="Typography fs-18 mb-50 text-secondary"
            variant="body1"
            sx={{ mb: { xs: 10, sm: 10 } }}
          >
            Create a new password and secure your account.{" "}
          </Typography>
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          <Form onSubmit={formik.handleSubmit}>
            <Box width="100%">
              <FormControl fullWidth margin="normal" className="mb-20">
                <TextField
                  name="otp"
                  label={
                    <span>
                      OTP <sup><i className="fa-solid fa-star-of-life" style={{color: "#fa0000", fontSize:'0.5rem'}}></i></sup>
                    </span>
                  }
                  type="text"
                  variant="outlined"
                  className="text-secondary"
                  value={formik.values.otp}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={submitform && Boolean(formik.errors.otp)}
                  helperText={submitform && formik.errors.otp}
                  InputProps={{
                    startAdornment: (
                      <IconButton>
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6 10V8C6 4.69 7 2 12 2C17 2 18 4.69 18 8V10"
                            stroke="white"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M7 22C3 22 2 21 2 17V15C2 11 3 10 7 10H17C21 10 22 11 22 15V17C22 21 21 22 17 22H7Z"
                            stroke="white"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M15.9965 16H16.0054"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M11.9955 16H12.0045"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M7.99451 16H8.00349"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </IconButton>
                    ),
                  }}
                  InputLabelProps={{
                    shrink: false,
                  }}
                />
              </FormControl>
              <FormControl fullWidth margin="normal" className="mb-20">
                <TextField
                  name="Password"
                  label={
                    <span>
                      Password <sup><i className="fa-solid fa-star-of-life" style={{color: "#fa0000", fontSize:'0.5rem'}}></i></sup>
                    </span>
                  }
                  type={showPassword ? "text" : "password"}
                  variant="outlined"
                  className="text-secondary"
                  value={formik.values.Password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={submitform && Boolean(formik.errors.Password)}
                  helperText={submitform && formik.errors.Password}
                  InputProps={{
                    startAdornment: (
                      <IconButton>
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6 10V8C6 4.69 7 2 12 2C17 2 18 4.69 18 8V10"
                            stroke="white"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M7 22C3 22 2 21 2 17V15C2 11 3 10 7 10H17C21 10 22 11 22 15V17C22 21 21 22 17 22H7Z"
                            stroke="white"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M15.9965 16H16.0054"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M11.9955 16H12.0045"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M7.99451 16H8.00349"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </IconButton>
                    ),
                    endAdornment: (
                      <IconButton
                        onClick={() => setshowPassword(!showPassword)}
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9.47004 14.53C8.82004 13.88 8.42004 12.99 8.42004 12C8.42004 10.02 10.02 8.42004 12 8.42004C12.99 8.42004 13.88 8.82004 14.53 9.47004L9.47004 14.53Z"
                            stroke="white"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M17.82 5.76998C16.07 4.44998 14.07 3.72998 12 3.72998C8.46997 3.72998 5.17997 5.80998 2.88997 9.40998C1.98997 10.82 1.98997 13.19 2.88997 14.6C3.67997 15.84 4.59997 16.91 5.59997 17.77"
                            stroke="white"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M8.42004 19.5301C9.56004 20.0101 10.77 20.2701 12 20.2701C15.53 20.2701 18.82 18.1901 21.11 14.5901C22.01 13.1801 22.01 10.8101 21.11 9.40005C20.78 8.88005 20.42 8.39005 20.05 7.93005"
                            stroke="white"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M15.5099 12.7C15.2499 14.11 14.0999 15.26 12.6899 15.52"
                            stroke="white"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M9.47 14.53L2 22"
                            stroke="white"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M22 2L14.53 9.47"
                            stroke="white"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </IconButton>
                    ),
                  }}
                  InputLabelProps={{
                    shrink: false,
                  }}
                />
                {/* {formik.touched.Password && formik.errors.Password ? <Typography className='error '>{formik.errors.Password}</Typography> : null} */}
              </FormControl>
              <FormControl fullWidth margin="normal" className="mb-20">
                <TextField
                  name="Confirm_Password"
                  label={
                    <span>
                      Confirm Password <sup><i className="fa-solid fa-star-of-life" style={{color: "#fa0000", fontSize:'0.5rem'}}></i></sup>
                    </span>
                  }
                  type={showPassword ? "text" : "password"}
                  variant="outlined"
                  className="text-secondary"
                  value={formik.values.Confirm_Password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={submitform && Boolean(formik.errors.Confirm_Password)}
                  helperText={submitform && formik.errors.Confirm_Password}
                  InputProps={{
                    startAdornment: (
                      <IconButton>
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6 10V8C6 4.69 7 2 12 2C17 2 18 4.69 18 8V10"
                            stroke="white"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M7 22C3 22 2 21 2 17V15C2 11 3 10 7 10H17C21 10 22 11 22 15V17C22 21 21 22 17 22H7Z"
                            stroke="white"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M15.9965 16H16.0054"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M11.9955 16H12.0045"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M7.99451 16H8.00349"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </IconButton>
                    ),
                    endAdornment: (
                      <IconButton
                        onClick={() => setshowPassword(!showPassword)}
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9.47004 14.53C8.82004 13.88 8.42004 12.99 8.42004 12C8.42004 10.02 10.02 8.42004 12 8.42004C12.99 8.42004 13.88 8.82004 14.53 9.47004L9.47004 14.53Z"
                            stroke="white"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M17.82 5.76998C16.07 4.44998 14.07 3.72998 12 3.72998C8.46997 3.72998 5.17997 5.80998 2.88997 9.40998C1.98997 10.82 1.98997 13.19 2.88997 14.6C3.67997 15.84 4.59997 16.91 5.59997 17.77"
                            stroke="white"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M8.42004 19.5301C9.56004 20.0101 10.77 20.2701 12 20.2701C15.53 20.2701 18.82 18.1901 21.11 14.5901C22.01 13.1801 22.01 10.8101 21.11 9.40005C20.78 8.88005 20.42 8.39005 20.05 7.93005"
                            stroke="white"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M15.5099 12.7C15.2499 14.11 14.0999 15.26 12.6899 15.52"
                            stroke="white"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M9.47 14.53L2 22"
                            stroke="white"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M22 2L14.53 9.47"
                            stroke="white"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </IconButton>
                    ),
                  }}
                  InputLabelProps={{
                    shrink: false,
                  }}
                />
                <Link
                  to="/forgot"
                  className="mb-10 link"
                  style={{ textAlign: "end", marginTop: "10px" }}
                >
                  Forgot Passoword?
                </Link>
              </FormControl>

              <Button
                variant="contained"
                color="primary"
                fullWidth
                className="btn-primary fw-700 fs-18  btn mb-10"
                endIcon={
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15 22C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22H15Z"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M7.75 12L10.58 14.83L16.25 9.17004"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                }
                type="submit"
                onClick={() => {
                  setsubmitform(!submitform);
                }}
              >
                Submit
              </Button>
            </Box>
          </Form>
        </Formik>
        <Typography sx={{ mt: 2 }} className="text-secondary">
          Don't have an account? &nbsp;{" "}
          <Link to="/" className="link">
            Sign Up
          </Link>
        </Typography>
      </RightSection>
    </Container>
  );
}

export default Reset;

const RightSection = styled(Box)(({ theme }) => ({
  width: "50%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
  [theme.breakpoints.down("lg")]: {
   
    padding:'40px 60px'
  },
  [theme.breakpoints.down("sm")]: {
   
    padding:'40px 40px'
  },
}));
