import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Typography,
} from "@mui/material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

// Updated sample data
const rows = [
  { date: "10 March, 2024", time: "10:15 AM", amount: "$45.00", quantity: 10, isPositive: true },
  { date: "09 March, 2024", time: "03:30 PM", amount: "$30.50", quantity: 15, isPositive: true },
  { date: "08 March, 2024", time: "01:45 PM", amount: "$12.75", quantity: 5, isPositive: false },
  { date: "07 March, 2024", time: "11:20 AM", amount: "$50.00", quantity: 25, isPositive: true },
];

const TransactionHistoryTable = () => {
  return (
  
      <TableContainer component={Paper} style={{ marginTop: '30px', color: 'white', background: 'transparent', borderRadius: '20px', border: '2px solid #FFFFFF1A' }}>
        <Table>
          {/* Table Header */}
          <TableHead>
            <TableRow sx={{
              '& td': { borderBottom: 'none !important' },
            }}>
              <TableCell sx={{ color: "#BFBFBF", fontWeight: 600 }}>Date</TableCell>
              <TableCell sx={{ color: "#BFBFBF", fontWeight: 600 }}>Time</TableCell>
              <TableCell sx={{ color: "#BFBFBF", fontWeight: 600 }}>Total Amount</TableCell>
              <TableCell sx={{ color: "#BFBFBF", fontWeight: 600 }}>Total Qty.</TableCell>
            </TableRow>
          </TableHead>

          {/* Table Body */}
          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={index} sx={{
                '& td': { borderBottom: 'none !important' },
              }}>
                {/* Date with icon */}
                <TableCell sx={{ display: "flex", alignItems: "center", color: "#FFFFFF" }}>
                  {row.isPositive ? (
                    <ArrowDropUpIcon sx={{ color: "#4CAF50", marginRight: "8px" }} />
                  ) : (
                    <ArrowDropDownIcon sx={{ color: "#F44336", marginRight: "8px" }} />
                  )}
                  {row.date}
                </TableCell>

                {/* Time */}
                <TableCell sx={{ color: "#FFFFFF" }}>{row.time}</TableCell>

                {/* Amount */}
                <TableCell sx={{ color: "#FFFFFF" }}>{row.amount}</TableCell>

                {/* Quantity */}
                <TableCell sx={{ color: "#FFFFFF" }}>{row.quantity}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

  );
};

export default TransactionHistoryTable;
