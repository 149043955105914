import { Box, Button, Container, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { Field, Form, Formik, FormikHelpers } from "formik";
import React, { useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { UrlConstant } from "../../commom/UrlConstant";
import Axios from "../../config/axios";
import "../../style/style.css";
import LeftSide from "../common/Layout/LeftSide";

// Define the initial values shape for the form
interface FormValues {
  input1: string;
  input2: string;
  input3: string;
  input4: string;
  input5: string;
  input6: string;
}

const initialValues: FormValues = {
  input1: "",
  input2: "",
  input3: "",
  input4: "",
  input5: "",
  input6: "",
};

// Validation schema using Yup
const validationSchema = Yup.object().shape({
  input1: Yup.string()
    .length(1, "Only 1 character is allowed")
    .required("Required"),
  input2: Yup.string()
    .length(1, "Only 1 character is allowed")
    .required("Required"),
  input3: Yup.string()
    .length(1, "Only 1 character is allowed")
    .required("Required"),
  input4: Yup.string()
    .length(1, "Only 1 character is allowed")
    .required("Required"),
  input5: Yup.string()
    .length(1, "Only 1 character is allowed")
    .required("Required"),
  input6: Yup.string()
    .length(1, "Only 1 character is allowed")
    .required("Required"),
});

function Otp() {
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const [submitForm, setSubmitForm] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();
  const userEmail = location.state?.email;
  const email = userEmail;
  const onSubmit = (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ) => {
    submitOTP(values);
    setSubmitting(false);
  };
  const handleKeyUp = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number,
    setFieldTouched: (
      field: string,
      isTouched?: boolean,
      shouldValidate?: boolean
    ) => void
  ) => {
    if (e.key === "Enter") {
      setFieldTouched(`input${index}`, true, true); // Set the field as touched for validation
    } else if (
      e.currentTarget.value.length === 1 &&
      inputRefs.current[index + 1]
    ) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  const submitOTP = async (value: any) => {
    const otp = `${value.input1}${value.input2}${value.input3}${value.input4}${value.input5}${value.input6}`;
    const payLoad = {
      email: email,
      otp: otp,
    };
    try {
      const response = await Axios.post(
        UrlConstant.Auth.VerifyEmailByOTP,
        payLoad
      );

      const resData = response.data;
      if (resData.result === true) {
        navigate("/login");
      } else {
        Swal.fire({
          title: "Failed",
          text: resData.msg,
          icon: "error",
        });
      }
    } catch (error: any) {
      console.error("ERRR : ", error);
      Swal.fire({
        title: "Failed",
        text: error.response.data.msg,
        icon: "error",
      });
    }
  };
  const resendOTP = async () => {
    try {
      const response = await Axios.post(UrlConstant.Auth.ResendOTP, {
        email,
      });
      const resData = response.data;
      if (resData.result === true) {
        Swal.fire({
          title: "Success",
          text: "Successfully send OTP",
          icon: "info",
        });
      }
    } catch (error: any) {
      console.error("ERRR : ", error);
      console.error(error.response.data.msg);
      Swal.fire({
        title: "Failed",
        text: error.response.data.msg,
        icon: "error",
      });
    }
  };
  React.useEffect(() => {
    if (email === undefined || email === "" || email === null) {
      navigate("/");
    }
  }, [email, navigate]);

  return (
    <Container
      maxWidth={false}
      className="container"
      sx={{ maxWidth: "100%", margin: "0px" }}
    >
      <LeftSide />
      <RightSection className="RightSection bg-color">
        <Typography width="100%">
          <Typography className="Typography fw-700 mb-10 fs-24 heading">
            Verify Your Identity{" "}
          </Typography>
          <Typography
            className="Typography fs-18 mb-50 text-secondary"
            variant="body1"
            sx={{ mb: { xs: 10, sm: 10 } }}
          >
            Just one step away from unlocking your access.{" "}
          </Typography>
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ setFieldTouched, errors, touched }) => (
            <Form>
              <Typography className="heading mb-20"> 
                  <span>
                    OTP <sup><i className="fa-solid fa-star-of-life" style={{color: "#fa0000", fontSize:'0.5rem'}}></i></sup>
                  </span>
                </Typography>
              <div className="otpgrp">
                {[1, 2, 3, 4, 5, 6].map((i) => {
                  const inputName = `input${i}` as keyof FormValues;

                  return (
                    <div key={i}>
                      <Field
                        name={`input${i}`}
                        as="input"
                        type="text"
                        className="otp heading"
                        maxLength="1"
                        innerRef={(el: any) => (inputRefs.current[i] = el)} // Store each input in the array
                        onKeyUp={(e: any) => handleKeyUp(e, i, setFieldTouched)}
                        style={{
                          borderColor:
                            submitForm && errors[inputName] ? "red" : "",
                        }}
                      />
                      {/* <div style={{ color: "red" }}>
                        <ErrorMessage name={`input${i}`} />
                      </div> */}
                    </div>
                  );
                })}
              </div>
              <label
                // to="/"

                className="link"
                style={{
                  textAlign: "end",
                  display: "flex",
                  justifyContent: "end",
                  marginBottom: "40px",
                }}
                onClick={resendOTP}
              >
                Resend otp?
              </label>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                className="btn-primary fw-700 fs-18  btn mb-10"
                endIcon={
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15 22C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22H15Z"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M7.75 12L10.58 14.83L16.25 9.17004"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                }
                type="submit"
                //  onClick={()=>navigate('/reset')}
                onClick={() => setSubmitForm(true)}
              >
                Submit
              </Button>
            </Form>
          )}
        </Formik>

        <Typography sx={{ mt: 2 }} className="text-secondary">
          Don't have an account? &nbsp; <Link to="/">Sign Up</Link>
        </Typography>
      </RightSection>
    </Container>
  );
}

export default Otp;

const RightSection = styled(Box)(({ theme }) => ({
  width: "50%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));
