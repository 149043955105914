import React, { useEffect } from "react";
import {
  Container,
  Typography,
  Box,
  Card,
  CardContent,
  Avatar,
  CircularProgress,
  Grid,
  Divider,
  Button,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Chip,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { useLocation, useNavigate } from "react-router-dom";
import { UrlConstant } from "../../../commom/UrlConstant";
import Axios from "../../../config/axios";
import moment from "moment";

function BoosterLoanDetails() {

  const location = useLocation();
  const navigation = useNavigate();

  const [loading, setLoading] = React.useState<any>(false);
  const [boosterLoanDetails, setBoosterLoanDetails] = React.useState<any>();


  const stock = location.state?.symbol;
  const loanDetails = location.state?.loanDetails;
  const icon = location.state?.icon;
  const title = location.state?.title;
  const loan_id = location.state?.loan_id;
  console.log(loanDetails, "loanDetails")
  const dummyData = {
    stock: "Apple INC",
    symbol: "APPL",
    amountPaid: 630.0,
    remainingAmount: 1890.0,
    installmentAmount: 330.0,
    loanDetails: {
      loanAmount: 1890.0,
      qty: 100,
      interest: 10,
      interestAmount: 189.0,
      fees: 1.0,
      totalAmount: 2080.0,
    },
    paymentSchedule: [
      { date: "05-May-2024", status: "PAID", method: "Cash" },
      { date: "05-Jun-2024", status: "PAID", method: "Share Selldown" },
      { date: "05-Jul-2024", status: "UPCOMING", method: "Cash" },
      { date: "05-Aug-2024", status: "REMAINING", method: "Cash" },
      { date: "05-Sep-2024", status: "REMAINING", method: "Cash" },
      { date: "05-Oct-2024", status: "REMAINING", method: "Cash" },
      { date: "05-Nov-2024", status: "REMAINING", method: "Cash" },
      { date: "05-Dec-2024", status: "REMAINING", method: "Cash" },
      { date: "05-Jan-2025", status: "REMAINING", method: "Cash" },
      { date: "05-Feb-2025", status: "REMAINING", method: "Cash" },
    ],
  };

  const getLogoUrl = (symbol) => {
    if (!symbol) {
      throw new Error("Symbol is required");
    }
    return `https://logos-api.extend.apexclearing.com/logos/${symbol.toLowerCase().trim()}.png?api_key=4RJeGywzAE&api_secret=isUE1ZzPNi5IzXbs9Qg0S01eaFUcxX09c4U88R9JBiJgJ6WxXHrXz3VtEima`;
  };


  const getBoosterInstallments = async () => {
    setLoading(true)
    try {
      const response = await Axios.get(
        UrlConstant.BoosterLoan.GetBoosterLoanInstallment.replace("{0}", loan_id)
      );
      const resData = response.data;
      if (resData.status === true) {
        setBoosterLoanDetails(resData.data);
      }
    } catch (error: any) {
      console.error("ERRR : ", error);
    } finally {
      setLoading(false)
    }
  };


  useEffect(() => {
    if (loan_id) {
      getBoosterInstallments()
    }
  }, [loan_id])


  return (
    <Container maxWidth={false} className="bg-dark" style={{ minHeight: '100vh', paddingTop: '30px' }}>
      {/* Header */}
      <Typography
        className="text-secondary"
      >
        {" "}
        <span
          onClick={() => navigation(-2)}
          style={{ color: "#4A7AF2", cursor: "pointer" }}
        >
          Booster Loan{" "}
        </span>{" "}

        <span> / {stock}</span>
      </Typography>

      {/* Loan Overview */}
      <Grid container spacing={4}>
        <Grid item xs={12} md={8}>
          <Card className="bg-dark w-100" style={{ border: '0px', boxShadow: 'none' }}
          >
            <CardContent className="text-white">
              <Box display='flex' justifyContent='space-between' alignItems='center'>
                <Box display="flex" alignItems="center" gap={2}>
                  <Avatar
                    src={getLogoUrl(stock)}
                    alt={stock}
                    sx={{ width: 64, height: 64 }}
                  />
                  <Box>
                    <Typography variant="h5" gutterBottom>{stock}</Typography>
                    <Typography >
                      NYSE: {dummyData.symbol}
                    </Typography>
                  </Box>
                </Box>
                <Typography variant="h4" fontSize='32px'>$330.00</Typography>
              </Box>

              {/* Progress */}
              <Box mt={4}>

                <LinearProgress
                  variant="determinate"
                  sx={{
                    height: '10px', borderRadius: '20px', bgcolor: "#FFFFFF1A",
                    '& .MuiLinearProgress-bar': {
                      backgroundColor: '#4A7AF2', // Filled bar (progress)
                    },
                  }}
                  value={
                    (boosterLoanDetails?.loanDetail?.paid_amount /
                      (boosterLoanDetails?.loanDetail?.paid_amount + boosterLoanDetails?.loanDetail?.remaining_amount)) *
                    100
                  }
                />
                <Box className="text-white" display={"flex"} alignItems={"center"} justifyContent={"space-between"} >

                  <Box mt='20px'>
                    <Typography variant="subtitle2">
                      {boosterLoanDetails?.paid_installment} Paid
                    </Typography>
                    <Typography variant="h6">
                      ${boosterLoanDetails?.loanDetail?.paid_amount?.toFixed(2)}
                    </Typography>
                  </Box>

                  <Box>
                    <Typography variant="subtitle2" >
                      {boosterLoanDetails?.remaining_installment} Remaining
                    </Typography>
                    <Typography variant="h6" >
                      ${boosterLoanDetails?.loanDetail?.remaining_amount?.toFixed(2)}
                    </Typography>
                  </Box>

                </Box>
              </Box>
            </CardContent>
          </Card>
          <Box className="text-white" display={"flex"} alignItems={"center"} justifyContent={"space-between"} >
            <Typography variant="h6"  >
              Payment Schedule
            </Typography>
            <Button
              variant="contained"
              className="btn-primary"
              sx={{ borderRadius: '20px', textTransform: 'none', padding: '5px 20px' }}
            >
              Pay Remaining
            </Button>
          </Box>

          {/* Payment Schedule */}
          <Box className="text-white" sx={{ mt: '30px' }} >

            <List>
              {boosterLoanDetails?.installments?.map((installment, index) => (
                <ListItem sx={{ alignItems: 'start',paddingBottom:'0px', paddingTop:'0px'}} key={index}>
                  <Box display={'flex'}>
                    <ListItemIcon sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
                      {installment.status === "PAID" ? (
                        <CheckCircleIcon color="success"/>
                      ) : (
                        <>
                          <RadioButtonUncheckedIcon />
                        </>
                      )}
                      {/* {installment < installment.length - 1 && (
                    )} */}
                      <Box sx={{ width: '3px', backgroundColor: '#666', height: '25px', my:'4px' }} />
                    </ListItemIcon>
                    <ListItemText
                      primary={`${moment(+installment.due_date).format("DD-MMM-YYYY")}`}
                      secondary={installment.method}
                      primaryTypographyProps={{
                        // color:
                        //   installment.status === "PAID"
                        //     ? "success.main"
                        //     : installment.status === "UPCOMING"
                        //       ? "info.main"
                        //       : installment.status == "REMAINING"
                        //         ? "info.secondary"
                        //         : "error.main",
                        fontWeight: '700',
                        fontSize: '14px'
                      }}
                    />
                  </Box>
                  <ListItemText
                    primary={
                      <Box display="flex" alignItems="center" gap='5px'>
                        <Typography variant="caption">1 of 10</Typography>
                        <Typography variant="caption">|</Typography>
                        <Typography variant="caption" sx={{ color: installment.status === "PAID" ? "#4EDF6D" : installment.status === "UPCOMING" ? "#4A7AF2" : installment.status === "REMAINING" ? "#F2674A" : "white", fontSize: '12px' }}>
                          {installment.status}
                        </Typography>
                      </Box>
                    }
                    primaryTypographyProps={{
                      display: 'flex',
                      justifyContent: "end"
                    }}
                  />
                  <ListItemText
                    primary={'cash'}
                    primaryTypographyProps={{
                      textAlign: 'end'
                    }}
                  />
                  <ListItemText
                    primary={'$330.00'}
                    primaryTypographyProps={{
                      fontSize: '16px',
                      textAlign: 'end'
                    }}
                  />
                </ListItem>
              ))}
            </List>
          </Box>
        </Grid>

        {/* Loan Details */}
        <Grid item xs={12} md={4}>
          <Typography variant="h6" className="text-white" gutterBottom>
            Loan Details
          </Typography>
          <Card className="w-100" sx={{ boxShadow: 'none', borderRadius: '30px', border: '1px solid #FFFFFF33', bgcolor: '#FFFFFF1A' }}>
            <CardContent sx={{ padding: '0px 0px !important' }} className="text-white">
              {/* <Divider sx={{ my: 2 }} /> */}
              <Box display='flex' sx={{ padding: '20px 30px' }} alignItems='center' justifyContent='space-between'>
                <Box display="flex" alignItems="center" gap={2}>
                  <Avatar
                    src="https://logos-api.extend.apexclearing.com/logos/aapl.png"
                    alt="Apple INC"
                    sx={{ width: 36, height: 36 }}
                  />
                  <Box>
                    <Typography variant="subtitle1" mb={0}>{dummyData.stock}</Typography>
                    <Typography variant="overline" className="text-secondary">
                      NYSE: {dummyData.symbol}
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  <Typography variant="subtitle1" mb={0}>$18.90 <span style={{ color: "#4EDF6D" }}>+$0.90 (+10%)</span></Typography>
                  <Typography variant="caption" sx={{ color: '#4A7AF2' }}>08/12/2024 - 15:59:59 EST</Typography>
                </Box>
              </Box>
              <Divider sx={{ bgcolor: 'white' }}></Divider>
              <Box padding='20px 30px' display='flex' justifyContent='space-between' alignItems='center'>
                <Box>
                  <Typography variant="caption" className="text-secondary">Payment Method</Typography>
                  <Typography variant="h6" className="text-white" fontWeight='700'>***5460</Typography>

                </Box>
                <Button
                  variant="contained"
                  className="btn-primary"
                  sx={{ borderRadius: '20px', bgcolor: '#4A7AF2 !important', textTransform: 'none', padding: '5px 20px' }}
                >
                  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.3335 1.83398H6.00016C2.66683 1.83398 1.3335 3.16732 1.3335 6.50065V10.5007C1.3335 13.834 2.66683 15.1673 6.00016 15.1673H10.0002C13.3335 15.1673 14.6668 13.834 14.6668 10.5007V9.16732" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M10.6933 2.5135L5.43992 7.76683C5.23992 7.96683 5.03992 8.36017 4.99992 8.64683L4.71325 10.6535C4.60659 11.3802 5.11992 11.8868 5.84659 11.7868L7.85325 11.5002C8.13325 11.4602 8.52659 11.2602 8.73325 11.0602L13.9866 5.80683C14.8933 4.90017 15.3199 3.84684 13.9866 2.5135C12.6533 1.18017 11.5999 1.60684 10.6933 2.5135Z" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M9.93994 3.26758C10.3866 4.86091 11.6333 6.10758 13.2333 6.56091" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  <span style={{marginLeft:'5px'}}>Change Method</span> 
                </Button>
              </Box>
              <Divider sx={{ bgcolor: 'white'}}></Divider>
              {/* <Divider sx={{border:'1px solid white'}}></Divider> */}
              <Box sx={{ padding: '20px 30px' }}>
                <Typography className="text-secondary" variant="body2" display="flex" justifyContent="space-between" gutterBottom>
                  Loan Amount: <span className="text-white">${dummyData.loanDetails.loanAmount}</span>
                </Typography>
                <Typography className="text-secondary" variant="body2" display="flex" justifyContent="space-between" gutterBottom>
                  Qty (Whole): <span className="text-white">{dummyData.loanDetails.qty}</span>
                </Typography>
                <Typography className="text-secondary" variant="body2" display="flex" justifyContent="space-between" gutterBottom>
                  Interest: <span className="text-white">{dummyData.loanDetails.interest}%</span>
                </Typography>
                <Typography className="text-secondary" variant="body2" display="flex" justifyContent="space-between" gutterBottom>
                  Interest Amount: <span className="text-white">${dummyData.loanDetails.interestAmount}</span>
                </Typography>
                <Typography  className="text-secondary" variant="body2" display="flex" justifyContent="space-between" gutterBottom>
                  Fees: <span className="text-white">${dummyData.loanDetails.fees}</span>
                </Typography>
                <Typography className="text-secondary" variant="body2" display="flex" justifyContent="space-between" gutterBottom>
                  Total Amount: <span className="text-white">${dummyData.loanDetails.totalAmount}</span>
                </Typography>
                {/* <Typography variant="body2" display="flex" justifyContent="space-between" gutterBottom mt={1}>
                  <Chip label={`Total Amount: $${dummyData.loanDetails.totalAmount}`} />
                </Typography> */}
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}

export default BoosterLoanDetails;
