// src/axiosConfig.js
import axios from "axios";
import { BASE_URL } from "../commom/UrlConstant";

const Axios = axios.create({
  baseURL: BASE_URL,
});

Axios.interceptors.request.use(
  (config: any) => {
    //   debugger;
    const userString = localStorage.getItem("user");
    let user: any = null;

    if (userString) {
      user = JSON.parse(userString);
      config.headers.Authorization = `Bearer ${user.userToken}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

Axios.interceptors.response.use(
  (response) => {
    //debugger;
    return response;
  },
  (error) => {
    //  debugger;
    if (error.response && error.response.status === 401 || error.response.status === 403) {
      localStorage.removeItem("user");
      localStorage.removeItem("isAuthenticated"); // Optionally remove the user token
      window.location.href = "/login"; // Redirect to the login page
    }
    return Promise.reject(error);
  }
);

export default Axios;
