import React, { useState } from "react";
import { FormControl, InputLabel, Select, MenuItem, Box } from "@mui/material";

interface RangeInputProps {
  min: number;
  max: number;
  minValue: any;
  maxValue: any;
  steps: number;
  onRangeChange: (e:any) => void;
}

const RangeInput: React.FC<RangeInputProps> = ({ min, max,minValue,maxValue, steps, onRangeChange }) => {
  const [selectedMin, setSelectedMin] = useState<number>(min);
  const [selectedMax, setSelectedMax] = useState<number>(max);

  // Generate dropdown options based on min, max, and steps
  const generateOptions = (min: number, max: number, steps: number): number[] => {
    const options: number[] = [];
    for (let i = min; i <= max; i += steps) {
      options.push(i);
    }
    return options;
  };

  const options = generateOptions(min, max, steps);

  const handleMinChange = (e) => {
    // setSelectedMin(e);
    if (e.target.value < selectedMax) {
      // setSelectedMax(e); // Ensure max is not less than min
      onRangeChange(e);
    }else{
      alert("Its Grater Then Max Value")
    }
  };

  const handleMaxChange = (e) => {
    setSelectedMax(e.target.value);
    onRangeChange(e);
  };

  return (
    <>
      <FormControl fullWidth sx={{ flex: 1 }}>
        <InputLabel sx={{ color: "gray !important", backgroundColor: "bg-color" }}>
          Min <sup style={{ color: "red" }}>*</sup>
        </InputLabel>
        <Select
          className="text-secondary"
          id="min-select"
          value={minValue}
          onChange={(e) => handleMinChange(e)}
          name="min"
          label="Min"
          sx={{
            backgroundColor: "bg-color",
            borderRadius: 1,
            height: "52px", // Same height for all fields
            "& .MuiSelect-icon": { color: "white" },
            "& .MuiInputBase-root": { color: "white" },
          }}
        >
          {options.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl fullWidth sx={{ flex: 1 }}>
        <InputLabel sx={{ color: "gray !important", backgroundColor: "bg-color" }}>
          Max <sup style={{ color: "red" }}>*</sup>
        </InputLabel>
        <Select
          className="text-secondary"
          id="max-select"
          value={maxValue}
          onChange={(e) => handleMaxChange(e)}
          name="max"
          label="Max"
          sx={{
            backgroundColor: "bg-color",
            borderRadius: 1,
            height: "52px", // Same height for all fields
            "& .MuiSelect-icon": { color: "white" },
            "& .MuiInputBase-root": { color: "white" },
          }}
        >
          {options
            .filter((option) => option >= minValue) // Ensure max >= min
            .map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </>
  );
};

export default RangeInput;
