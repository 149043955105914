import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Avatar,
  Typography,
  Box,
  Chip,
} from '@mui/material';

// Sample data
const rows = [
  {
    company: { name: 'Apple INC', logo: 'https://path-to-apple-logo.png' },
    installments: '05 of 10',
    date: '05-Jun-2024',
    day: 'Thursday',
    amount: '$330.00',
    paymentMethod: 'Share Selldown',
    sharesSold: 20,
    status: 'Success',
  },
  {
    company: { name: 'Vodafone Idea', logo: 'https://path-to-vodafone-logo.png' },
    installments: '04 of 06',
    date: '06-Jun-2024',
    day: 'Friday',
    amount: '$823.00',
    paymentMethod: '**** 5409',
    sharesSold: '-',
    status: 'Success',
  },
  {
    company: { name: 'Tesla', logo: 'https://path-to-tesla-logo.png' },
    installments: '07 of 12',
    date: '06-Jun-2024',
    day: 'Friday',
    amount: '$243.00',
    paymentMethod: '**** 2340',
    sharesSold: '-',
    status: 'Failed',
  },
  {
    company: { name: 'Apple INC', logo: 'https://path-to-apple-logo.png' },
    installments: '05 of 10',
    date: '05-Jun-2024',
    day: 'Thursday',
    amount: '$330.00',
    paymentMethod: 'Share Selldown',
    sharesSold: 20,
    status: 'Success',
  },
  {
    company: { name: 'Vodafone Idea', logo: 'https://path-to-vodafone-logo.png' },
    installments: '04 of 06',
    date: '06-Jun-2024',
    day: 'Friday',
    amount: '$823.00',
    paymentMethod: '**** 5409',
    sharesSold: '-',
    status: 'Success',
  },
  {
    company: { name: 'Tesla', logo: 'https://path-to-tesla-logo.png' },
    installments: '07 of 12',
    date: '06-Jun-2024',
    day: 'Friday',
    amount: '$243.00',
    paymentMethod: '**** 2340',
    sharesSold: '-',
    status: 'Failed',
  },
];

interface ActiveLoansProps {
  boosterLoanHistoryData: any; 
}

const HistoryTable: React.FC<ActiveLoansProps> = ({boosterLoanHistoryData}) => {
  return (
    <TableContainer component={Paper}  style={{marginTop:'30px',color: 'white', background:'transparent', borderRadius:'20px', border:'2px solid #FFFFFF1A' }}>
      <Table>
        <TableHead>
          <TableRow sx={{ '& th': { borderColor: '#FFFFFF1A'}, 
              }}>
            <TableCell style={{fontSize:'12px', color:'white'}}>Company</TableCell>
            <TableCell style={{fontSize:'12px', color:'white',textAlign:'end' }}>Installments</TableCell>
            <TableCell style={{fontSize:'12px', color:'white', textAlign:'end'}}>Day & Date</TableCell>
            <TableCell style={{fontSize:'12px', color:'white', textAlign:'end'}}>Amount</TableCell>
            <TableCell style={{fontSize:'12px', color:'white', textAlign:'end'}}>Payment Method</TableCell>
            <TableCell style={{fontSize:'12px', color:'white',textAlign:'center'}}>Shares Sold</TableCell>
            <TableCell style={{fontSize:'12px', color:'white',textAlign:'center'}}>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow key={index} sx={{
                '& td': { borderBottom: 'none !important' }, 
              }}>
              <TableCell>
                <Box display="flex" alignItems="center">
                  <Avatar
                    src={row.company.logo}
                    alt={row.company.name}
                    style={{ marginRight: '8px', height:'36px', width:'36px' }}
                  />
                  <Typography style={{fontSize:'14px', color: 'white' }}>{row.company.name}</Typography>
                </Box>
              </TableCell>
              <TableCell style={{ fontSize:"16px",color: 'white',textAlign:'end'  }}>{row.installments}</TableCell>
              <TableCell style={{ fontSize:"16px",color: 'white', textAlign:'end' }}>
                {row.date} | <span style={{color:"#4A7AF2"}}>{row.day}</span> </TableCell>
              <TableCell style={{ fontSize:"16px",color: 'white',textAlign:'end'  }}>{row.amount}</TableCell>
              <TableCell style={{ fontSize:"16px",color: 'white',textAlign:'end'  }}>{row.paymentMethod}</TableCell>
              <TableCell style={{ fontSize:"16px",color: 'white', textAlign: 'center' }}>
                {row.sharesSold}
              </TableCell>
              <TableCell style={{textAlign:'center'}}>
                <Chip
                  label={row.status}
                  style={{
                    fontSize:'14px',
                    backgroundColor: row.status === 'Success' ? '#4EDF6D33' : '#FF2A2A33',
                    color:row.status === 'Success' ? '#4EDF6D' : '#FF2A2A',
                    padding:row.status==='Failed'?'5px 28px':'5px 23px'
                  }}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default HistoryTable;
